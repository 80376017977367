import { Col, Row, Typography } from "antd";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  VictoryAxis,
  VictoryBar,
  VictoryBrushContainer,
  VictoryChart,
  VictoryLine
} from "victory";
import { iconColors } from "../../constants/colors";
import {
  PrimaryGraphZoomDomain,
  ZoomDimension,
  commonPrimaryGraphProps,
  dateAxisFormater,
  getNormalizerFunctions,
  getOverviewGraphData,
  yAxisDefaultNormalizedValues,
  zoomDimensionToVictoryProp
} from "../../helpers/graphHelper";
import { timezoneSelector } from "../../helpers/timezoneSelector";
import {
  selectGlobalTimezone,
  selectGlobalTimezoneToggle
} from "../../state/sessionSlice";
import {
  GraphDataPoint,
  NullableGraphDataPoint,
  PrimaryGraphData
} from "./PrimaryGraph";
import { isNull, isUndefined } from "lodash-es";

interface IProps {
  data: PrimaryGraphData;
  width: number;
  height: number;
  zoomDomain: { x: [Date, Date]; y: [number, number] };
  activeDomain: [Date, Date];

  zoomDimension?: ZoomDimension;
  //Note no type to make victory library happy.
  handleZoom: (domain: PrimaryGraphZoomDomain) => void;
  resetZoom: () => void;
}
const OverviewGraph: React.FC<IProps> = (props) => {
  /** Data to be shown in graph. Filtered for performance reasons */
  const graphData = useMemo(
    () => getOverviewGraphData(props.data),
    [props.data]
  );

  const startDate = dayjs(props.activeDomain[0]);
  const endDate = dayjs(props.activeDomain[1]);

  /** logical values in graph. Ranges from -1 to 1 */
  const scale = { low: -1, high: 1 };

  const { normalizeValue: normalizeAccValue } = getNormalizerFunctions(
    props.data.yAxisDomain.acc,
    scale
  );

  const { normalizeValue: normalizeRhValue } = getNormalizerFunctions(
    props.data.yAxisDomain.rh,
    scale
  );

  const { normalizeValue: normalizePressureValue } = getNormalizerFunctions(
    props.data.yAxisDomain.pressure,
    scale
  );

  const { normalizeValue: normalizeTempValue } = getNormalizerFunctions(
    props.data.yAxisDomain.temp,
    scale
  );

  /** Checks if a domain is equivalent with the entire domain */
  const isZoomReset = (domain: PrimaryGraphZoomDomain): boolean =>
    domain.x[0] <= props.activeDomain[0] &&
    domain.x[1] >= props.activeDomain[1] &&
    domain.y[0] === yAxisDefaultNormalizedValues[0] &&
    domain.y[1] === yAxisDefaultNormalizedValues[1];

  /** Updates the store with the new domain. Since Victory triggers the same
   * event for zoom/pan as reset, a check to determine the kind of event is made */
  const handleBrushZoom = (newDomain: PrimaryGraphZoomDomain) => {
    isZoomReset(newDomain) ? props.resetZoom() : props.handleZoom(newDomain);
  };

  const timezoneState = useSelector(selectGlobalTimezone);
  const timezoneToggle = useSelector(selectGlobalTimezoneToggle);

  const filteredTempData = !isUndefined(graphData.tempData)
    ? graphData.tempData.filter((temp) => !isNull(temp.y))
    : [];

  const filteredRhData = !isUndefined(graphData.rhData)
    ? graphData.rhData.filter((rh) => !isNull(rh.y))
    : [];

  const filteredPressureRawData = !isUndefined(
    graphData.pressureData?.pressureRaw
  )
    ? graphData.pressureData?.pressureRaw.filter(
        (pressure) => !isNull(pressure.y)
      )
    : [];

  const filteredPressureCompData = !isUndefined(
    graphData.pressureData?.pressureComp
  )
    ? graphData.pressureData?.pressureComp.filter(
        (pressure) => !isNull(pressure.y)
      )
    : [];

  return (
    <Row wrap={false} align="top">
      <Col
        flex="100px"
        style={{
          textAlign: "center",
          paddingTop: commonPrimaryGraphProps.overviewPadding.top
        }}
      >
        <div>
          <Typography.Text>{startDate.format("YYYY-MM-DD")}</Typography.Text>
        </div>
        <div>
          <Typography.Text>{endDate.format("YYYY-MM-DD")}</Typography.Text>
        </div>
      </Col>
      <Col flex="auto">
        <VictoryChart
          domain={{ x: props.activeDomain, y: yAxisDefaultNormalizedValues }}
          width={props.width}
          height={props.height}
          standalone={true}
          scale={{ x: "time" }}
          padding={commonPrimaryGraphProps.overviewPadding}
          containerComponent={
            <VictoryBrushContainer
              brushDimension={zoomDimensionToVictoryProp(props.zoomDimension)}
              brushDomain={props.zoomDomain}
              onBrushDomainChange={handleBrushZoom as any}
            />
          }
        >
          {/* x-axis(based on time, show no values) */}
          <VictoryAxis tickFormat={[]} axisValue={normalizeAccValue(0) ?? 0} />
          {/* x-axis(based on time, show values) */}
          <VictoryAxis
            axisValue={scale.low}
            tickFormat={(t, _i, arr) =>
              dateAxisFormater(
                t,
                arr,
                timezoneSelector(
                  props.data.timezone,
                  timezoneState,
                  timezoneToggle
                )
              )
            }
            fixLabelOverlap
            style={{
              tickLabels: { fontSize: 16, fontFamily: "Arial" }
            }}
          />

          {props.data.dataFilters.xAcc.dataToggle.isActive && (
            <VictoryBar
              data={graphData?.accData?.xAcc}
              x="x"
              y={(datum: GraphDataPoint) => normalizeAccValue(datum.y[0])}
              style={{ data: { fill: iconColors.xAcc } }}
              barWidth={2}
            />
          )}
          {props.data.dataFilters.yAcc.dataToggle.isActive && (
            <VictoryBar
              data={graphData?.accData?.yAcc}
              x="x"
              y={(datum: GraphDataPoint) => normalizeAccValue(datum.y[0])}
              style={{ data: { fill: iconColors.yAcc } }}
              barWidth={2}
            />
          )}
          {props.data.dataFilters.zAcc.dataToggle.isActive && (
            <VictoryBar
              data={graphData?.accData?.zAcc}
              x="x"
              y={(datum: GraphDataPoint) => normalizeAccValue(datum.y[0])}
              style={{ data: { fill: iconColors.zAcc } }}
              barWidth={2}
            />
          )}
          {props.data.dataFilters.temp.dataToggle.isActive &&
            filteredTempData.length > 0 && (
              <VictoryLine
                data={filteredTempData}
                y={(datum: NullableGraphDataPoint) =>
                  datum.y === null ? 0 : normalizeTempValue(datum.y[0])
                }
                style={{ data: { stroke: iconColors.temp } }}
              />
            )}
          {props.data.dataFilters.rh.dataToggle.isActive &&
            filteredRhData.length > 0 && (
              <VictoryLine
                data={filteredRhData}
                y={(datum: NullableGraphDataPoint) =>
                  datum.y === null ? 0 : normalizeRhValue(datum.y[0])
                }
                style={{ data: { stroke: iconColors.rh } }}
              />
            )}
          {props.data.dataFilters.pressureRaw.dataToggle.isActive &&
            filteredPressureRawData.length > 0 && (
              <VictoryLine
                data={filteredPressureRawData}
                y={(datum: NullableGraphDataPoint) =>
                  datum.y === null ? 0 : normalizePressureValue(datum.y[0])
                }
                style={{ data: { stroke: iconColors.pressure } }}
              />
            )}
          {props.data.dataFilters.pressureComp.dataToggle.isActive &&
            filteredPressureCompData.length > 0 && (
              <VictoryLine
                data={filteredPressureCompData}
                y={(datum: NullableGraphDataPoint) =>
                  datum.y === null ? 0 : normalizePressureValue(datum.y[0])
                }
                style={{ data: { stroke: iconColors.pressure } }}
              />
            )}
        </VictoryChart>
      </Col>
    </Row>
  );
};

export default React.memo(OverviewGraph);

import React from "react";
import { Typography, Row } from "antd";
import { useTranslation } from "react-i18next";
import FAT100Icon from "../../assets/Icons/FAT100.svg";
import L, { Icon, LatLngExpression, LatLngTuple } from "leaflet";
import {
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
  useMapEvents
} from "react-leaflet";
import dayjs from "dayjs";
import { DictionaryTransKeys } from "../../lib/i18n";
import {
  HomeOutlined,
  HomeTwoTone,
  ScheduleOutlined,
  WarningOutlined,
  WarningTwoTone
} from "@ant-design/icons";
import ReactDOMServer from "react-dom/server";
import { RecentOnlineDevice, User } from "../../state/cargologRestApi";
import { useNavigate } from "react-router";
import { isUndefined } from "lodash-es";
import {
  openProjectModal,
  setSelectedProjectId
} from "../../state/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import { License } from "../MicroComponents/LicenseAccess";
import { SpeedConverter } from "../MicroComponents/SpeedConverter";
import { selectMySpeed } from "../../state/sessionSlice";
import { MapProviderLink } from "../MicroComponents/MapProviderLink";
import { size } from "../../helpers/pageHelper";
const { Text, Link } = Typography;

export const deviceIcon = new Icon({
  iconUrl: FAT100Icon,
  iconSize: [24, 24],
  popupAnchor: [0, -10]
});

/** Used for all maps and tables containing devices */
export interface DeviceData {
  parameterId: string;
  fileName?: string;
  firstPos?: FirstPos;
  lastPos?: LastPos;
  recordingStart?: string;
  latestData?: string;
  lastUploader?: string;
  lastUploadType?: string;
  created?: string;
  creator?: string;
  serialNumber: string;
  deviceDescription: string;
  gpsPosData: GpsPosData[];
  lineColor: string;
  alarms?: number;
  project?: {
    id: string;
    name: string;
  };
  lastSeen?: string;
  lastUser?: User;
  nextCalibration?: string;
  recentDevice?: RecentOnlineDevice;
  hasData?: boolean;
  isActive?: boolean;
  parXFileName?: string;
  key: string;
}

export interface FirstPos {
  firstDateTime?: string;
  firstLat?: number;
  firstLon?: number;
  firstVelocity?: number;
  firstStatus?: number;
}

export interface LastPos {
  lastDateTime: string;
  lastLat: number;
  lastLon: number;
  lastVelocity: number;
  lastStatus: number;
}

export interface GpsPosData {
  path: [number, number];
  velocity: number;
  dateTime: string;
  status: number;
}

interface IProps {
  center: LatLngTuple;
  outerBounds: LatLngTuple[];
  deviceData: DeviceData[];
  showStartPos: boolean;
  showAlarms: boolean;
  showDevices: boolean;
  showPolylines: boolean;
}
const DeviceMap = (props: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const speedUnit = useSelector(selectMySpeed);
  const {
    center,
    outerBounds,
    deviceData,
    showStartPos,
    showAlarms,
    showDevices,
    showPolylines
  } = props;

  const openProject = (projectId: string) => {
    dispatch(openProjectModal());
    dispatch(setSelectedProjectId(projectId));
    navigate("/projects");
  };

  const MapHook = () => {
    const mapEvents = useMapEvents({
      mousedown: (e) => {
        // Prevents CC from crashing when "click-dragging" with scroll wheel button in the map
        if (e.originalEvent.button === 1) {
          e.originalEvent.preventDefault();
          return false;
        }
      }
    });
    return null;
  };

  const alarmPopup = (
    t: (s: DictionaryTransKeys) => string,
    unit: DeviceData,
    info: GpsPosData
  ) => {
    const timezoneOffset = new Date().getTimezoneOffset();
    const dateTime = dayjs
      .utc(info.dateTime)
      .subtract(timezoneOffset, "minutes")
      .format("YYYY-MM-DD, HH:mm:ss");

    return (
      <>
        <Popup closeButton={false} position={[0, 0]}>
          <WarningOutlined
            style={{
              position: "absolute",
              top: 8,
              right: 8,
              color: unit.lineColor,
              fontSize: 15
            }}
            title={t("Alarm")}
          />
          <Row>
            <Text strong style={{ marginBottom: size.s1, fontSize: 14 }}>
              {unit.serialNumber}
            </Text>
          </Row>
          {!isUndefined(unit.project) ? (
            <Row style={{ marginBottom: 3 }}>
              <Text>
                {t("Project")}: {unit.project?.name}
                <Link
                  title={t("OpenProject")}
                  style={{ padding: size.s1, color: unit.lineColor }}
                  onClick={() => openProject(unit.project?.id ?? "")}
                >
                  <ScheduleOutlined />
                </Link>
              </Text>
            </Row>
          ) : (
            <></>
          )}
          <Row style={{ marginBottom: 3 }}>
            <Text>{dateTime}</Text>
          </Row>
          <Row style={{ marginBottom: 3 }}>
            <Text>
              {info.path[0] + ", " + info.path[1]}
              <MapProviderLink
                lat={info.path[0]}
                lon={info.path[1]}
                text={false}
                color={unit.lineColor}
              />
            </Text>
          </Row>
          <Row style={{ marginBottom: 3 }}>
            <Text>
              {SpeedConverter(info.velocity, speedUnit) + " " + t(speedUnit)}
            </Text>
          </Row>
        </Popup>
      </>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        height: "245px",
        overflow: "hidden"
      }}
    >
      <MapContainer
        style={{
          width: "100%",
          height: "245px"
        }}
        center={outerBounds.length <= 1 ? center : undefined}
        bounds={outerBounds.length > 1 ? outerBounds : undefined}
        zoom={11}
        scrollWheelZoom={true}
      >
        <MapHook />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxNativeZoom={19}
          maxZoom={20}
        />

        {showStartPos &&
          deviceData.length > 0 &&
          deviceData.map((unit, index) => {
            // Returning gray instead of black for better visibility on the map
            const color =
              unit.lineColor === "#000000" ? "#333333" : unit.lineColor;
            const customMarker = new L.DivIcon({
              iconAnchor: [10, 10],
              popupAnchor: [0.5, -10],
              html: ReactDOMServer.renderToString(
                <HomeTwoTone
                  twoToneColor={color}
                  style={{ fontSize: 20, color: color }}
                />
              ),
              className: "custom-icon"
            });

            return (
              <React.Fragment key={index}>
                {unit.firstPos?.firstLat && unit.firstPos.firstLon && (
                  <Marker
                    key={unit.parameterId}
                    position={[unit.firstPos.firstLat, unit.firstPos.firstLon]}
                    icon={customMarker}
                    zIndexOffset={10}
                  >
                    <Popup closeButton={false}>
                      <HomeOutlined
                        style={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          color: unit.lineColor,
                          fontSize: 15
                        }}
                        title={t("StartPosition")}
                      />
                      <Row>
                        <Text
                          strong
                          style={{ marginBottom: size.s1, fontSize: 14 }}
                        >
                          {unit.serialNumber}
                        </Text>
                      </Row>
                      <License right="ADI">
                        {unit.project && (
                          <Row style={{ marginBottom: 3 }}>
                            <Text>
                              {t("Project")}: {unit.project?.name}
                              <Link
                                title={t("OpenProject")}
                                style={{
                                  padding: size.s1,
                                  color: unit.lineColor
                                }}
                                onClick={() =>
                                  openProject(unit.project?.id ?? "")
                                }
                              >
                                <ScheduleOutlined />
                              </Link>
                            </Text>
                          </Row>
                        )}
                      </License>
                      <Row style={{ marginBottom: 3 }}>
                        <Text>{unit.firstPos.firstDateTime}</Text>
                      </Row>
                      <Row style={{ marginBottom: 3 }}>
                        <Text>
                          {unit.firstPos.firstLat +
                            ", " +
                            unit.firstPos.firstLon}
                          <MapProviderLink
                            lat={unit.firstPos.firstLat}
                            lon={unit.firstPos.firstLon}
                            text={false}
                            color={unit.lineColor}
                          />
                        </Text>
                      </Row>
                    </Popup>
                  </Marker>
                )}
              </React.Fragment>
            );
          })}

        {showPolylines &&
          deviceData &&
          deviceData.map((unit, index) => {
            const linePositions: LatLngExpression[] = unit.gpsPosData.map(
              (data) => data.path
            );

            return (
              unit.gpsPosData.length > 0 && (
                <Polyline
                  pathOptions={{ color: unit.lineColor }}
                  positions={linePositions}
                  key={index}
                />
              )
            );
          })}

        {showDevices &&
          deviceData.length > 0 &&
          deviceData.map((unit, index) => {
            return (
              <React.Fragment key={index}>
                {unit.lastPos && (
                  <Marker
                    key={unit.parameterId}
                    position={[unit.lastPos.lastLat, unit.lastPos.lastLon]}
                    icon={deviceIcon}
                    zIndexOffset={15}
                  >
                    <Popup closeButton={false}>
                      <div
                        style={{
                          position: "absolute",
                          top: 10,
                          right: 8,
                          borderLeft: "3px solid" + unit.lineColor,
                          fontSize: 14
                        }}
                        title={t("StartPosition")}
                      >
                        &nbsp;
                      </div>
                      <Row>
                        <Text
                          strong
                          style={{ marginBottom: size.s1, fontSize: 14 }}
                        >
                          {unit.serialNumber}
                        </Text>
                      </Row>
                      {unit.project && (
                        <Row style={{ marginBottom: 3 }}>
                          <Text>
                            {t("Project")}: {unit.project?.name}
                            <Link
                              title={t("OpenProject")}
                              style={{
                                padding: size.s1,
                                color: unit.lineColor
                              }}
                              onClick={() =>
                                openProject(unit.project?.id ?? "")
                              }
                            >
                              <ScheduleOutlined />
                            </Link>
                          </Text>
                        </Row>
                      )}
                      <Row style={{ marginBottom: 3 }}>
                        <Text>{unit.lastPos.lastDateTime}</Text>
                      </Row>
                      <Row style={{ marginBottom: 3 }}>
                        <Text>
                          {unit.lastPos.lastLat + ", " + unit.lastPos.lastLon}
                          <MapProviderLink
                            lat={unit.lastPos.lastLat}
                            lon={unit.lastPos.lastLon}
                            text={false}
                            color={unit.lineColor}
                          />
                        </Text>
                      </Row>
                    </Popup>
                  </Marker>
                )}
              </React.Fragment>
            );
          })}

        {showAlarms &&
          deviceData.length > 0 &&
          deviceData.map((unit, index) => {
            const popupInfo = unit.gpsPosData.filter(
              (data) => data.status === 1024
            );

            // Returning gray instead of black for better visibility on the map
            const color =
              unit.lineColor === "#000000" ? "#333333" : unit.lineColor;
            const customAlarmMarker = new L.DivIcon({
              iconAnchor: [10, 10],
              popupAnchor: [0.5, -10],
              html: ReactDOMServer.renderToString(
                <WarningTwoTone
                  twoToneColor={color}
                  style={{ fontSize: 20, color: color }}
                />
              ),
              className: "custom-icon"
            });

            return (
              <React.Fragment key={index}>
                {popupInfo.map((info, index) => (
                  <Marker
                    key={index}
                    position={[info.path[0], info.path[1]]}
                    icon={customAlarmMarker}
                    zIndexOffset={5}
                  >
                    {alarmPopup(t, unit, info)}
                  </Marker>
                ))}
              </React.Fragment>
            );
          })}
      </MapContainer>
    </div>
  );
};

export default DeviceMap;

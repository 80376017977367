import React from "react";
import {
  AimOutlined,
  DashboardOutlined,
  FormOutlined,
  InfoCircleOutlined,
  TeamOutlined,
  WarningOutlined
} from "@ant-design/icons";
import { skipToken } from "@reduxjs/toolkit/query";
import { Tabs, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import {
  useGetParametersQuery,
  useGetProjectAlarmQuery,
  useGetProjectGpsQuery,
  useGetProjectNotesQuery,
  useGetProjectNotificationByIdQuery,
  useProjectByIdQuery
} from "../../state/cargologRestApi";
import { ExportPdfButton } from "../Common/CommonButtons";
import {
  ProjectModalTabs,
  closeProjectModal,
  projectsState,
  resetProjectModalTab,
  setProjectModalTab,
  setSelectedProjectId
} from "../../state/projectSlice";
import NotesTab from "../Projects/NotesTab";
import AlarmsTab, { AlarmRow, getAlarmData } from "./AlarmsTab";
import OverviewTab from "./OverviewTab";
import PeopleTab, { peopleTableData } from "./PeopleTab";
import RecordingsTab, { getLineColor } from "./RecordingsTab";
import StatusTab from "./StatusTab";
import StandardModal from "../Common/StandardModal";
import { useExportPdfHook } from "../PrintExport/pdfExport";
import {
  DocumentProps,
  ItemHeaderData,
  ProjectHeaderData
} from "../../helpers/pdf/pdfInterfaces";
import {
  ExportableItem,
  ExportMultipleComponent
} from "../PrintExport/pdfMultiExport";
import dayjs from "dayjs";
import AlarmsTabTable from "./AlarmsTabTable";
import ActivePeopleTabTable from "./ActivePeopleTabTable";
import { getUser } from "../../state/sessionSlice";
import { activePeopleData } from "./projectPeopleData";
import StatusTabTable from "./StatusTabTable";
import { DeviceData, GpsPosData } from "../DevicesPage/DeviceMap";
import { isNil, isUndefined } from "lodash-es";
import { deviceColorsArray } from "../../constants/colors";
import { LatLngExpression, LatLngTuple } from "leaflet";

const { TabPane } = Tabs;

const tabBarHeight = 62;

const ProjectModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isProjectModalOpen, projectModalTab, selectedProjectId } =
    useSelector(projectsState);

  const { data: project } = useProjectByIdQuery(
    selectedProjectId ? { id: selectedProjectId } : skipToken
  );

  // For the RecordingsTabTable:
  const { data: parameters, isLoading: loadingParameters } =
    useGetParametersQuery(
      selectedProjectId ? { projectId: selectedProjectId } : skipToken
    );

  const { data: recordings, isLoading: loadingPositions } =
    useGetProjectGpsQuery(
      selectedProjectId ? { id: selectedProjectId } : skipToken
    );

  /** Used for centering the map when there is only one device in the project */
  // let center: LatLngTuple = [57.8563, 14.30782];
  /** Used for creating a map bounds that shows all the devices in the project*/
  let outerBounds: LatLngTuple[] = [];
  const gpsData = recordings
    ? recordings.map((gps) => {
        if (gps.gpsData?.length === 0) {
          return;
        }
        const lastElement = gps.gpsData?.[gps.gpsData.length - 1];

        const timezoneOffset = new Date().getTimezoneOffset();
        const lastDateTime = dayjs
          .utc(lastElement?.dateTime)
          .subtract(timezoneOffset, "minutes")
          .format("YYYY-MM-DD, HH:mm:ss");

        const lastVelocity = lastElement?.velocity;
        const lastStatus = lastElement?.status ?? 100;

        const firstDate = gps.gpsData?.[0].dateTime;
        const firstDateTime = dayjs
          .utc(firstDate)
          .subtract(timezoneOffset, "minutes")
          .format("YYYY-MM-DD, HH:mm:ss");
        const firstLat = gps.gpsData?.[0].lat;
        const firstLon = gps.gpsData?.[0].lon;

        const lastLat = lastElement?.lat ?? 57.8563;
        const lastLon = lastElement?.lon ?? 14.30782;

        const mapOrigo: LatLngExpression = [lastLat, lastLon];

        outerBounds.push(mapOrigo);
        // center = mapOrigo;

        const lastPos = {
          lastDateTime: lastDateTime,
          lastLat: lastLat,
          lastLon: lastLon,
          lastVelocity: lastVelocity ?? 0,
          lastStatus: lastStatus
        };

        /** Used for Polyline */
        const gpsPosData: GpsPosData[] = gps.gpsData
          ? gps.gpsData.map((data) => {
              // todo: should this include all gps positions or only the devices?
              outerBounds.push([data.lat, data.lon]);

              return {
                path: [data.lat, data.lon],
                velocity: data.velocity,
                dateTime: data.dateTime,
                status: data.status
              };
            })
          : [];

        const sortedGpsPos = gpsPosData.sort((a, b) =>
          a.dateTime.localeCompare(b.dateTime)
        );
        return {
          parameterId: gps.parameterId ?? "",
          firstPos: {
            firstDateTime,
            firstLat,
            firstLon
          },
          lastPos: lastPos,
          gpsPosData: sortedGpsPos
        };
      })
    : [];

  const colorsArray = [...deviceColorsArray];

  const deviceData: DeviceData[] = parameters
    ? parameters
        .map((param, index) => {
          const gps = gpsData?.find((gps) => gps?.parameterId === param.id);
          const deviceAlarms = project?.alarms?.filter(
            (alarm) => alarm.parameterId === param.id
          );
          return {
            parameterId: param.id ?? "",
            fileName: param.fileName ?? "",
            firstPos: gps?.firstPos,
            lastPos: gps?.lastPos,
            recordingStart: param.firstStart,
            latestData: param.hasData ? param.lastData : "",
            lastUploader: param.lastUploader ?? "",
            lastUploadType: param.lastUploadType ?? "",
            created: param.created ?? "",
            creator: param.creator ?? "",
            serialNumber: param.deviceName ?? "",
            deviceDescription: param.deviceDescription ?? "",
            gpsPosData: gps?.gpsPosData ?? [],
            lineColor: !isUndefined(gps)
              ? getLineColor(colorsArray).color
              : "transparent",
            alarms: deviceAlarms?.length ?? 0,
            project: {
              id: param.projectId,
              name: ""
            },
            hasData: param.hasData,
            isActive: param.isActive,
            key: index.toString()
          };
        })
        .sort((a: any, b: any) =>
          b.latestData?.toLowerCase().localeCompare(a.latestData?.toLowerCase())
        )
    : [];

  // For the AlarmsTabTable:
  const { data: projectAlarms, isLoading: loadingAlarms } =
    useGetProjectAlarmQuery(
      selectedProjectId ? { id: selectedProjectId } : skipToken
    );

  // Main data parsing and filtering
  const alarmsData: AlarmRow[] = projectAlarms
    ? projectAlarms
        .map((alarm, index) => ({
          key: index,
          sensor: alarm.type,
          serial: alarm.serial,
          timestamp: alarm.dateTime,
          parameterId: alarm.parameterId,
          fileName: alarm.fileName,
          data: getAlarmData(alarm)
        }))
        .sort((a, b) => {
          if (a.timestamp < b.timestamp) return 1;
          if (a.timestamp > b.timestamp) return -1;
          return 0;
        })
    : [];

  // For the ActivePeopleTabTable:
  const user = useSelector(getUser);
  const { data: projectEmailNotifications, isLoading: loadingPeople } =
    useGetProjectNotificationByIdQuery(project?.id ? project.id : skipToken);

  let activePeople: peopleTableData[] = [];

  if (project) {
    activePeople = activePeopleData({
      project: project,
      emailNotifications: projectEmailNotifications
    });
  }

  // For the NotesTabNotes:
  const { data: notes, isLoading: loadingNotes } = useGetProjectNotesQuery(
    selectedProjectId ? selectedProjectId : skipToken
  );

  interface NotesTableData {
    id: string;
    user: {
      id: string;
      name: string;
      created: string;
      lastEdit: string;
    };
    text: string;
  }
  let notesData: NotesTableData[] | undefined = undefined;

  if (!isUndefined(notes)) {
    notesData = notes
      .map((note) => {
        let row: NotesTableData = {
          id: note.id,
          user: {
            id: note.user.userId,
            name: `${note.user.firstName} ${note.user.lastName}`,
            created: note.created,
            lastEdit: note.lastEdit ?? ""
          },
          text: note.text
        };
        return row;
      })
      .reverse();
  }

  // Detect if the selected project data has been loaded
  const loading = !selectedProjectId || selectedProjectId !== project?.id;
  const loadingExportBtn =
    loading ||
    loadingAlarms ||
    loadingPeople ||
    loadingNotes ||
    loadingParameters ||
    loadingPositions;

  const onChangeTab = (key: ProjectModalTabs) => {
    dispatch(setProjectModalTab(key));
  };

  const { isExporting, startExport, finishExport } = useExportPdfHook();

  const tabIconStyle: React.CSSProperties = {
    marginRight: size.s2
  };

  const closeModalFunctions = () => (
    dispatch(closeProjectModal()),
    dispatch(resetProjectModalTab()),
    dispatch(setSelectedProjectId(undefined))
  );

  const documentProps: DocumentProps = {
    documentTitle: t("ProjectReport"),
    fileName: "project_report.pdf"
  };

  const itemHeaders: ItemHeaderData[] = [
    { itemType: "Alarms", itemTitle: "Alarms" },
    { itemType: "Recordings", itemTitle: "Recordings" },
    { itemType: "MembersInProject", itemTitle: "MembersInProject" },
    { itemType: "ProjectStatus", itemTitle: "ProjectStatus" },
    { itemType: "Notes", itemTitle: "Notes" }
  ];

  const timezoneOffset = new Date().getTimezoneOffset();
  const startTime = dayjs
    .utc(project?.start)
    .subtract(timezoneOffset, "minutes")
    .format("YYYY-MM-DD, HH:mm:ss");
  const endTime = dayjs
    .utc(project?.end)
    .subtract(timezoneOffset, "minutes")
    .format("YYYY-MM-DD, HH:mm:ss");

  const projectHeaderData: ProjectHeaderData = {
    reportType: "ProjectReport",
    title: project?.title ?? "",
    description: project?.description ?? "",
    startTime: startTime,
    endTime: endTime
  };

  const exportableItems: ExportableItem[] = [
    {
      ComponentBody: AlarmsTabTable,
      componentProps: {
        alarmsData,
        loadingAlarms,
        isExport: true
      }
    },
    {
      ComponentBody: () => {
        return (
          <>
            <table>
              <thead>
                <tr>
                  <th>{t("SerialNumber")}</th>
                  <th>{t("RecordingStart")}</th>
                  <th>{t("LatestUpload")}</th>
                  <th>{t("Alarms")}</th>
                  <th>{t("Status")}</th>
                </tr>
              </thead>
              <tbody>
                {deviceData.map((device) => {
                  const recordingStart = device.recordingStart;

                  const renderRecdordingStart = () => {
                    if (isNil(recordingStart)) {
                      return t("NoDataUploaded");
                    }
                    return dayjs
                      .utc(recordingStart)
                      .local()
                      .format("YYYY-MM-DD HH:mm");
                  };

                  const latestData = device.latestData;
                  const renderLatestData = () => {
                    if (latestData === "") {
                      return t("NoDataUploaded");
                    }
                    return dayjs
                      .utc(latestData)
                      .local()
                      .format("YYYY-MM-DD HH:mm");
                  };

                  const isActive = device.isActive
                    ? t("StatusActive")
                    : t("StatusFinished");

                  const deviceDescription = device.deviceDescription;
                  const renderDeviceDescription = () => {
                    if (deviceDescription === "") {
                      return t("NoDescription");
                    }
                    return `${deviceDescription}`;
                  };

                  return (
                    <>
                      <tr key={device.parameterId}>
                        <td>{device.serialNumber}</td>
                        <td>{renderRecdordingStart()}</td>
                        <td>{renderLatestData()}</td>
                        <td>{device.alarms}</td>
                        <td>{isActive}</td>
                      </tr>
                      <tr>
                        <td colSpan={5}>
                          {t("RecordingDescription")} {": "}{" "}
                          {renderDeviceDescription()}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </>
        );
      },
      componentProps: {}
    },
    {
      ComponentBody: ActivePeopleTabTable,
      componentProps: {
        activePeople: activePeople,
        project: project,
        projectEmailNotifications: projectEmailNotifications,
        user: user,
        userRights: project?.userRights,
        isExport: true
      }
    },
    {
      ComponentBody: StatusTabTable,
      componentProps: {
        projectStatus: project?.status
      }
    },
    {
      ComponentBody: () => (
        <>
          {notesData &&
            notesData.length > 0 &&
            notesData.map((note) => {
              const timezoneOffset = new Date().getTimezoneOffset();
              const noteCreated = dayjs
                .utc(note.user.created)
                .subtract(timezoneOffset, "minutes")
                .format("YYYY-MM-DD, HH:mm");

              let noteLastEdit = "";

              if (note.user.lastEdit !== "") {
                noteLastEdit = dayjs
                  .utc(note.user.lastEdit)
                  .subtract(5, "seconds")
                  .fromNow();
              }

              return (
                <table>
                  <tbody>
                    <tr>
                      <td colSpan={3}>
                        {note.user.name + " "} {noteCreated}{" "}
                        {noteLastEdit !== "" &&
                          `(${t("Edited")}: ${noteLastEdit})`}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>{note.text}</td>
                    </tr>
                  </tbody>
                </table>
              );
            })}
        </>
      ),
      componentProps: {}
    }
  ];

  return (
    <>
      {isExporting && (
        <ExportMultipleComponent
          exportableItems={exportableItems}
          itemHeaders={itemHeaders}
          documentProps={documentProps}
          projectHeader={projectHeaderData}
          reportExportDone={finishExport}
        />
      )}

      <StandardModal
        title={
          loading ? (
            <Typography.Text type="secondary" strong style={{ fontSize: 20 }}>
              {t("Loading")}...
            </Typography.Text>
          ) : (
            project?.title
          )
        }
        open={isProjectModalOpen}
        zIndex={1040}
        onCancel={() => closeModalFunctions()}
        closable={true}
        footer={null}
        width={1000}
        extraButtons={[
          <ExportPdfButton
            disabled={loadingExportBtn}
            loading={loadingExportBtn}
            key={"ExportPDF"}
            onClick={() => startExport()}
          >
            {t("ExportPDF")}
          </ExportPdfButton>
        ]}
        destroyOnClose={true}
      >
        <Tabs
          defaultActiveKey="overview"
          activeKey={projectModalTab}
          onChange={(key) => onChangeTab(key as ProjectModalTabs)}
          destroyInactiveTabPane={true}
          tabBarStyle={{
            position: "absolute",
            backgroundColor: "white",
            zIndex: 1045,
            // Subtracting modalbody x-padding
            width: "calc(100% - (24px + 24px))",
            marginRight: size.l2,
            marginBottom: 0
          }}
        >
          <TabPane
            tab={
              <>
                <DashboardOutlined style={tabIconStyle} />
                {t("Overview")}
              </>
            }
            key="overview"
            style={{ paddingTop: tabBarHeight }}
          >
            <OverviewTab project={loading ? undefined : project} />
          </TabPane>

          <TabPane
            tab={
              <>
                <WarningOutlined style={tabIconStyle} />
                {t("Alarms")}
              </>
            }
            key="alarms"
            style={{ paddingTop: tabBarHeight }}
          >
            <AlarmsTab />
          </TabPane>

          <TabPane
            tab={
              <>
                <AimOutlined style={tabIconStyle} />
                {t("Recordings")}
              </>
            }
            key="recordings"
            style={{ paddingTop: tabBarHeight }}
          >
            <RecordingsTab project={loading ? undefined : project} />
          </TabPane>

          <TabPane
            tab={
              <>
                <FormOutlined style={tabIconStyle} />
                {t("Notes")}
              </>
            }
            key="notes"
            style={{ paddingTop: tabBarHeight }}
          >
            <NotesTab />
          </TabPane>

          <TabPane
            tab={
              <>
                <TeamOutlined style={tabIconStyle} />
                {t("People")}
              </>
            }
            key="people"
            style={{ paddingTop: tabBarHeight }}
          >
            <PeopleTab
              project={loading ? undefined : project}
              loadingProject={loading}
            />
          </TabPane>

          <TabPane
            tab={
              <>
                <InfoCircleOutlined style={tabIconStyle} />
                {t("ProjectStatus")}
              </>
            }
            key="status"
            style={{ paddingTop: tabBarHeight }}
          >
            <StatusTab project={loading ? undefined : project} />
          </TabPane>
        </Tabs>
      </StandardModal>
    </>
  );
};

export default ProjectModal;

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetParametersQuery,
  useProjectByIdQuery,
  useRemoveRecentDeviceMutation
} from "../../state/cargologRestApi";
import {
  closeRemoveDeviceModal,
  modalsState,
  setDeviceToRemove
} from "../../state/modalsSlice";
import StandardModal from "../Common/StandardModal";
import { NormalButton, PrimaryButton } from "../Common/CommonButtons";
import { notification, Typography } from "antd";
import { isUndefined } from "lodash-es";
import { skipToken } from "@reduxjs/toolkit/query";
const { Text } = Typography;

const RemoveDeviceModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isRemoveDeviceModalOpen, deviceToRemove } = useSelector(modalsState);

  const { data: parameters } = useGetParametersQuery({});

  const activeParameters =
    !isUndefined(deviceToRemove) && !isUndefined(parameters)
      ? parameters.filter((p) => p.deviceName === deviceToRemove && p.isActive)
      : [];

  const { data: project } = useProjectByIdQuery(
    activeParameters.length === 0
      ? skipToken
      : { id: activeParameters[0].projectId }
  );

  const [removeDevice, requestStatus] = useRemoveRecentDeviceMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const handleRemoveDevice = async (request: string) => {
    const result: any = await removeDevice({ serialNum: request });
    if (result.error) {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      } else if (e) {
        for (let i in e) {
          errorDescription += " " + e[i][0];
        }
      }
      notification.error({
        message: t("FailedToRemoveTheDevice"),
        description: errorDescription
      });
    } else {
      notification.success({ message: t("SuccessfullyRemovedTheDevice") });
      dispatch(setDeviceToRemove(undefined));
      dispatch(closeRemoveDeviceModal());
    }
  };

  const onFinish = () => {
    if (!isUndefined(deviceToRemove)) {
      handleRemoveDevice(deviceToRemove);
    }
  };

  const closeModal = () => {
    dispatch(setDeviceToRemove(undefined));
    dispatch(closeRemoveDeviceModal());
  };

  return (
    <StandardModal
      title={t("DeleteDevice")}
      open={isRemoveDeviceModalOpen}
      onCancel={closeModal}
      closable={true}
      destroyOnClose={true}
      footer={[
        <NormalButton key="cancel" onClick={closeModal}>
          {t("Cancel")}
        </NormalButton>,
        <PrimaryButton
          key="delete"
          danger
          onClick={onFinish}
          loading={requestIsLoading}
          disabled={activeParameters.length > 0}
        >
          {t("Remove")}
        </PrimaryButton>
      ]}
    >
      {activeParameters.length > 0 ? (
        <>
          <Text>{t("ThisDeviceIsCurrentlyActive")}</Text>
          <Text strong> {project?.title}</Text>
          <Text>.</Text>
          <br />
          <Text>{t("IfYouWantToDeleteThisDevice")} </Text>
        </>
      ) : (
        <>
          <Text>{t("AreYouSureYouWantToDeleteDevice")}</Text>
          <Text strong> {deviceToRemove}</Text>
          <Text>?</Text>
        </>
      )}
    </StandardModal>
  );
};

export default RemoveDeviceModal;

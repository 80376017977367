import React, { useState } from "react";
import StandardModal from "../Common/StandardModal";
import { useTranslation } from "react-i18next";
import {
  closeSaveParxTemplateModal,
  modalsState
} from "../../state/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../Common/CommonButtons";
import { Form, Input, notification } from "antd";
import { selectRecordingParameters } from "../../state/openParxSlice";
import {
  ParXTemplateForCreation,
  useUploadParXTemplateDataMutation,
  useUploadParXTemplateFileMutation
} from "../../state/cargologRestApi";
import {
  selectTargetDevice,
  setSelectedParXTemplate
} from "../../state/paramsPageSlice";
import { createPackValidateParx } from "../../helpers/paramsHelper";
import { vmRecordingParameters2DataModel } from "../../helpers/dataModelHelper";
import { getUser } from "../../state/sessionSlice";
import { isUndefined } from "lodash-es";

const SaveParxTemplateModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isSaveParxTemplateModalOpen } = useSelector(modalsState);
  const user = useSelector(getUser);

  const [templateName, setTemplateName] = useState<string>();
  const [templateDescription, setTemplateDescription] = useState<string>();

  const recParams = useSelector(selectRecordingParameters);
  const targetDevice = useSelector(selectTargetDevice);

  const [uploadParXTemplateFile, fileRequestStatus] =
    useUploadParXTemplateFileMutation();
  const { isLoading: fileIsLoading } = fileRequestStatus;

  const [uploadParXTemplateData, dataRequestStatus] =
    useUploadParXTemplateDataMutation();
  const { isLoading: dataIsLoading } = dataRequestStatus;

  const handleSaveParxTemplateRequest = async (
    request: Buffer,
    userId: string,
    companyId: string
  ) => {
    /** Upload parX template file to blobstorage */
    const fileResult: any = await uploadParXTemplateFile(request);
    if (fileResult.data) {
      const parXTemplateData: ParXTemplateForCreation = {
        name: templateName!,
        description: !isUndefined(templateDescription)
          ? templateDescription
          : "",
        fileName: fileResult.data,
        createdByUserId: userId,
        companyId: companyId
      };

      /** Upload parX template to table */
      const dataResult: any = uploadParXTemplateData(parXTemplateData);

      if (dataResult.error) {
        notification.error({
          message: t("FailedToSaveParxTemplate")
        });
      } else {
        notification.success({
          message: t("SuccessfullySavedParxTemplate")
        });
        setTemplateName(undefined);
        setTemplateDescription(undefined);
        dispatch(setSelectedParXTemplate(undefined));
        dispatch(closeSaveParxTemplateModal());
      }
    } else if (fileResult.error) {
      let errorDescription: string = "";
      const e = fileResult.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      } else if (e) {
        for (let i in e) {
          errorDescription += " " + e[i][0];
        }
      }
      notification.error({
        message: t("FailedToSaveParxFile"),
        description: errorDescription
      });
    }
  };

  const onFinish = () => {
    if (
      templateName !== "" &&
      !isUndefined(user.userId) &&
      !isUndefined(user.companyId)
    ) {
      const recordingParameters = vmRecordingParameters2DataModel(
        recParams,
        targetDevice
      );

      const { packed, isValid } = createPackValidateParx(recordingParameters);

      if (isUndefined(templateName)) {
        notification.error({
          message: t("TemplateNameIsRequired")
        });
      } else if (!isValid) {
        notification.error({
          message: t("FailedToSaveParxTemplate")
        });
      } else {
        handleSaveParxTemplateRequest(packed, user.userId, user.companyId);
      }
    }
  };

  return (
    <StandardModal
      title={t("SaveParxTemplate")}
      open={isSaveParxTemplateModalOpen}
      zIndex={1045}
      width={400}
      closable={true}
      onCancel={() => {
        dispatch(closeSaveParxTemplateModal());
      }}
      destroyOnClose={true}
      footer={null}
    >
      <Form
        name="basic"
        onFinish={() => onFinish()}
        layout="vertical"
        style={{ width: "100%" }}
      >
        <Form.Item
          label={t("TemplateName")}
          name="templateName"
          rules={[{ required: true, message: t("TemplateNameIsRequired") }]}
        >
          <Input
            placeholder=""
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
          />
        </Form.Item>

        <Form.Item label={t("TemplateDescription")} name="templateDescription">
          <Input.TextArea
            rows={4}
            showCount
            maxLength={256}
            value={templateDescription}
            onChange={(e) => setTemplateDescription(e.target.value)}
          />
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <PrimaryButton
            style={{ width: "100%", height: "40px" }}
            htmlType="submit"
            loading={fileIsLoading || dataIsLoading}
          >
            {t("UploadTemplate")}
          </PrimaryButton>
        </Form.Item>
      </Form>
    </StandardModal>
  );
};

export default SaveParxTemplateModal;

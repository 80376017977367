import {
  FilePdfOutlined,
} from "@ant-design/icons";
import { Col, Row, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createUtcOffsetStr, guessLocalTz } from "../../helpers/dateHelper";
import { getEndDate, getStartDate } from "../../helpers/exportHelper";
import { selectRecordingParametersItemsForPrint } from "../../state/openParxSlice";
import {
  nextParamsPage,
  prevParamsPage,
  selectParamsPageState
} from "../../state/paramsPageSlice";
import { NormalButton } from "../Common/CommonButtons";
import { NormalCard } from "../Common/CommonCards";
import { Help } from "../MicroComponents/Help";
import { PdfExportComponent, useExportPdfHook } from "../PrintExport/pdfExport";
import {
  TransportHeaderData,
  DocumentProps,
  ItemHeaderData
} from "../../helpers/pdf/pdfInterfaces";
import ParamsPreview from "./ParamsPreview";
import { size } from "../../helpers/pageHelper";

interface ExportProps {
  itemHeader: ItemHeaderData;
  documentProps: DocumentProps;
  transportHeader: TransportHeaderData;
}

/** Local component: Export current recording parameters as PDF*/
const ExportRecordingParametersAsPdfButton = (exportProps: ExportProps) => {
  const { recordingParameters, targetDevice: systemInfo } = useSelector(
    selectRecordingParametersItemsForPrint
  );

  const { isExporting, startExport, finishExport } = useExportPdfHook();

  return (
    <>
      {isExporting && (
        <PdfExportComponent
          ComponentBody={ParamsPreview}
          componentProps={{
            systemInfo,
            recordingParameters
          }}
          documentProps={exportProps.documentProps}
          itemHeader={exportProps.itemHeader}
          transportHeader={exportProps.transportHeader}
          reportExportDone={finishExport}
        />
      )}
      <NormalButton icon={<FilePdfOutlined />} onClick={startExport}>
        PDF
      </NormalButton>
    </>
  );
};

interface IProps {
  contentWidth: number;
}
const ParamsBuilderFooter: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { userProgress } = useSelector(selectParamsPageState);

  const dispatch = useDispatch();

  const shouldPrevBtnRender = userProgress !== 0;
  const shouldNextBtnRender = userProgress !== 3;
  const shouldLoadBtnRender = userProgress === 3;

  const goPrev = () => {
    dispatch(prevParamsPage());
  };

  const goNext = () => {
    dispatch(nextParamsPage());
  };

  const { recordingParameters, targetDevice } = useSelector(
    selectRecordingParametersItemsForPrint
  );

  const systemInfo = targetDevice;

  const timezoneStr = useSelector(guessLocalTz);
  const utcOffsetStr = createUtcOffsetStr(timezoneStr);
  const timezone = {
    timezoneStr,
    utcOffsetStr
  };

  const documentProps: DocumentProps = {
    documentTitle: t("ParametersPreview"),
    fileName: "recording_parameters_preview.pdf"
  };

  const itemHeader: ItemHeaderData = {
    itemType: "ParametersPreview",
    itemTitle: t("RecordingParameters")
  };

  const transportHeader: TransportHeaderData = {
    reportType: "ParametersPreview",
    projectName: recordingParameters.ProjectName,
    recStart: getStartDate(recordingParameters, timezone, t),
    recEnd: getEndDate(recordingParameters, timezone, t),
    deviceId: systemInfo.serial.toString()
  };

  return (
    <NormalCard size="default" style={{ width: "100%", padding: size.l2 }}>
      <Row justify="center">
        <Row
          justify="space-between"
          style={{ maxWidth: props.contentWidth, width: "100%" }}
        >
          <Col>
            {shouldPrevBtnRender && (
              <NormalButton onClick={goPrev}>{t("Previous")}</NormalButton>
            )}
          </Col>
          <Col>
            {shouldLoadBtnRender ? (
              <Space>
                <ExportRecordingParametersAsPdfButton
                  documentProps={documentProps}
                  itemHeader={itemHeader}
                  transportHeader={transportHeader}

                />
                {/* {Help(
                  <LoadRecordingParametersButton />,
                  t("ClickToDownloadSelectedParameters"),
                  "topRight"
                )} */}
              </Space>
            ) : (
              // Issue with Help component in an if-statement
              // Help(
                <NormalButton onClick={goNext}>{t("Next")}</NormalButton>
              //   t("ClickHereToGoToTheNextStep"),
              //   "left"
              // )
            )}
          </Col>
        </Row>
      </Row>
    </NormalCard>
  );
};

export default ParamsBuilderFooter;

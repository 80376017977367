import React from "react";
import StandardModal from "../Common/StandardModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  closeDeleteParXTemplateModal,
  modalsState
} from "../../state/modalsSlice";
import { notification, Typography } from "antd";
import { NormalButton, PrimaryButton } from "../Common/CommonButtons";
import {
  selectParamsPageState,
  setSelectedDeleteParXTemplate
} from "../../state/paramsPageSlice";
import { useDeleteParXTemplateMutation } from "../../state/cargologRestApi";
import { isUndefined } from "lodash-es";
const { Text } = Typography;

const RemoveParXTemplateModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isDeleteParXTemplateModalOpen } = useSelector(modalsState);
  const { selectedDeleteParXTemplate } = useSelector(selectParamsPageState);

  const [deleteParXTemplate, request] = useDeleteParXTemplateMutation();
  const { isLoading } = request;

  const handleDeleteTemplate = async (requestId: string) => {
    const result: any = await deleteParXTemplate({ templateId: requestId });

    if (result.data) {
      notification.success({ message: t("SuccessfullyDeletedTemplate") });
      dispatch(setSelectedDeleteParXTemplate(undefined));
      dispatch(closeDeleteParXTemplateModal());
    } else {
      notification.error({ message: t("UnableToDeleteTemplate") });
    }
  };

  const onFinish = () => {
    if (
      !isUndefined(selectedDeleteParXTemplate) &&
      !isUndefined(selectedDeleteParXTemplate.id)
    ) {
      const requestId: string = selectedDeleteParXTemplate.id;
      handleDeleteTemplate(requestId);
    }
  };

  return (
    <StandardModal
      title={t("DeleteParxTemplate")}
      open={isDeleteParXTemplateModalOpen}
      zIndex={1045}
      width={400}
      closable={true}
      onCancel={() => {
        dispatch(closeDeleteParXTemplateModal());
      }}
      destroyOnClose={true}
      footer={[
        <NormalButton
          htmlType="submit"
          onClick={() => dispatch(closeDeleteParXTemplateModal())}
        >
          {t("Cancel")}
        </NormalButton>,
        <PrimaryButton
          danger
          htmlType="submit"
          onClick={onFinish}
          loading={isLoading}
        >
          {t("DeleteTemplate")}
        </PrimaryButton>
      ]}
    >
      {`${t("AreYouSureYouWantToDelete")} ${t("theParXTemplate")} `}
      <Text strong>{`${selectedDeleteParXTemplate?.name}?`}</Text>
    </StandardModal>
  );
};

export default RemoveParXTemplateModal;

import React from "react";
import {
  Row,
  Col,
  CardProps,
  Typography,
  Statistic,
  Space,
  Progress
} from "antd";
import { size } from "../../helpers/pageHelper";
import {
  AppstoreAddOutlined,
  FileOutlined,
  FormOutlined,
  TeamOutlined,
  WarningOutlined,
  AimOutlined,
  CheckCircleOutlined
} from "@ant-design/icons";

import { isUndefined } from "lodash-es";
import { useDispatch } from "react-redux";
import {
  openProjectModal,
  setSelectedProjectId,
  setProjectModalTab
} from "../../state/projectSlice";
import { useTranslation } from "react-i18next";
import { Project } from "../../state/cargologRestApi";
import {
  renderStatus,
  calcTimeDiff,
  warningColor
} from "../../pages/ProjectsPage";
import { LiftedCard } from "../Common/CommonCards";
import { SmallTitle } from "../Common/CommonFonts";
import dayjs from "dayjs";
import _ from "lodash";

const { Text, Title, Paragraph } = Typography;

const landingCardHeight = 350;

const ProjectCard = (props: CardProps) => (
  <Col xs={24} lg={12} xl={8} xxl={6}>
    <LiftedCard
      {...props}
      bordered={false}
      style={{
        ...props.style,
        height: landingCardHeight,
        borderRadius: size.m1
      }}
    >
      {props.children}
    </LiftedCard>
  </Col>
);

interface IProps {
  projects?: Project[];
}

const ProjectsGrid = (props: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { projects } = props;

  /* Aligns item to top right of parent */
  const TopRight = (props: CardProps) => (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        padding: 0,
        paddingRight: size.m1,
        fontWeight: "normal"
      }}
    >
      {props.children}
    </div>
  );

  return (
    <>
      {!isUndefined(projects) &&
        projects.map((project, index) => {
          const timezoneOffset = new Date().getTimezoneOffset();
          const startTime = dayjs
            .utc(project?.start)
            .subtract(timezoneOffset, "minutes")
            .format("YYYY-MM-DD");
          const endTime = dayjs
            .utc(project?.end)
            .subtract(timezoneOffset, "minutes")
            .format("YYYY-MM-DD");

          return (
            <React.Fragment key={index}>
              <ProjectCard
                key={index}
                onClick={() => {
                  dispatch(openProjectModal());
                  dispatch(setSelectedProjectId(project.id));
                }}
                style={{
                  cursor: "pointer",
                  background: "#f8f9fb"
                }}
                styles={{
                  header: {
                    backgroundColor: "white",
                    borderTopLeftRadius: size.m1,
                    borderTopRightRadius: size.m1,
                    paddingBlock: size.m1
                  }
                }}
                title={
                  <>
                    <TopRight>
                      <Text type="secondary">{project.companyName}</Text>
                    </TopRight>
                    <Title
                      ellipsis
                      level={3}
                      style={{
                        marginTop: size.m1,
                        marginBottom: size.s1,
                        textAlign: "center"
                      }}
                    >
                      {project.title}
                    </Title>
                    <Paragraph
                      type="secondary"
                      ellipsis={{ rows: 1 }}
                      style={{ marginBottom: size.m1, textAlign: "center" }}
                    >
                      {project.description}
                    </Paragraph>
                  </>
                }
              >
                <div style={{ paddingInline: size.l2 }}>
                  <Row justify="center">
                    <Text strong>{t("Time")}</Text>
                    <br />
                  </Row>
                  {startTime === "Invalid Date" ||
                  endTime === "Invalid Date" ? (
                    <Row justify="center" style={{ paddingTop: 0 }}>
                      <Text type="secondary">{t("NoActiveLoggers")}</Text>
                    </Row>
                  ) : (
                    <>
                      <Progress
                        percent={calcTimeDiff(startTime, endTime)}
                        type="line"
                        size={["100%", 8]}
                        strokeColor={{
                          "0%": "#108ee9",
                          "100%": "#74b7e8"
                        }}
                        trailColor="#dddfe2"
                        status={project.status === 1 ? "active" : "normal"}
                      />
                    </>
                  )}
                </div>

                <div
                  style={{
                    position: "absolute",
                    bottom: size.l2,
                    left: 0,
                    width: "100%"
                  }}
                >
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                      paddingInline: size.l2
                    }}
                  >
                    <Row justify="center">
                      <Col span={8}>
                        <Row
                          justify="center"
                          onClick={() => dispatch(setProjectModalTab("alarms"))}
                        >
                          <Statistic
                            style={{ textAlign: "center" }}
                            title={t("Alarms")}
                            value={project.alarmCount}
                            prefix={
                              project.alarmCount > 0 ? (
                                <WarningOutlined />
                              ) : (
                                <CheckCircleOutlined />
                              )
                            }
                            valueStyle={{
                              color: warningColor(project.alarmCount > 0)
                            }}
                          />
                        </Row>
                      </Col>

                      <Col span={8}>
                        <Row
                          justify="center"
                          onClick={() =>
                            dispatch(setProjectModalTab("recordings"))
                          }
                        >
                          <Statistic
                            style={{ textAlign: "center" }}
                            title={t("Files")}
                            value={project.datXFileCount}
                            prefix={<FileOutlined />}
                          />
                        </Row>
                      </Col>

                      <Col span={8}>
                        <Row
                          justify="center"
                          onClick={() => dispatch(setProjectModalTab("notes"))}
                        >
                          <Statistic
                            style={{ textAlign: "center" }}
                            title={t("Notes")}
                            value={project.projectNotesCount ?? 0}
                            prefix={<FormOutlined />}
                          />
                        </Row>
                      </Col>
                    </Row>

                    <Row style={{ width: "100%" }} justify="space-between">
                      <Col span={8}>
                        <Row
                          justify="center"
                          onClick={() =>
                            dispatch(setProjectModalTab("recordings"))
                          }
                        >
                          <Statistic
                            style={{ textAlign: "center" }}
                            title={t("Recordings")}
                            value={project.deviceCount}
                            prefix={<AimOutlined />}
                          />
                        </Row>
                      </Col>

                      <Col span={8}>
                        <Row
                          justify="center"
                          onClick={() => dispatch(setProjectModalTab("people"))}
                        >
                          <Statistic
                            style={{ textAlign: "center" }}
                            title={t("People")}
                            value={project?.projectUsers?.length ?? 0}
                            prefix={<TeamOutlined />}
                          />
                        </Row>
                      </Col>

                      <Col span={8}>
                        <Row
                          justify="center"
                          onClick={() => dispatch(setProjectModalTab("status"))}
                        >
                          <Statistic
                            style={{ textAlign: "center" }}
                            valueStyle={{
                              fontSize: 14,
                              paddingTop: size.s2,
                              fontWeight: "bold",
                              color: renderStatus(t, project.status).color
                            }}
                            title={t("ProjectStatus")}
                            value={renderStatus(t, project.status).string}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </Space>
                </div>
              </ProjectCard>
            </React.Fragment>
          );
        })}

      {projects?.length === 0 && (
        <Row
          justify="center"
          align="middle"
          style={{
            width: "100%",
            // todo: is there a better way to vertically center this card?
            height: "calc(100vh - (250px))"
          }}
        >
          <LiftedCard
            bordered={false}
            style={{
              background: "#f8f9fb",
              width: 450,
              borderRadius: size.m1
            }}
            styles={{
              header: {
                backgroundColor: "white",
                borderTopLeftRadius: size.m1,
                borderTopRightRadius: size.m1
              }
            }}
            title={
              <Title level={4} style={{ marginBlock: 0, textAlign: "center" }}>
                {t("WelcomeToTheProjectsPage")}
              </Title>
            }
          >
            <Row align="middle" justify="center" style={{ height: "100%" }}>
              <Row>
                <SmallTitle type="secondary" style={{ textAlign: "center" }}>
                  {t("ThereAreCurrentlyNoProjectsToShow")}
                </SmallTitle>
              </Row>
              <Row>
                <SmallTitle style={{ textAlign: "center" }}>
                  {t("YouCanManageYourInvitesByPressingThe")}
                  <Text
                    style={{
                      whiteSpace: "nowrap",
                      fontWeight: "normal",
                      fontSize: "14px"
                    }}
                  >
                    {" "}
                    <AppstoreAddOutlined /> {t("ProjectInvites")}{" "}
                  </Text>
                  {t("buttonAbove")}
                </SmallTitle>
              </Row>
            </Row>
          </LiftedCard>
        </Row>
      )}
    </>
  );
};

export default ProjectsGrid;

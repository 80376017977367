//===== Data Types
export const DT_Timestamp = 0x01;
export const DT_Acc = 0x02;
export const DT_Temp = 0x03;
export const DT_Rh = 0x04;
export const DT_Angle = 0x05;
export const DT_Pressure = 0x06;
export const DT_Light_Sensor = 0x07;
export const DT_GPS_Pos = 0x08;
export const DT_External_Sensor = 0x09;
export const DT_External_Inputs = 0x0a;
export const DT_External_Outputs = 0x0b;
export const DT_DVA = 0x10;
export const DT_DVA_Start = 0x1a;
export const DT_DVA_End = 0x1b;
export const DT_Card_Reader = 0x11;
export const DT_Text = 0x12;
export const DT_Error_Msg = 0x13;
export const DT_LTE_Status = 0x14;
export const DT_GPS_Status = 0x15;
export const DT_Running_Status = 0x16;
export const DT_Device_Healh = 0x17; // Memory used, Main battery voltage, Backup battery voltage
export const DT_Schedule_Idx = 0x38; // Index to use for Schedule Channel Id, saves to flash when moving to next range
export const DT_Debug_Text = 0x3f; // Debug text (1-253 chars) + terminating 0x00. extra 0x00 if needed to make even bytes long

/** Extra value, type via EVI_xxx, that needs a time stamp for example time
 * stamp for max AngleX */
export const DT_Extra_Value = 0x18;

// Current Alarm Status. Saved when Alarm Status is changed.
export const DT_AlarmStatus = 0x19;

//Message from External Sensor to show to user.
export const DT_ExtSensMsg = 0x1c;

// DT_DataLink follows DT_GPS_Pos and links to its triggering data
export const DT_DataLink = 0x30;
// DT_StatusLink follows DT_GPS_Status or DT_LTE_Status and links to its triggering data
export const DT_StatusLink = 0x31;

//===== Extra Value Type Id, to be used by DT_Extra_Value
export const EVI_ANGLE_X = 0x01;
export const EVI_ANGLE_Y = 0x02;
export const EVI_ANGLE_Z = 0x03;
export const EVI_TEMP = 0x04;
export const EVI_RH = 0x05;
export const EVI_LIGHT = 0x06;
export const EVI_PRESSURE = 0x07;
export const EVI_BATTERY_DATA = 0x20;

// Running Status bits
// Bit 0 = Check Limit pressed
// Bit 1 = Reserved1
// Bit 2 = Reserved2
// Bit 3 = Tampering detected
// Bit 8 = Recording started
// Bit 9 = Recording ended, see bits 10-12
// Bit 10-12:
// 000 Normal end, record period over
// 001 Memory full before end of record period
// 010 Error writing data to flash

export const RS_BUTTON = 0x01;
export const RS_TAMPERING = 0x08;
export const RS_REC_START = 0x100;
export const RS_REC_END = 0x200;
export const RS_REC_END_MASK = 0x1c00; // Masks out bit 10-12
export const RS_REC_END_CODE_NORMAL = 0;
export const RS_REC_END_CODE_MEMORY_FULL = 1;
export const RS_REC_END_CODE_WRITE_ERROR = 2;

// External sensor types:
export const ST_ACC1 = 0x00; // External Acc, 0-16G
export const ST_ACC2 = 0x01; // External Acc, 0-200G
export const ST_ACC3 = 0x02; // Reserved
export const ST_TEMP1 = 0x03; // External temp -40 - +80
export const ST_TEMP2 = 0x04; // External temp xxx – xxx
export const ST_TEMP3 = 0x05; // External temp xxx – xxx
export const ST_RH1 = 0x06; // External Rh 0-100 % (same as internal sensor)
export const ST_RH2 = 0x07; // External Rh 0-100% (reserved)
export const ST_RH3 = 0x08; // External Rh 0-100% (reserved)
export const ST_LIGHT1 = 0x09; // External Lightsensor (same as internal)
export const ST_LIGHT2 = 0x0a; // External Lightsensor (reserved)
export const ST_PRESSURE1 = 0x0b; // External Pressure sensor (same as internal)
export const ST_PRESSURE2 = 0x0c; // External Pressure sensor (reserved)
export const ST_VIBRATION1 = 0x0d; // External Vibration sensor 10-50Hz
export const ST_VIBRATION2 = 0x0e; // External Vibration sensor 5-100Hz
export const ST_TIMER1 = 0x20; // Timer ms, trigged by External IN1

// External sensor type IDs:
export const RHTemp1S = 0x1011; // SHT31 (0x45), Single Identic to RHTemp1M
export const RHTemp1M = 0x1111; // SHT31 (0x45), Multi
export const RHTemp2M = 0x1112; // SHT31 (0x45), Multi
export const RHTemp3M = 0x1113; // SHT31 (0x45), Multi
export const RHTemp4M = 0x1114; // SHT31 (0x45), Multi
export const RHTemp5M = 0x1115; // SHT31 (0x45), Multi
export const RHTemp6M = 0x1116; // SHT31 (0x45), Multi
export const RHTemp7M = 0x1117; // SHT31 (0x45), Multi
export const RHTemp8M = 0x1118; // SHT31 (0x45), Multi
export const RhTemp = [
  RHTemp1S,
  RHTemp1M,
  RHTemp2M,
  RHTemp3M,
  RHTemp4M,
  RHTemp5M,
  RHTemp6M,
  RHTemp7M,
  RHTemp8M
];

// External sensor Device Feature bits as stored in SystemInfo
const DF_RHTemp1S = 0x00000001;
const DF_RHTemp1M = 0x00000100;
const DF_RHTemp2M = 0x00000200;
const DF_RHTemp3M = 0x00000400;
const DF_RHTemp4M = 0x00000800;
const DF_RHTemp5M = 0x00001000;
const DF_RHTemp6M = 0x00002000;
const DF_RHTemp7M = 0x00004000;
const DF_RHTemp8M = 0x00008000;

// Lookup for External Sensor Type IDs
export const typeIdToFeature: Record<number, number> = {
  [RHTemp1S]: DF_RHTemp1S,
  [RHTemp1M]: DF_RHTemp1M,
  [RHTemp2M]: DF_RHTemp2M,
  [RHTemp3M]: DF_RHTemp3M,
  [RHTemp4M]: DF_RHTemp4M,
  [RHTemp5M]: DF_RHTemp5M,
  [RHTemp6M]: DF_RHTemp6M,
  [RHTemp7M]: DF_RHTemp7M,
  [RHTemp8M]: DF_RHTemp8M
};

export const Timer1X = 0xe000; // Internal timer started/stopped by ExtIO

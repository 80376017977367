import React from "react";
import { Collapse, List, Typography, Tooltip, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { ellipsisIfLongTextStyle } from "../Common/CommonFonts";
import { useDispatch } from "react-redux";
import { NormalButtonSmall } from "../Common/CommonButtons";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { size } from "../../helpers/pageHelper";
import { License } from "../MicroComponents/LicenseAccess";
import {
  openDeleteParXTemplateModal,
  openEditParXTemplateModal
} from "../../state/modalsSlice";
import { ParXTemplate } from "../../state/cargologRestApi";
import {
  setSelectedDeleteParXTemplate,
  setSelectedEditParXTemplate,
  setSelectedParXTemplate
} from "../../state/paramsPageSlice";
import dayjs from "dayjs";

interface IProps {
  data?: ParXTemplate[];
  loading: boolean;
}
const ParamsTemplates: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Collapse expandIconPosition="end" ghost defaultActiveKey="templateFiles">
      <Collapse.Panel
        header={t("TemplateFiles")}
        key="templateFiles"
        className="custom"
      >
        <List
          size="small"
          split={false}
          locale={{ emptyText: t("NoData") }}
          dataSource={props.data}
          loading={props.loading}
          renderItem={(item) => (
            <List.Item
              key={item.name}
              style={{ paddingTop: 0, paddingRight: 0, paddingLeft: 0 }}
            >
              <Row
                justify="space-between"
                align="middle"
                style={{ width: "100%" }}
                wrap={false}
              >
                <Col>
                  <Tooltip
                    placement="right"
                    title={() => (
                      <p style={{ marginBlock: 0 }}>
                        {item.description !== "" && (
                          <div>
                            {item.description}
                            <br />
                            <br />
                          </div>
                        )}
                        {t("Created")}:{" "}
                        {dayjs(item.created).format("YYYY-MM-DD, HH:mm")}
                        <br />
                        {t("CreatedBy")}: {item.createdByUser.firstName}{" "}
                        {item.createdByUser.lastName}
                      </p>
                    )}
                  >
                    <Typography.Link
                      onClick={() => dispatch(setSelectedParXTemplate(item))}
                      className="selectableText"
                      style={{
                        ...ellipsisIfLongTextStyle,
                        color: "black"
                      }}
                    >
                      {item.name}
                    </Typography.Link>
                  </Tooltip>
                </Col>

                <License right="ADB">
                  <Col>
                    <NormalButtonSmall
                      icon={
                        <EditOutlined title={t("EditNameAndDescription")} />
                      }
                      onClick={() => {
                        dispatch(setSelectedEditParXTemplate(item));
                        dispatch(openEditParXTemplateModal());
                      }}
                    />

                    <NormalButtonSmall
                      icon={<CloseOutlined title={t("closeFileTip")} />}
                      onClick={() => {
                        dispatch(setSelectedDeleteParXTemplate(item));
                        dispatch(openDeleteParXTemplateModal());
                      }}
                      style={{ marginLeft: size.s1 }}
                    />
                  </Col>
                </License>
              </Row>
            </List.Item>
          )}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default ParamsTemplates;

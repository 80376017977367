import { Buffer } from "buffer";
import { isNil } from "lodash-es";
import {
  DTExternalIO,
  DTExternalSensor,
  DTExternalTimer,
  DTStatusLink,
  DtDataLink,
  DtExtraValueReponse,
  IParsedDtBlock,
  RunningStatusTypes
} from "../../models/DTTypes";
import {
  DT_Acc,
  DT_AlarmStatus,
  DT_Angle,
  DT_DataLink,
  DT_Debug_Text,
  DT_Device_Healh,
  DT_Error_Msg,
  DT_External_Inputs,
  DT_External_Outputs,
  DT_External_Sensor,
  DT_ExtSensMsg,
  DT_Extra_Value,
  DT_GPS_Pos,
  DT_GPS_Status,
  DT_LTE_Status,
  DT_Pressure,
  DT_Rh,
  DT_Running_Status,
  DT_StatusLink,
  DT_Temp,
  DT_Text,
  DT_Timestamp,
  EVI_ANGLE_X,
  EVI_ANGLE_Y,
  EVI_ANGLE_Z,
  EVI_LIGHT,
  EVI_PRESSURE,
  EVI_RH,
  EVI_TEMP,
  EVI_BATTERY_DATA,
  RhTemp,
  RS_BUTTON,
  RS_REC_END,
  RS_REC_END_CODE_MEMORY_FULL,
  RS_REC_END_CODE_NORMAL,
  RS_REC_END_CODE_WRITE_ERROR,
  RS_REC_END_MASK,
  RS_REC_START,
  RS_TAMPERING,
  ST_TIMER1
} from "../../models/FAT100DataTypes";
import { array2IDate, buf2IDate, iDate2Unix } from "../dataModelHelper";
import { DeviceHealth, RecordingDataBlock } from "../datasetHelper";
import { DatxVersion } from "./parseDatxHeaderHelper";

/**
 * Checks dt_running status for button presses. If no button press is detected,
 * undefined is returned
 * @param msg
 * @returns "btnPress" as a string. In the future there will be more return options
 */
const parseDtRunningStatus = (
  buf: Buffer
): RunningStatusTypes[] | undefined => {
  if (buf.length !== 2) {
    throw new Error("wrong length dt running status");
  }

  const data = buf.readUInt16LE(0);
  const status: RunningStatusTypes[] = [];

  if ((data & RS_BUTTON) > 0) {
    status.push("btnPress");
  }
  if ((data & RS_TAMPERING) > 0) {
    status.push("tampering");
  }
  if ((data & RS_REC_START) > 0) {
    status.push("recStart");
  }
  if ((data & RS_REC_END) > 0) {
    // Mask out end code and switch bits
    const recEndCode = (data & RS_REC_END_MASK) >> 8;
    switch (recEndCode) {
      case RS_REC_END_CODE_NORMAL:
        status.push("recEndNormal");
        break;
      case RS_REC_END_CODE_MEMORY_FULL:
        status.push("recEndMemoryFull");
        break;
      case RS_REC_END_CODE_WRITE_ERROR:
        status.push("recEndWriteError");
        break;
      default:
        status.push("recEndNormal");
        break;
    }
  }
  return status;
};

export const parseDtAcc = (
  buf: Buffer
): {
  xAcc: [number, number];
  yAcc: [number, number];
  zAcc: [number, number];
} => {
  if (buf.length !== 12) {
    throw new Error(
      `wrong length for acc data, expected 12 but got: ": ${buf.length}. \nEntire message: ${JSON.stringify(buf)}`
    );
  }

  const xAcc: [number, number] = [
    buf.readInt16LE(0) / 100,
    buf.readUInt16LE(2)
  ];

  const yAcc: [number, number] = [
    buf.readInt16LE(4) / 100,
    buf.readUInt16LE(6)
  ];

  const zAcc: [number, number] = [
    buf.readInt16LE(8) / 100,
    buf.readUInt16LE(10)
  ];

  return { xAcc, yAcc, zAcc };
};

export const parseDtTemp = (buf: Buffer) => {
  if (buf.length !== 2) {
    throw new Error(`Wrong dt-temp length. Expected 2 but got: ${buf.length}`);
  }
  const value = buf.readInt16LE(0) / 100;
  return value;
};

export const parseDtRh = (buf: Buffer) => {
  if (buf.length !== 2) {
    throw new Error(`Wrong dt-rh length. Expected 2 but got: ${buf.length}`);
  }
  const value = buf.readInt16LE(0) / 100;
  return value;
};

export const parseDtAngle = (buf: Buffer): [number, number, number] => {
  if (buf.length !== 6) throw new Error("wrong data length for angle");

  const xAngle = buf.readInt16LE(0) / 10;
  const yAngle = buf.readInt16LE(2) / 10;
  const zAngle = buf.readInt16LE(4) / 10;

  return [xAngle, yAngle, zAngle];
};

export const parseDtPressure = (buf: Buffer) => {
  if (buf.length !== 4) {
    throw new Error(
      `Wrong dt-pressure length. Expected 4 but got: ${buf.length}`
    );
  }
  const valueRaw = buf.readInt16LE(0);
  const valueCompensated = buf.readInt16LE(2);
  return [valueRaw, valueCompensated];
};

export const parseDtGpsPos = (buf: Buffer, datxVersion: DatxVersion) => {
  if (
    (datxVersion >= 8 && buf.length !== 24) ||
    (datxVersion < 8 && buf.length !== 20)
  ) {
    const expectedLength = datxVersion >= 8 ? 24 : 20;
    throw new Error(
      `Wrong dt-gps-pos length. Expected ${expectedLength} but got: ${buf.length}`
    );
  }

  let bufferPos = 0;

  const longitude = buf.readInt32LE(bufferPos);
  bufferPos += 4;

  const latitude = buf.readInt32LE(bufferPos);
  bufferPos += 4;

  const velocity = buf.readInt32LE(bufferPos); // Unit: cm/s
  bufferPos += 4;

  const rawtime: number[] = [];
  for (let i = 0; i < 6; i++) {
    rawtime.push(buf.readUInt8(bufferPos));
    bufferPos += 1;
  }

  const timestampDate = array2IDate(rawtime);
  const gpsTimestamp = iDate2Unix(timestampDate);

  const gpsStatus = buf.readUInt16LE(bufferPos);
  // Bit 0: 1 = Alarm pos
  // Bit 1: 1 = Periodic pos
  // Bit 2: 1 = Velocity active
  // Bit 3-15: reserved

  let accuracy: number | undefined = undefined;
  if (datxVersion >= 8) {
    bufferPos += 2;
    accuracy = buf.readUint32LE(bufferPos); // Unit: mm
  }

  return { latitude, longitude, velocity, gpsTimestamp, gpsStatus, accuracy };
};

const parseDtDeviceHealth = (buf: Buffer): DeviceHealth => {
  if (buf.length !== 6) {
    throw new Error(
      "DT_Device_Health wrong length. Expected 6 but got: " + buf.length
    );
  }

  const memoryUsed = buf.readUInt32LE(0);
  const mainBatteryStatus = buf.readUInt8(4);
  const backupBatteryStatus = buf.readUInt8(5);

  return { memoryUsed, mainBatteryStatus, backupBatteryStatus };
};

/**
 * Returns correct value (signed or unsigned) for extra value id.
 * @param extraValueId
 * @param value Buffer with length 4
 * @throws Error if extraValueId is not recognized.
 */
const parseDataForDtExtraValue = (extraValueId: number, value: Buffer) => {
  switch (extraValueId) {
    case EVI_ANGLE_X:
      return value.readInt32LE(0) / 10;
    case EVI_ANGLE_Y:
      return value.readInt32LE(0) / 10;
    case EVI_ANGLE_Z:
      return value.readInt32LE(0) / 10;
    case EVI_TEMP:
      return value.readInt32LE(0) / 100;
    case EVI_RH:
      return value.readUInt32LE(0) / 100;
    case EVI_LIGHT:
      return value.readUInt32LE(0);
    case EVI_PRESSURE:
      return value.readInt32LE(0);
    case EVI_BATTERY_DATA:
      return value.readUInt32LE(0);

    default:
      throw new Error("Unknown extraValueId: " + extraValueId);
  }
};

const parseDtExtraValue = (buf: Buffer): DtExtraValueReponse => {
  if (buf.length !== 12) {
    throw new Error(
      "DT_Extra_Value wrong length. Expected 12 but got: " + buf.length
    );
  }

  const extraValueId = buf.readUInt8(0);

  //next byte is reserved
  const timestamp = buf2IDate(buf.subarray(2, 8));

  const value = parseDataForDtExtraValue(extraValueId, buf.subarray(8, 12));

  return { extraValueId, timestamp, value };
};

const parseDtErrorMsg = (buf: Buffer) => {
  const errorAsString = String.fromCharCode(...buf);
  return { errorAsString };
};

const parseDtText = (buf: Buffer) => {
  const textAsString = String.fromCharCode(...buf);
  return { textAsString };
};

const parseDtDebugText = (buf: Buffer) => {
  const textAsString = String.fromCharCode(...buf);
  return { textAsString };
};

const parseDtTimestamp = (buf: Buffer) => {
  if (buf.length !== 6) {
    throw new Error(
      `Wrong dt-timestamp length. Expected 6 but got: ${buf.length}`
    );
  }
  const timestamp = buf2IDate(buf);
  return { timestamp };
};

const parseDtLteStatus = (buf: Buffer) => {
  if (buf.length !== 2) {
    throw new Error(
      `Wrong DT_LTE_Status length. Expected 2 but got: ${buf.length}`
    );
  }
  const lteStatus = buf.readUInt16LE(0);
  return { lteStatus };
};

const parseDtGpsStatus = (buf: Buffer) => {
  if (buf.length !== 2) {
    throw new Error(
      `Wrong DT_GPS_Status length. Expected 2 but got: ${buf.length}`
    );
  }
  const gpsStatus = buf.readUInt16LE(0);
  return { gpsStatus };
};

const parseDtAlarmStatus = (buf: Buffer) => {
  if (buf.length !== 4) {
    throw new Error(
      `Wrong DT_AlarmStatus length. Expected 4 but got: ${buf.length}`
    );
  }
  const alarmStatus = buf.readUInt32LE(0);
  return { alarmStatus };
};

const parseDtDataLink = (buf: Buffer): DtDataLink => {
  if (buf.length !== 8) {
    throw new Error(
      `Wrong DT_DataLink length. Expected 6 but got: ${buf.length}`
    );
  }

  let bufferPos = 0;
  const alarmDt = buf.readUInt8(bufferPos);
  bufferPos += 1;
  const linkSource = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const rawtime: number[] = [];
  for (let i = 0; i < 6; i++) {
    rawtime.push(buf.readUInt8(bufferPos));
    bufferPos += 1;
  }

  const timestampDate = array2IDate(rawtime);
  const alarmTimestamp = iDate2Unix(timestampDate);

  return { alarmDt, linkSource, alarmTimestamp };
};

const parseDtStatusLink = (buf: Buffer): DTStatusLink => {
  let bufferPos = 0;
  const alarmDt = buf.readUInt8(bufferPos);
  bufferPos += 1;
  const linkSource = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const rawtime: number[] = [];
  for (let i = 0; i < 6; i++) {
    rawtime.push(buf.readUInt8(bufferPos));
    bufferPos += 1;
  }

  const timestampDate = array2IDate(rawtime);
  const alarmTimestamp = iDate2Unix(timestampDate);
  return { alarmDt, linkSource, alarmTimestamp };
};

const parseExternalSensorType = (buf: Buffer): string => {
  const bufferPos = 0;
  const sensorType = buf.readUInt8(bufferPos);
  switch (sensorType) {
    case ST_TIMER1:
      return "ST_TIMER1";
    default:
      console.log("Unknown sensor type: " + sensorType);
  }
  return "unknown";
};

const parseDtExternalSensor = (buf: Buffer): DTExternalSensor => {
  let bufferPos = 0;
  const sensorType = buf.readUInt8(bufferPos);
  bufferPos += 1;
  const sensorId = buf.readUInt8(bufferPos);
  bufferPos += 1;
  const data: number[] = [];
  while (bufferPos < buf.length) {
    data.push(buf.readUInt16LE(bufferPos));
    bufferPos += 2;
  }
  return { sensorType, sensorId, data };
};

const parseDtExternalTimer = (buf: Buffer): DTExternalTimer => {
  let bufferPos = 1;
  const sensorId = buf.readUInt8(bufferPos);
  bufferPos += 1;
  const data: number[] = [];
  while (bufferPos < buf.length) {
    data.push(buf.readUInt16LE(bufferPos));
    bufferPos += 2;
  }
  const timerTime = (data[1] << 8) | data[0];
  return { sensorId, timerTime };
};

const parseDtExternalRhTemp = (buf: Buffer) => {
  let bufferPos = 0;
  const sensorId = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  let sensorSerial: string = "";
  for (let i = 0; i < 16; i++) {
    const nextChar = buf.readUInt8(bufferPos);
    if (nextChar !== 0) {
      sensorSerial += String.fromCharCode(nextChar);
    }
    bufferPos += 1;
  }

  const rh = buf.readInt16LE(bufferPos) / 100;
  bufferPos += 2;
  const temp = buf.readInt16LE(bufferPos) / 100;
  bufferPos += 2;
  return { sensorId, sensorSerial, temp, rh };
};

const parseDtExternalIO = (buf: Buffer): DTExternalIO => {
  const rawChanged = buf.readUInt8(0);
  const rawState = buf.readUInt8(1);
  return {
    changed: [
      (rawChanged & 1) > 0,
      (rawChanged & 2) > 0,
      (rawChanged & 4) > 0,
      (rawChanged & 8) > 0,
      (rawChanged & 16) > 0,
      (rawChanged & 32) > 0,
      (rawChanged & 64) > 0,
      (rawChanged & 128) > 0
    ],
    state: [
      (rawState & 1) > 0,
      (rawState & 2) > 0,
      (rawState & 4) > 0,
      (rawState & 8) > 0,
      (rawState & 16) > 0,
      (rawState & 32) > 0,
      (rawState & 64) > 0,
      (rawState & 128) > 0
    ]
  };
};

const parseDtExtSensMsg = (buf: Buffer) => {
  const textAsString = String.fromCharCode(...buf);
  return { textAsString };
};

export const parseDtsToDataset = (
  dts: IParsedDtBlock,
  datxVersion: DatxVersion,
  errorReporter: Function
): RecordingDataBlock => {
  const timestamp = iDate2Unix(dts.timestamp);

  const allDts = dts.dts.reduce((resp, curr) => {
    try {
      switch (curr.id) {
        case DT_Acc: {
          const dtAccs = parseDtAcc(curr.data);

          // Note: I also include acceleration that is 0
          resp.xAcc = resp?.xAcc?.concat([dtAccs.xAcc]) ?? [dtAccs.xAcc];
          resp.yAcc = resp?.yAcc?.concat([dtAccs.yAcc]) ?? [dtAccs.yAcc];
          resp.zAcc = resp?.zAcc?.concat([dtAccs.zAcc]) ?? [dtAccs.zAcc];
          return resp;
        }

        case DT_Temp: {
          const parsedTemp = parseDtTemp(curr.data);

          /** Note: temp may allready have been set through dt_extra_value. In
           * that case, set the average of the 2 as the new value */
          const existingTemp = resp?.temp;
          resp.temp = existingTemp
            ? (parsedTemp + existingTemp) / 2
            : parsedTemp;
          break;
        }

        case DT_Rh: {
          const parsedRh = parseDtRh(curr.data);

          /** Note: rh may allready have been set through dt_extra_value. In
           * that case, set the average of the 2 as the new value */
          const existingRh = resp?.rh;
          resp.rh = existingRh ? (parsedRh + existingRh) / 2 : parsedRh;
          break;
        }

        case DT_Angle: {
          const parsedAngle = parseDtAngle(curr.data);
          resp.angle = resp?.angle?.concat([parsedAngle]) ?? [parsedAngle];
          break;
        }

        case DT_Pressure: {
          const parsedPressure = parseDtPressure(curr.data);
          resp.pressureRaw = parsedPressure[0];
          resp.pressureComp = parsedPressure[1];
          break;
        }

        case DT_Extra_Value: {
          const parsedExtraValue = parseDtExtraValue(curr.data);

          switch (parsedExtraValue.extraValueId) {
            case EVI_ANGLE_X: {
              const value: [number, number, number] = [
                parsedExtraValue.value,
                0,
                0
              ];

              resp.angle = resp?.angle?.concat([value]) ?? [value];
              break;
            }

            case EVI_ANGLE_Y: {
              const value: [number, number, number] = [
                0,
                parsedExtraValue.value,
                0
              ];

              resp.angle = resp?.angle?.concat([value]) ?? [value];
              break;
            }

            case EVI_ANGLE_Z: {
              const value: [number, number, number] = [
                0,
                0,
                parsedExtraValue.value
              ];

              resp.angle = resp?.angle?.concat([value]) ?? [value];
              break;
            }

            case EVI_TEMP: {
              /** Note: rh may allready have been set through dt_temp. In
               * that case, set the average of the 2 as the new value */
              const existingTemp = resp?.temp;
              resp.temp = existingTemp
                ? (parsedExtraValue.value + existingTemp) / 2
                : parsedExtraValue.value;

              break;
            }

            case EVI_RH: {
              /** Note: rh may allready have been set through dt_rh. In
               * that case, set the average of the 2 as the new value */
              const existingRh = resp?.rh;
              resp.rh = existingRh
                ? (parsedExtraValue.value + existingRh) / 2
                : parsedExtraValue.value;

              break;
            }

            case EVI_BATTERY_DATA: {
              resp.batteryData = parsedExtraValue.value;
              break;
            }

            default:
              break;
          }
          break;
        }

        case DT_GPS_Pos: {
          const parsedGpsPos = parseDtGpsPos(curr.data, datxVersion);
          resp.gps = parsedGpsPos;
          break;
        }

        case DT_Device_Healh: {
          const parsedDeviceHealth = parseDtDeviceHealth(curr.data);
          resp.deviceHealth = parsedDeviceHealth;
          break;
        }

        case DT_Running_Status: {
          const parsedRunningStatus = parseDtRunningStatus(curr.data);
          if (!isNil(parsedRunningStatus)) {
            resp.runningStatus = parsedRunningStatus;
          }
          break;
        }

        case DT_Timestamp: {
          const parsedTimestamp = parseDtTimestamp(curr.data);
          const asUnix = iDate2Unix(parsedTimestamp.timestamp);
          resp.timestamp2 = asUnix;
          break;
        }

        case DT_LTE_Status: {
          const parsedStatus = parseDtLteStatus(curr.data);
          resp.lteStatus = resp.lteStatus?.concat([parsedStatus.lteStatus]) ?? [
            parsedStatus.lteStatus
          ];
          break;
        }

        case DT_GPS_Status: {
          const parsedStatus = parseDtGpsStatus(curr.data);
          resp.gpsStatus = resp?.gpsStatus?.concat([
            parsedStatus.gpsStatus
          ]) ?? [parsedStatus.gpsStatus];
          break;
        }

        case DT_AlarmStatus: {
          const parsedStatus = parseDtAlarmStatus(curr.data);
          resp.alarmStatus = parsedStatus.alarmStatus;
          break;
        }

        case DT_DataLink: {
          const parsedDataLink = parseDtDataLink(curr.data);
          resp.dataLink = parsedDataLink;
          break;
        }

        case DT_StatusLink: {
          const parsedStatusLink = parseDtStatusLink(curr.data);
          resp.statusLink = parsedStatusLink;
          break;
        }

        case DT_Text: {
          const parsedText = parseDtText(curr.data);
          resp.text = parsedText.textAsString;
          break;
        }

        case DT_Debug_Text: {
          const parsedDebugText = parseDtDebugText(curr.data);
          console.debug("DT_Debug_Text:", parsedDebugText.textAsString);
          break;
        }

        case DT_Error_Msg: {
          const parsedError = parseDtErrorMsg(curr.data);
          resp.errorString = parsedError.errorAsString;
          break;
        }

        case DT_External_Sensor: {
          if (datxVersion < 7) {
            const sensortype = parseExternalSensorType(curr.data);
            switch (sensortype) {
              case "ST_TIMER1": {
                const parsedExternalTimer = parseDtExternalTimer(curr.data);
                resp.externalTimers = parsedExternalTimer;
                break;
              }
              default: {
                const parsedExternalSensor = parseDtExternalSensor(curr.data);
                resp.externalSensor = parsedExternalSensor;
              }
            }
          } else {
            // Find out what type of sensor it is
            const sensorTypeId = curr.data.readUInt16LE(0);
            if (RhTemp.includes(sensorTypeId)) {
              const parsed = parseDtExternalRhTemp(curr.data);
              resp.externalTemp = {
                sensorId: parsed.sensorId,
                sensorSerial: parsed.sensorSerial,
                temp: parsed.temp
              };
              resp.externalRh = {
                sensorId: parsed.sensorId,
                sensorSerial: parsed.sensorSerial,
                rh: parsed.rh
              };
            }
          }
          break;
        }

        case DT_External_Inputs: {
          const parsedExternalInputs = parseDtExternalIO(curr.data);
          resp.externalInputs = {
            changed: [
              resp.externalInputs?.changed[0]
                ? resp.externalInputs?.changed[0]
                : parsedExternalInputs.changed[0],
              resp.externalInputs?.changed[1]
                ? resp.externalInputs?.changed[1]
                : parsedExternalInputs.changed[1],
              resp.externalInputs?.changed[2]
                ? resp.externalInputs?.changed[2]
                : parsedExternalInputs.changed[2],
              resp.externalInputs?.changed[3]
                ? resp.externalInputs?.changed[3]
                : parsedExternalInputs.changed[3],
              resp.externalInputs?.changed[4]
                ? resp.externalInputs?.changed[4]
                : parsedExternalInputs.changed[4],
              resp.externalInputs?.changed[5]
                ? resp.externalInputs?.changed[5]
                : parsedExternalInputs.changed[5],
              resp.externalInputs?.changed[6]
                ? resp.externalInputs?.changed[6]
                : parsedExternalInputs.changed[6],
              resp.externalInputs?.changed[7]
                ? resp.externalInputs?.changed[7]
                : parsedExternalInputs.changed[7]
            ],
            state: [
              resp.externalInputs?.changed[0]
                ? resp.externalInputs.state[0]
                : parsedExternalInputs.state[0],
              resp.externalInputs?.changed[1]
                ? resp.externalInputs.state[1]
                : parsedExternalInputs.state[1],
              resp.externalInputs?.changed[2]
                ? resp.externalInputs.state[2]
                : parsedExternalInputs.state[2],
              resp.externalInputs?.changed[3]
                ? resp.externalInputs.state[3]
                : parsedExternalInputs.state[3],
              resp.externalInputs?.changed[4]
                ? resp.externalInputs.state[4]
                : parsedExternalInputs.state[4],
              resp.externalInputs?.changed[5]
                ? resp.externalInputs.state[5]
                : parsedExternalInputs.state[5],
              resp.externalInputs?.changed[6]
                ? resp.externalInputs.state[6]
                : parsedExternalInputs.state[6],
              resp.externalInputs?.changed[7]
                ? resp.externalInputs.state[7]
                : parsedExternalInputs.state[7]
            ]
          };
          break;
        }

        case DT_External_Outputs: {
          const parsedExternalOutputs = parseDtExternalIO(curr.data);
          resp.externalOutputs = {
            changed: [
              resp.externalOutputs?.changed[0]
                ? resp.externalOutputs?.changed[0]
                : parsedExternalOutputs.changed[0],
              resp.externalOutputs?.changed[1]
                ? resp.externalOutputs?.changed[1]
                : parsedExternalOutputs.changed[1],
              resp.externalOutputs?.changed[2]
                ? resp.externalOutputs?.changed[2]
                : parsedExternalOutputs.changed[2],
              resp.externalOutputs?.changed[3]
                ? resp.externalOutputs?.changed[3]
                : parsedExternalOutputs.changed[3],
              resp.externalOutputs?.changed[4]
                ? resp.externalOutputs?.changed[4]
                : parsedExternalOutputs.changed[4],
              resp.externalOutputs?.changed[5]
                ? resp.externalOutputs?.changed[5]
                : parsedExternalOutputs.changed[5],
              resp.externalOutputs?.changed[6]
                ? resp.externalOutputs?.changed[6]
                : parsedExternalOutputs.changed[6],
              resp.externalOutputs?.changed[7]
                ? resp.externalOutputs?.changed[7]
                : parsedExternalOutputs.changed[7]
            ],
            state: [
              resp.externalOutputs?.changed[0]
                ? resp.externalOutputs.state[0]
                : parsedExternalOutputs.state[0],
              resp.externalOutputs?.changed[1]
                ? resp.externalOutputs.state[1]
                : parsedExternalOutputs.state[1],
              resp.externalOutputs?.changed[2]
                ? resp.externalOutputs.state[2]
                : parsedExternalOutputs.state[2],
              resp.externalOutputs?.changed[3]
                ? resp.externalOutputs.state[3]
                : parsedExternalOutputs.state[3],
              resp.externalOutputs?.changed[4]
                ? resp.externalOutputs.state[4]
                : parsedExternalOutputs.state[4],
              resp.externalOutputs?.changed[5]
                ? resp.externalOutputs.state[5]
                : parsedExternalOutputs.state[5],
              resp.externalOutputs?.changed[6]
                ? resp.externalOutputs.state[6]
                : parsedExternalOutputs.state[6],
              resp.externalOutputs?.changed[7]
                ? resp.externalOutputs.state[7]
                : parsedExternalOutputs.state[7]
            ]
          };
          break;
        }

        case DT_ExtSensMsg: {
          const parsedExtSensMsg = parseDtExtSensMsg(curr.data);
          resp.extSensMsg = parsedExtSensMsg.textAsString;
          break;
        }

        default: {
          console.log(`Found unknown dt_type. ID: 0x${curr.id.toString(16)}`);
          break;
        }
      }
    } catch (e) {
      errorReporter(`Error for type ${curr.id}: ${e}`);
    }

    return resp;
  }, {} as RecordingDataBlock);

  return { ...allDts, timestamp };
};

/** Breaks down buffer into DT blocks */
export const parseDtMessages = (buf: Buffer): IParsedDtBlock => {
  let bufPos = 0;

  //KIND_RECORDINGDATA will always begin with a timestamp
  const timestamp = buf2IDate(buf.subarray(bufPos, bufPos + 6));
  bufPos += 6;

  const dts: { id: number; data: Buffer }[] = [];

  while (bufPos < buf.length) {
    const id = buf.readUInt8(bufPos);
    bufPos += 1;
    const len = buf.readUInt8(bufPos);
    bufPos += 1;
    const data = buf.subarray(bufPos, bufPos + len);
    bufPos += len;

    dts.push({ id, data });
  }

  return { timestamp, dts };
};

// helper that converts between different data models

import { Buffer } from "buffer";
import { TFunction } from "i18next";
import { cloneDeep, isEmpty, isNil, isUndefined } from "lodash-es";
import dayjs, { Dayjs } from "dayjs";
import { VMGeneralRecordingInformationCard } from "../components/MicroComponents/GeneralRecordingInformation";
import {
  REC_START_DATE,
  REC_START_DOWNLOAD,
  REC_START_ON_BUTTON,
  REC_STOP_DATE,
  REC_STOP_DURATION,
  SSI_Acc,
  SSI_Angle,
  SSI_Bluetooth,
  SSI_Dev_Health,
  SSI_Ext_Inputs,
  SSI_Ext_Outputs,
  SSI_GPS,
  SSI_LTE,
  SSI_Light,
  SSI_Not_Active,
  SSI_Pressure,
  SSI_RH,
  SSI_Dev_Status,
  SSI_Temp,
  ValidRecStartMode,
  alarmInputBitsBitMasks,
  alarmOutputBitsBitMasks,
  channelBitMasks,
  gpsStatusBitMasks,
  onChangeBits,
  SSI_Ext_Sensor1,
  SSI_Ext_Sensor2,
  SSI_Ext_Sensor3,
  SSI_Ext_Sensor4,
  SSI_Ext_Sensor5,
  SSI_Ext_Sensor6,
  SSI_Ext_Sensor7,
  SSI_Ext_Sensor8,
  External_Sensors_SSI
} from "../constants/FAT100-prot-constants";
import {
  angleAutoHorizonKey,
  defaultChannelBits,
  defaultExternalInputParams,
  defaultExternalOutputParams,
  defaultLteParams,
  defaultStopAfterDuration,
  disabledAccParams,
  disabledAlarmInputBits,
  disabledAlarmOutputBits,
  disabledAngleParams,
  disabledPressureParams,
  disabledRhParams,
  disabledTempParams
} from "../constants/params-defaults";
import {
  SCTRL_ALWAYSON,
  SCTRL_EVENTTRIGGED,
  SCTRL_FLIGHTMODE,
  SCTRL_GPSREQUIRED,
  SCTRL_INACTIVE,
  SCTRL_PERODIC,
  SCTRL_RECORD
} from "../models/FAT100ControlBits";
import {
  EVI_ANGLE_X,
  EVI_ANGLE_Y,
  EVI_ANGLE_Z,
  EVI_BATTERY_DATA,
  EVI_LIGHT,
  EVI_PRESSURE,
  EVI_RH,
  EVI_TEMP
} from "../models/FAT100DataTypes";
import { IAccFilterValues } from "../models/IAccFilterValues";
import IDate from "../models/IDate";
import { ISRD, IScheduler } from "../models/IScheduler";
import { GeneralSystemInfo } from "../models/ISystemInfo";
import IAccParams from "../models/RecordingParameters/IAccParams";
import IAlarmInputBits from "../models/RecordingParameters/IAlarmInputBits";
import IAlarmOutputBits from "../models/RecordingParameters/IAlarmOutputBits";
import IAngleParams from "../models/RecordingParameters/IAngleParams";
import IChannelBits, {
  IChannelBitTypes
} from "../models/RecordingParameters/IChannelBits";
import IExternalInputsParams from "../models/RecordingParameters/IExternalInputsParams";
import IExternalOutputsParams from "../models/RecordingParameters/IExternalOutputsParams";
import ILTEParams from "../models/RecordingParameters/ILTEParams";
import IRecParams from "../models/RecordingParameters/IRecParams";
import { IRecordingParameters } from "../models/RecordingParameters/IRecordingParameters";
import IRhParams from "../models/RecordingParameters/IRhParams";
import ITempParams from "../models/RecordingParameters/ITempParams";
import { VMDeviceFeatures } from "../models/VMDeviceFeatures";
import { VMAccParams } from "../models/ViewModelRecordingParameters/VMAccParams";
import VMAngleParams from "../models/ViewModelRecordingParameters/VMAngleParams";
import { VMExternalOutputParams } from "../models/ViewModelRecordingParameters/VMExternalOutputParams";
import { VMExternalInputParams } from "../models/ViewModelRecordingParameters/VMExtternalInputParams";
import {
  VMGpsScheduleParams,
  disabledGpsParams,
  emptyGpsParams
} from "../models/ViewModelRecordingParameters/VMGpsParams";
import {
  VMLteParams,
  VMLteSchdeuleParams,
  disabledLteParams,
  emptyLteParams
} from "../models/ViewModelRecordingParameters/VMLteParams";
import {
  RecEndType,
  RecStartType,
  VMRecParams
} from "../models/ViewModelRecordingParameters/VMRecParams";
import {
  ScheduleBlock,
  VMRecordingParameters
} from "../models/ViewModelRecordingParameters/VMRecordingParameters";
import { VMRhParams } from "../models/ViewModelRecordingParameters/VMRhParams";
import { VMTempParams } from "../models/ViewModelRecordingParameters/VMTempParams";
import { Optional } from "../utils/utilTypes";
import { IDtDva, IDvaData } from "./parsers/parseDvaHelper";
import {
  getExternalSensor,
  getNumberOfIO,
  interpretSystemInfoHasFeatures
} from "./parsers/parseSystemInfoHelper";
import { AppVersion } from "./versionHelper";
import IPressureParams from "../models/RecordingParameters/IPressureParams";
import { VMPressureParams } from "../models/ViewModelRecordingParameters/VMPressureParams";
import { DictionaryTransKeys } from "../lib/i18n";
import { VMExternalSensorParams } from "../models/ViewModelRecordingParameters/VMExternalSensorParams";
import IExternalSensorParams from "../models/RecordingParameters/IExternalSensorParams";
import { gpsAccuracyColors } from "../constants/colors";

/** All available type of sensors by name */
type SensorIdName =
  | "Acc"
  | "Temp"
  | "Rh"
  | "Angle"
  | "Pressure"
  | "Light"
  | "Gps"
  | "Lte"
  | "Bluetooth"
  | "DevStatus"
  | "DevHealth"
  | "ExtInputs"
  | "ExtOutputs"
  | "ExtSensor1"
  | "ExtSensor2"
  | "ExtSensor3"
  | "ExtSensor4"
  | "ExtSensor5"
  | "ExtSensor6"
  | "ExtSensor7"
  | "ExtSensor8";

/** All available type of sensors by id */
type SensorId =
  | typeof SSI_Acc
  | typeof SSI_Temp
  | typeof SSI_RH
  | typeof SSI_Angle
  | typeof SSI_Pressure
  | typeof SSI_Light
  | typeof SSI_GPS
  | typeof SSI_LTE
  | typeof SSI_Bluetooth
  | typeof SSI_Dev_Status
  | typeof SSI_Dev_Health
  | typeof SSI_Ext_Inputs
  | typeof SSI_Ext_Outputs
  | typeof SSI_Ext_Sensor1
  | typeof SSI_Ext_Sensor2
  | typeof SSI_Ext_Sensor3
  | typeof SSI_Ext_Sensor4
  | typeof SSI_Ext_Sensor5
  | typeof SSI_Ext_Sensor6
  | typeof SSI_Ext_Sensor7
  | typeof SSI_Ext_Sensor8
  | typeof SSI_Not_Active;

/** Get sensor-id given its name */
const sensorIdLookup: Record<SensorIdName, SensorId> = {
  Acc: SSI_Acc,
  Temp: SSI_Temp,
  Rh: SSI_RH,
  Angle: SSI_Angle,
  Pressure: SSI_Pressure,
  Light: SSI_Light,
  Gps: SSI_GPS,
  Lte: SSI_LTE,
  Bluetooth: SSI_Bluetooth,
  DevStatus: SSI_Dev_Status,
  DevHealth: SSI_Dev_Health,
  ExtInputs: SSI_Ext_Inputs,
  ExtOutputs: SSI_Ext_Outputs,
  ExtSensor1: SSI_Ext_Sensor1,
  ExtSensor2: SSI_Ext_Sensor2,
  ExtSensor3: SSI_Ext_Sensor3,
  ExtSensor4: SSI_Ext_Sensor4,
  ExtSensor5: SSI_Ext_Sensor5,
  ExtSensor6: SSI_Ext_Sensor6,
  ExtSensor7: SSI_Ext_Sensor7,
  ExtSensor8: SSI_Ext_Sensor8
};

/** Converts IChannelBits to number */
const packChannelBits = (channelBits: IChannelBits): number => {
  let packed = 0;
  packed |= channelBits.Temp ? channelBitMasks.Temp : 0;
  packed |= channelBits.Rh ? channelBitMasks.Rh : 0;
  packed |= channelBits.Angle ? channelBitMasks.Angle : 0;
  packed |= channelBits.Pressure ? channelBitMasks.Pressure : 0;
  packed |= channelBits.Light ? channelBitMasks.Light : 0;
  packed |= channelBits.GPS ? channelBitMasks.GPS : 0;
  packed |= channelBits.LTE ? channelBitMasks.LTE : 0;
  packed |= channelBits.Bluetooth ? channelBitMasks.Bluetooth : 0;
  packed |= channelBits.DeviceHealth ? channelBitMasks.DeviceHealth : 0;
  return packed;
};

/** Converts IAlarmOutputBits to number */
const packAlarmInputBits = (alarmOutputBits: IAlarmInputBits): number => {
  const mask = alarmInputBitsBitMasks;
  let packed = 0;
  packed |= alarmOutputBits.input0 ? mask.input0used : 0;
  packed |= alarmOutputBits.input1 ? mask.input1used : 0;
  packed |= alarmOutputBits.input2 ? mask.input2used : 0;
  packed |= alarmOutputBits.input3 ? mask.input3used : 0;
  packed |= alarmOutputBits.input4 ? mask.input4used : 0;
  packed |= alarmOutputBits.input5 ? mask.input5used : 0;
  packed |= alarmOutputBits.input6 ? mask.input6used : 0;
  packed |= alarmOutputBits.input7 ? mask.input7used : 0;
  return packed;
};

/** Converts IAlarmOutputBits to number */
const packAlarmOutputBits = (alarmOutputBits: IAlarmOutputBits): number => {
  const mask = alarmOutputBitsBitMasks;
  let packed = 0;
  packed |= alarmOutputBits.output0.used ? mask.output0used : 0;
  packed |= alarmOutputBits.output1.used ? mask.output1used : 0;
  packed |= alarmOutputBits.output2.used ? mask.output2used : 0;
  packed |= alarmOutputBits.output3.used ? mask.output3used : 0;
  packed |= alarmOutputBits.output4.used ? mask.output4used : 0;
  packed |= alarmOutputBits.output5.used ? mask.output5used : 0;
  packed |= alarmOutputBits.output6.used ? mask.output6used : 0;
  packed |= alarmOutputBits.output7.used ? mask.output7used : 0;
  packed |= alarmOutputBits.output0.newStateOn ? mask.output0newState : 0;
  packed |= alarmOutputBits.output1.newStateOn ? mask.output1newState : 0;
  packed |= alarmOutputBits.output2.newStateOn ? mask.output2newState : 0;
  packed |= alarmOutputBits.output3.newStateOn ? mask.output3newState : 0;
  packed |= alarmOutputBits.output4.newStateOn ? mask.output4newState : 0;
  packed |= alarmOutputBits.output5.newStateOn ? mask.output5newState : 0;
  packed |= alarmOutputBits.output6.newStateOn ? mask.output6newState : 0;
  packed |= alarmOutputBits.output7.newStateOn ? mask.output7newState : 0;
  return packed;
};

/** Parses AlsoRecord number and returns IChannelBits object */
const parseChannelBits = (AlsoRecord: number): IChannelBits => {
  const channelBits: IChannelBits = {
    Temp: (AlsoRecord & channelBitMasks.Temp) > 0,
    Rh: (AlsoRecord & channelBitMasks.Rh) > 0,
    Angle: (AlsoRecord & channelBitMasks.Angle) > 0,
    Pressure: (AlsoRecord & channelBitMasks.Pressure) > 0,
    Light: (AlsoRecord & channelBitMasks.Light) > 0,
    GPS: (AlsoRecord & channelBitMasks.GPS) > 0,
    LTE: (AlsoRecord & channelBitMasks.LTE) > 0,
    Bluetooth: (AlsoRecord & channelBitMasks.Bluetooth) > 0,
    DeviceHealth: (AlsoRecord & channelBitMasks.DeviceHealth) > 0
  };
  return channelBits;
};

/** Parses AlarmInputBits */
const parseAlarmInputBits = (AlarmInputBits: number): IAlarmInputBits => {
  const mask = alarmInputBitsBitMasks;
  const alarmInputBits: IAlarmInputBits = {
    input0: (AlarmInputBits & mask.input0used) > 0,
    input1: (AlarmInputBits & mask.input1used) > 0,
    input2: (AlarmInputBits & mask.input2used) > 0,
    input3: (AlarmInputBits & mask.input3used) > 0,
    input4: (AlarmInputBits & mask.input4used) > 0,
    input5: (AlarmInputBits & mask.input5used) > 0,
    input6: (AlarmInputBits & mask.input6used) > 0,
    input7: (AlarmInputBits & mask.input7used) > 0
  };
  return alarmInputBits;
};

/** Parses AlarmOutputBits */
const parseAlarmOutputBits = (AlarmOutputBits: number): IAlarmOutputBits => {
  const mask = alarmOutputBitsBitMasks;
  const alarmOutputBits: IAlarmOutputBits = {
    output0: {
      used: (AlarmOutputBits & mask.output0used) > 0,
      newStateOn: (AlarmOutputBits & mask.output0newState) > 0
    },
    output1: {
      used: (AlarmOutputBits & mask.output1used) > 0,
      newStateOn: (AlarmOutputBits & mask.output1newState) > 0
    },
    output2: {
      used: (AlarmOutputBits & mask.output2used) > 0,
      newStateOn: (AlarmOutputBits & mask.output2newState) > 0
    },
    output3: {
      used: (AlarmOutputBits & mask.output3used) > 0,
      newStateOn: (AlarmOutputBits & mask.output3newState) > 0
    },
    output4: {
      used: (AlarmOutputBits & mask.output4used) > 0,
      newStateOn: (AlarmOutputBits & mask.output4newState) > 0
    },
    output5: {
      used: (AlarmOutputBits & mask.output5used) > 0,
      newStateOn: (AlarmOutputBits & mask.output5newState) > 0
    },
    output6: {
      used: (AlarmOutputBits & mask.output6used) > 0,
      newStateOn: (AlarmOutputBits & mask.output6newState) > 0
    },
    output7: {
      used: (AlarmOutputBits & mask.output7used) > 0,
      newStateOn: (AlarmOutputBits & mask.output7newState) > 0
    }
  };
  return alarmOutputBits;
};

interface CreateSRDParams {
  start: IDate;
  end: IDate;
  interval: { onPeriod?: number; measureInterval?: number };
  channelBits?: IChannelBits;
  ignoreCountLTE?: number;
  ignoreCountGPS?: number;
  power: {
    flightMode?: boolean;
    inactive?: boolean;
    alwaysOn?: boolean;
    gpsRequired?: boolean;
    periodic?: boolean;
    eventTrigged?: boolean;
  };
}
/**
 * Creates a SRD item for GPS and LTE
 * @returns A SRD
 */
export const createSRD = (params: CreateSRDParams): ISRD => {
  const {
    start,
    end,
    interval,
    channelBits,
    ignoreCountLTE,
    ignoreCountGPS,
    power
  } = params;

  const onPeriod = interval?.onPeriod ?? 0;
  const extAlsoRecord = 0;
  const measureInterval = 0;
  const value32 = 0;
  const ExtSensTypeID = 0;
  let FuncCtrl = 0;

  // Lars valde detta alternativ den 27/6 2023
  // Uppdaterades med eventTrigged den 8/1 2024
  if (power.flightMode) {
    FuncCtrl |= SCTRL_FLIGHTMODE;
  } else if (power.inactive && !power.eventTrigged) {
    FuncCtrl |= SCTRL_INACTIVE;
  } else {
    if (power.eventTrigged) {
      FuncCtrl |= SCTRL_EVENTTRIGGED;
    }
    if (power.gpsRequired) {
      FuncCtrl |= SCTRL_GPSREQUIRED;
    }
    if (power.alwaysOn) {
      FuncCtrl |= SCTRL_ALWAYSON;
    }
    if (power.periodic) {
      FuncCtrl |= SCTRL_PERODIC;
    }
  }

  const AlsoRecord = channelBits
    ? packChannelBits(channelBits)
    : packChannelBits(defaultChannelBits);
  const AlarmInputBits = 0;
  const AlarmOutputBits = 0;
  const IgnoreCountLTE = ignoreCountLTE ?? 0;
  const IgnoreCountGPS = ignoreCountGPS ?? 0;

  const SRD: ISRD = {
    start,
    end,
    onPeriod,
    extAlsoRecord,
    measureInterval,
    value32,
    ExtSensTypeID,
    FuncCtrl,
    IgnoreCountLTE,
    IgnoreCountGPS,
    AlsoRecord,
    AlarmInputBits,
    AlarmOutputBits
  };

  return SRD;
};

interface CreateSchedulerParams {
  sensorName: SensorIdName;
  start: IDate;
  end: IDate;
  interval: { onPeriod?: number; measureInterval?: number };
  channelBits?: IChannelBits;
  alarmInputBits?: IAlarmInputBits;
  alarmOutputBits?: IAlarmOutputBits;
  ignoreCountLTE?: number;
  ignoreCountGPS?: number;
  sensorTypeId?: number;
  power: {
    eventTrigged?: boolean;
  };
}
/**
 * Creates a full scheduler for the given type.
 * @returns Scheduler of type IScheduler
 */
export const createScheduler = (params: CreateSchedulerParams): IScheduler => {
  const {
    sensorName,
    start,
    end,
    interval,
    channelBits,
    alarmInputBits,
    alarmOutputBits,
    ignoreCountLTE,
    ignoreCountGPS,
    sensorTypeId,
    power
  } = params;

  const sensorId = sensorIdLookup[sensorName];

  //if more than one scheduler, use createSRD instead
  const numOfSchedulers = 1;
  const onPeriod = interval?.onPeriod ?? 0;
  const extAlsoRecord = 0;
  const measureInterval = 0;
  const value32 = 0;
  let FuncCtrl = 0;

  if (interval.onPeriod) {
    FuncCtrl |= SCTRL_RECORD;
  }
  if (power.eventTrigged) {
    FuncCtrl |= SCTRL_EVENTTRIGGED;
  }

  const IgnoreCountGPS = ignoreCountGPS ?? 0;
  const IgnoreCountLTE = ignoreCountLTE ?? 0;
  const AlsoRecord = channelBits
    ? packChannelBits(channelBits)
    : packChannelBits(defaultChannelBits);
  const AlarmInputBits = alarmInputBits
    ? packAlarmInputBits(alarmInputBits)
    : packAlarmInputBits(disabledAlarmInputBits);
  const AlarmOutputBits = alarmOutputBits
    ? packAlarmOutputBits(alarmOutputBits)
    : packAlarmOutputBits(disabledAlarmOutputBits);
  const ExtSensTypeID = sensorTypeId ?? 0;

  const SRD: ISRD[] = [
    {
      start,
      end,
      onPeriod,
      extAlsoRecord,
      measureInterval,
      value32,
      ExtSensTypeID,
      FuncCtrl,
      IgnoreCountLTE,
      IgnoreCountGPS,
      AlsoRecord,
      AlarmInputBits,
      AlarmOutputBits
    }
  ];

  return { sensorId, numOfSchedulers, SRD };
};

/** Removes channel bits that are not available in device */
const filterChannelBits = (
  channelBits: IChannelBits,
  deviceFeatures?: VMDeviceFeatures
): IChannelBits => ({
  Temp: channelBits.Temp && (!deviceFeatures || deviceFeatures.DF_TEMP),
  Rh: channelBits.Rh && (!deviceFeatures || deviceFeatures.DF_RH),
  Angle: channelBits.Angle && (!deviceFeatures || deviceFeatures.DF_ANGLE),
  Pressure:
    channelBits.Pressure && (!deviceFeatures || deviceFeatures.DF_PRESSURE),
  Light: channelBits.Light && (!deviceFeatures || deviceFeatures.DF_INT_LIGHT),
  GPS: channelBits.GPS && (!deviceFeatures || deviceFeatures.DF_GPS),
  LTE: channelBits.LTE && (!deviceFeatures || deviceFeatures.DF_LTE),
  Bluetooth:
    channelBits.Bluetooth && (!deviceFeatures || deviceFeatures.DF_BLUETOOTH),
  DeviceHealth: channelBits.DeviceHealth
});

/** Disables alarm input bits that are not available in device */
const filterAlarmInputBits = (
  alarmInputBits: IAlarmInputBits,
  deviceFeatures?: VMDeviceFeatures
): IAlarmInputBits => ({
  input0:
    alarmInputBits.input0 &&
    (!deviceFeatures || getNumberOfIO(deviceFeatures.DF_EXT_IO_CFG) > 0),
  input1:
    alarmInputBits.input1 &&
    (!deviceFeatures || getNumberOfIO(deviceFeatures.DF_EXT_IO_CFG) > 1),
  input2: alarmInputBits.input2 && !deviceFeatures, // No spec yet
  input3: alarmInputBits.input3 && !deviceFeatures, // No spec yet
  input4: alarmInputBits.input4 && !deviceFeatures, // No spec yet
  input5: alarmInputBits.input5 && !deviceFeatures, // No spec yet
  input6: alarmInputBits.input6 && !deviceFeatures, // No spec yet
  input7: alarmInputBits.input7 && !deviceFeatures // No spec yet
});

/** Disables alarm output bits that are not available in device */
const filterAlarmOutputBits = (
  alarmOutputBits: IAlarmOutputBits,
  deviceFeatures?: VMDeviceFeatures
): IAlarmOutputBits => ({
  output0: {
    used:
      alarmOutputBits.output0.used &&
      (!deviceFeatures || getNumberOfIO(deviceFeatures.DF_EXT_IO_CFG) > 0),
    newStateOn: alarmOutputBits.output0.newStateOn
  },
  output1: {
    used:
      alarmOutputBits.output1.used &&
      (!deviceFeatures || getNumberOfIO(deviceFeatures.DF_EXT_IO_CFG) > 1),
    newStateOn: alarmOutputBits.output1.newStateOn
  },
  output2: {
    used: alarmOutputBits.output2.used && !deviceFeatures, // No spec yet
    newStateOn: alarmOutputBits.output2.newStateOn
  },
  output3: {
    used: alarmOutputBits.output3.used && !deviceFeatures, // No spec yet
    newStateOn: alarmOutputBits.output3.newStateOn
  },
  output4: {
    used: alarmOutputBits.output4.used && !deviceFeatures, // No spec yet
    newStateOn: alarmOutputBits.output4.newStateOn
  },
  output5: {
    used: alarmOutputBits.output5.used && !deviceFeatures, // No spec yet
    newStateOn: alarmOutputBits.output5.newStateOn
  },
  output6: {
    used: alarmOutputBits.output6.used && !deviceFeatures, // No spec yet
    newStateOn: alarmOutputBits.output6.newStateOn
  },
  output7: {
    used: alarmOutputBits.output7.used && !deviceFeatures, // No spec yet
    newStateOn: alarmOutputBits.output7.newStateOn
  }
});

/**
 * Convert recParams view model to data model. The returned type is 1:1 with ct-types in spec. If a target device is provided, the returned type will be filtered to only include features that the device supports.
 * @param params
 * @returns KIND_RECPARAMS that is 1:1 with spec
 */
export const vmRecordingParameters2DataModel = (
  params: VMRecordingParameters,
  targetDevice?: GeneralSystemInfo
): IRecordingParameters => {
  /** The response that will be returned from this function */
  let res = {} as IRecordingParameters;
  res.CfgVersion = params.CfgVersion;
  //create a function that generates this
  res.ProgInfo = params.ProgInfo;
  res.RecParams = vmRecParams2DataModel(params);

  // Find out what features the device supports
  const deviceFeatures = targetDevice
    ? interpretSystemInfoHasFeatures(targetDevice.hasFeatures)
    : undefined;

  // The following code is a workaround in order to support devices that have firmware lower than 0.1.30.0. These devices do not handle OnPeriod being 0 or having the "EventTrigged" bit set instead of the "Record" bit.
  let firmwareVersion = 1111111;
  const newFirmwareVersion = 1030000;
  if (targetDevice) {
    firmwareVersion = parseInt(
      targetDevice.fwMajorVersion.toString().padStart(3, "0") +
        targetDevice.fwMinorVersion.toString().padStart(3, "0") +
        targetDevice.fwMainBuild.toString().padStart(3, "0") +
        targetDevice.fwSubBuild.toString().padStart(3, "0")
    );
  }
  const applyWorkaround = firmwareVersion < newFirmwareVersion;

  const shouldIncludeAccParams =
    params.AccParams.useAcc &&
    (params.AccParams.useX || params.AccParams.useY || params.AccParams.useZ) &&
    (!deviceFeatures || deviceFeatures.DF_ACC);

  if (shouldIncludeAccParams) {
    res.AccParams = viewModel2AccParams(params.AccParams);
    res.AccFilterValues = getAccFilterFromVMAccParams(params.AccParams);

    if (params.AccParams.useDva) {
      res.AccDVATrigLevel = params.AccParams.dvaTriggerLevel * 100;
    }
  }

  const shouldIncludeTempParams =
    params.TempParams.useTemp && (!deviceFeatures || deviceFeatures.DF_TEMP);
  if (shouldIncludeTempParams) {
    res.TempParams = viewModel2TempParams(params.TempParams);
  }

  const shouldIncludePressureParams =
    params.PressureParams.usePressure &&
    (!deviceFeatures || deviceFeatures.DF_PRESSURE);
  if (shouldIncludePressureParams) {
    res.PressureParams = viewModel2PressureParams(params.PressureParams);
  }

  const shouldIncludeRhParams =
    params.RhParams.useRh && (!deviceFeatures || deviceFeatures.DF_RH);
  if (shouldIncludeRhParams) {
    res.RhParams = rhParamsState2rhParams(params.RhParams);
  }

  const shouldIncludeAngleParams =
    params.AngleParams.useAngle && (!deviceFeatures || deviceFeatures.DF_ANGLE);
  if (shouldIncludeAngleParams) {
    res.AngleParams = viewModel2AngleParams(params.AngleParams);
  }

  const extSensorTech = deviceFeatures
    ? getExternalSensor(deviceFeatures.DF_EXT_IO_CFG)
    : "NONE";

  const shouldIncludeExternalInputParams =
    (!deviceFeatures || extSensorTech === "IO") &&
    params.ExternalInputParams.some((input) => input.used);
  if (shouldIncludeExternalInputParams) {
    res.ExternalInputs = viewModel2ExternalInputParams(
      params.ExternalInputParams,
      deviceFeatures
    );
  }

  const shouldIncludeExternalOutputParams =
    (!deviceFeatures || extSensorTech === "IO") &&
    params.ExternalOutputParams.some((output) => output.used);
  if (shouldIncludeExternalOutputParams) {
    res.ExternalOutputs = viewModel2ExternalOutputParams(
      params.ExternalOutputParams,
      deviceFeatures
    );
  }

  const shouldIncludeExternalSensors =
    (!deviceFeatures || extSensorTech === "I2C") &&
    params.ExternalSensorParams.length > 0;
  if (shouldIncludeExternalSensors) {
    res.ExternalSensors = viewModel2ExternalSensors(
      params.ExternalSensorParams
    );
  }

  /**
   * Check if a channel is trigged by any of the other channels
   */
  const isEventTrigged = (channel: IChannelBitTypes): boolean => {
    if (params.AccParams.useAcc && params.AccParams.channelBits[channel]) {
      return true;
    }
    if (params.TempParams.useTemp && params.TempParams.channelBits[channel]) {
      return true;
    }
    if (params.RhParams.useRh && params.RhParams.channelBits[channel]) {
      return true;
    }
    if (
      params.AngleParams.useAngle &&
      params.AngleParams.channelBits[channel]
    ) {
      return true;
    }
    if (
      params.ExternalInputParams.some(
        (input) => input.used && input.channelBits[channel]
      )
    ) {
      return true;
    }
    return false;
  };

  /** Include GPS SRD if unit has GPS and GPS is periodic, always on, in flight mode, required by LTE, or any of the channels use GPS */
  const shouldIncludeGpsParams =
    (!deviceFeatures || deviceFeatures.DF_GPS) &&
    (params.scheduleBlocks.some(
      (block) =>
        block.GpsParams.useGpsInterval ||
        block.GpsParams.alwaysOn ||
        block.GpsParams.flightModeGps ||
        block.flightMode ||
        block.LteParams.GpsRequired
    ) ||
      isEventTrigged("GPS"));

  if (shouldIncludeGpsParams && params?.GpsParams?.storeSpeed) {
    res.GPS = { storeSpeed: params?.GpsParams?.storeSpeed ? 1 : 0 };
  }

  /** Inclide LTE SRD if unit has LTE and LTE is periodic, in flight mode, or any of the channels use LTE */
  const shouldIncludeLteParams =
    (!deviceFeatures || deviceFeatures.DF_LTE) &&
    (params.scheduleBlocks.some(
      (block) =>
        block.LteParams.useLteInterval ||
        block.LteParams.alwaysOn ||
        block.LteParams.flightModeLte ||
        block.flightMode
    ) ||
      isEventTrigged("LTE"));

  if (shouldIncludeLteParams && params?.LteParams) {
    res.LTE = viewModel2LteParams(params.LteParams);
  }

  if (!isEmpty(params?.UserInfo)) {
    res.UserInfo = params.UserInfo;
  }

  if (!isEmpty(params?.ProjectName)) {
    res.ProjectName = params.ProjectName;
  }

  if (!isEmpty(params?.CompanyId)) {
    res.CompanyId = params.CompanyId;
  }

  if (!isEmpty(params?.ParameterId)) {
    res.ParameterId = params.ParameterId;
  }

  //schedulers
  //These needs to be last to get the order right when packing.
  res.SRD = [];
  if (shouldIncludeAccParams) {
    const {
      channelBits,
      alarmInputBits,
      alarmOutputBits,
      IgnoreCountLTE,
      IgnoreCountGPS
    } = params.AccParams!;
    res.SRD.push(
      createScheduler({
        sensorName: "Acc",
        start: res.RecParams.start,
        end: res.RecParams.end,
        interval: { onPeriod: applyWorkaround ? 2.419e9 : 0 }, // 28 days or none
        channelBits: filterChannelBits(channelBits, deviceFeatures),
        alarmInputBits: filterAlarmInputBits(alarmInputBits, deviceFeatures),
        alarmOutputBits: filterAlarmOutputBits(alarmOutputBits, deviceFeatures),
        ignoreCountLTE: IgnoreCountLTE,
        ignoreCountGPS: IgnoreCountGPS,
        power: { eventTrigged: true }
      })
    );
  }

  if (shouldIncludeTempParams) {
    const {
      intervalDays,
      intervalHours,
      intervalMinutes,
      channelBits,
      alarmInputBits,
      alarmOutputBits,
      IgnoreCountLTE,
      IgnoreCountGPS
    } = params.TempParams!;
    /** Period in milliseconds */
    let onPeriod = convertDaysHourMinutesToMilliseconds(
      intervalDays,
      intervalHours,
      intervalMinutes
    );
    if (applyWorkaround && onPeriod === 0) {
      onPeriod = 2.419e9; // 28 days
    }

    res.SRD.push(
      createScheduler({
        sensorName: "Temp",
        start: res.RecParams.start,
        end: res.RecParams.end,
        interval: { onPeriod },
        channelBits: filterChannelBits(channelBits, deviceFeatures),
        alarmInputBits: filterAlarmInputBits(alarmInputBits, deviceFeatures),
        alarmOutputBits: filterAlarmOutputBits(alarmOutputBits, deviceFeatures),
        ignoreCountLTE: IgnoreCountLTE,
        ignoreCountGPS: IgnoreCountGPS,
        power: { eventTrigged: isEventTrigged("Temp") || onPeriod === 0 }
      })
    );
  } else if (isEventTrigged("Temp")) {
    res.SRD.push(
      createScheduler({
        sensorName: "Temp",
        start: res.RecParams.start,
        end: res.RecParams.end,
        interval: { onPeriod: applyWorkaround ? 2.419e9 : 0 }, // 28 days or none
        power: { eventTrigged: true }
      })
    );
  }

  if (shouldIncludePressureParams) {
    const {
      intervalDays,
      intervalHours,
      intervalMinutes,
      channelBits,
      alarmInputBits,
      alarmOutputBits,
      IgnoreCountLTE,
      IgnoreCountGPS
    } = params.PressureParams!;
    /** Period in milliseconds */
    let onPeriod = convertDaysHourMinutesToMilliseconds(
      intervalDays,
      intervalHours,
      intervalMinutes
    );
    if (applyWorkaround && onPeriod === 0) {
      onPeriod = 2.419e9; // 28 days
    }

    res.SRD.push(
      createScheduler({
        sensorName: "Pressure",
        start: res.RecParams.start,
        end: res.RecParams.end,
        interval: { onPeriod },
        channelBits: filterChannelBits(channelBits, deviceFeatures),
        alarmInputBits: filterAlarmInputBits(alarmInputBits, deviceFeatures),
        alarmOutputBits: filterAlarmOutputBits(alarmOutputBits, deviceFeatures),
        ignoreCountLTE: IgnoreCountLTE,
        ignoreCountGPS: IgnoreCountGPS,
        power: { eventTrigged: isEventTrigged("Pressure") || onPeriod === 0 }
      })
    );

    // Adding a SRD for Temp when using temperature compensated pressure but useTemp is false.
    if (
      !params.TempParams?.useTemp &&
      params.PressureParams?.usePressure &&
      params.PressureParams?.params?.slopeValue != 0
    ) {
      res.SRD.push(
        createScheduler({
          sensorName: "Temp",
          start: res.RecParams.start,
          end: res.RecParams.end,
          interval: { onPeriod: applyWorkaround ? 2.419e9 : 0 }, // 28 days or none
          power: { eventTrigged: true }
        })
      );
    }
  } else if (isEventTrigged("Pressure")) {
    res.SRD.push(
      createScheduler({
        sensorName: "Pressure",
        start: res.RecParams.start,
        end: res.RecParams.end,
        interval: { onPeriod: applyWorkaround ? 2.419e9 : 0 }, // 28 days or none
        power: { eventTrigged: true }
      })
    );
  }

  if (shouldIncludeRhParams) {
    const {
      intervalDays,
      intervalHours,
      intervalMinutes,
      channelBits,
      alarmInputBits,
      alarmOutputBits,
      IgnoreCountLTE,
      IgnoreCountGPS
    } = params.RhParams!;
    /** Period in milliseconds */
    let onPeriod = convertDaysHourMinutesToMilliseconds(
      intervalDays,
      intervalHours,
      intervalMinutes
    );
    if (applyWorkaround && onPeriod === 0) {
      onPeriod = 2.419e9; // 28 days
    }

    res.SRD.push(
      createScheduler({
        sensorName: "Rh",
        start: res.RecParams.start,
        end: res.RecParams.end,
        interval: { onPeriod },
        channelBits: filterChannelBits(channelBits, deviceFeatures),
        alarmInputBits: filterAlarmInputBits(alarmInputBits, deviceFeatures),
        alarmOutputBits: filterAlarmOutputBits(alarmOutputBits, deviceFeatures),
        ignoreCountLTE: IgnoreCountLTE,
        ignoreCountGPS: IgnoreCountGPS,
        power: { eventTrigged: isEventTrigged("Rh") || onPeriod === 0 }
      })
    );
  } else if (isEventTrigged("Rh")) {
    res.SRD.push(
      createScheduler({
        sensorName: "Rh",
        start: res.RecParams.start,
        end: res.RecParams.end,
        interval: { onPeriod: applyWorkaround ? 2.419e9 : 0 }, // 28 days or none
        power: { eventTrigged: true }
      })
    );
  }

  if (shouldIncludeAngleParams) {
    const {
      channelBits,
      alarmInputBits,
      alarmOutputBits,
      IgnoreCountLTE,
      IgnoreCountGPS
    } = params.AngleParams!;

    res.SRD.push(
      createScheduler({
        sensorName: "Angle",
        start: res.RecParams.start,
        end: res.RecParams.end,
        interval: { onPeriod: applyWorkaround ? 2.419e9 : 0 }, // 28 days or none
        channelBits: filterChannelBits(channelBits, deviceFeatures),
        alarmInputBits: filterAlarmInputBits(alarmInputBits, deviceFeatures),
        alarmOutputBits: filterAlarmOutputBits(alarmOutputBits, deviceFeatures),
        ignoreCountLTE: IgnoreCountLTE,
        ignoreCountGPS: IgnoreCountGPS,
        power: { eventTrigged: true }
      })
    );
  } else if (isEventTrigged("Angle")) {
    res.SRD.push(
      createScheduler({
        sensorName: "Angle",
        start: res.RecParams.start,
        end: res.RecParams.end,
        interval: { onPeriod: applyWorkaround ? 2.419e9 : 0 }, // 28 days or none
        power: { eventTrigged: true }
      })
    );
  }

  if (shouldIncludeExternalInputParams) {
    res.SRD.push(
      createScheduler({
        sensorName: "ExtInputs",
        start: res.RecParams.start,
        end: res.RecParams.end,
        interval: { onPeriod: 0 },
        channelBits: filterChannelBits(defaultChannelBits, deviceFeatures),
        alarmInputBits: filterAlarmInputBits(
          disabledAlarmInputBits,
          deviceFeatures
        ),
        alarmOutputBits: filterAlarmOutputBits(
          disabledAlarmOutputBits,
          deviceFeatures
        ),
        power: { eventTrigged: true }
      })
    );
  }
  if (shouldIncludeExternalOutputParams) {
    res.SRD.push(
      createScheduler({
        sensorName: "ExtOutputs",
        start: res.RecParams.start,
        end: res.RecParams.end,
        interval: { onPeriod: 0 },
        power: { eventTrigged: true }
      })
    );
  }

  if (shouldIncludeExternalSensors) {
    params.ExternalSensorParams.forEach((sensor, index) => {
      const {
        intervalDays,
        intervalHours,
        intervalMinutes,
        channelBits,
        alarmInputBits,
        alarmOutputBits,
        params
      } = sensor;

      /** Period in milliseconds */
      let onPeriod = convertDaysHourMinutesToMilliseconds(
        intervalDays,
        intervalHours,
        intervalMinutes
      );
      if (applyWorkaround && onPeriod === 0) {
        onPeriod = 2.419e9; // 28 days
      }

      const { sensorTypeId } = params;
      const sensorIdName = `ExtSensor${(index % 8) + 1}` as SensorIdName;

      res.SRD?.push(
        createScheduler({
          sensorName: sensorIdName,
          start: res.RecParams.start,
          end: res.RecParams.end,
          interval: { onPeriod },
          channelBits: filterChannelBits(channelBits, deviceFeatures),
          alarmInputBits: filterAlarmInputBits(alarmInputBits, deviceFeatures),
          alarmOutputBits: filterAlarmOutputBits(
            alarmOutputBits,
            deviceFeatures
          ),
          sensorTypeId,
          power: { eventTrigged: onPeriod === 0 }
        })
      );
    });
  }

  // Schedulers for LTE and GPS
  const schedules: IRecParams[] = vmSchedulers2DataModel(params);

  let GpsSRDlist: ISRD[] = [];
  let LteSRDlist: ISRD[] = [];
  schedules.forEach((times, index) => {
    const block = params.scheduleBlocks[index];
    const lteMeasureInterval = convertDaysHourMinutesToMilliseconds(
      block.LteParams.intervalDays,
      block.LteParams.intervalHours,
      block.LteParams.intervalMinutes
    );

    LteSRDlist.push(
      createSRD({
        start: times.start,
        end: times.end,
        interval: {
          onPeriod: lteMeasureInterval,
          measureInterval: lteMeasureInterval
        },
        power: {
          periodic: block.LteParams.useLteInterval,
          flightMode: block.flightMode || block.LteParams.flightModeLte,
          inactive: !block.LteParams.useLteInterval,
          alwaysOn: block.LteParams.alwaysOn,
          gpsRequired: block.LteParams.GpsRequired,
          eventTrigged: isEventTrigged("LTE") || lteMeasureInterval === 0
        }
      })
    );

    const gpsMeasureInterval = convertDaysHourMinutesToMilliseconds(
      block.GpsParams.intervalDays,
      block.GpsParams.intervalHours,
      block.GpsParams.intervalMinutes
    );

    GpsSRDlist.push(
      createSRD({
        start: times.start,
        end: times.end,
        interval: {
          onPeriod: gpsMeasureInterval,
          measureInterval: gpsMeasureInterval
        },
        power: {
          periodic: block.GpsParams.useGpsInterval,
          flightMode: block.flightMode || block.GpsParams.flightModeGps,
          inactive: !block.GpsParams.useGpsInterval,
          alwaysOn: block.GpsParams.alwaysOn,
          eventTrigged: isEventTrigged("GPS") || gpsMeasureInterval === 0
        }
      })
    );
  });

  // Create and push LTE scheduler if the device has LTE
  if (shouldIncludeLteParams) {
    const LteScheduler: IScheduler = {
      sensorId: sensorIdLookup["Lte"],
      numOfSchedulers: LteSRDlist.length,
      SRD: LteSRDlist
    };
    res.SRD.push(LteScheduler);
  }

  // Create and push GPS scheduler if the device has GPS
  if (shouldIncludeGpsParams) {
    const GpsScheduler: IScheduler = {
      sensorId: sensorIdLookup["Gps"],
      numOfSchedulers: GpsSRDlist.length,
      SRD: GpsSRDlist
    };
    res.SRD.push(GpsScheduler);
  }

  //temp to measure battery health always
  const healthScheduler = createScheduler({
    sensorName: "DevHealth",
    start: res.RecParams.start,
    end: res.RecParams.end,
    interval: { onPeriod: 8.64e7 }, // every 24h
    power: { eventTrigged: isEventTrigged("DeviceHealth") }
  });
  res.SRD.push(healthScheduler);

  return res;
};

/**
 * Turn parameters into a view model that is ready to be displayed in the UI
 * @param params
 */
export const recordingParameters2ViewModel = (
  params: IRecordingParameters
): VMRecordingParameters => {
  let res = {} as VMRecordingParameters;

  res.CfgVersion = params.CfgVersion;
  res.ProgInfo = params.ProgInfo;
  res.RecParams = recParams2ViewModel(params.RecParams);

  //schedulers
  const accScheduler = isNil(params.SRD)
    ? undefined
    : getAccScheduler(params.SRD);
  const tempScheduler = isNil(params.SRD)
    ? undefined
    : getTempScheduler(params.SRD);
  const pressureScheduler = isNil(params.SRD)
    ? undefined
    : getPressureScheduler(params.SRD);
  const rhScheduler = isNil(params.SRD)
    ? undefined
    : getRhScheduler(params.SRD);
  const angleScheduler = isNil(params.SRD)
    ? undefined
    : getAngleScheduler(params.SRD);
  const lteScheduler = isNil(params.SRD)
    ? undefined
    : getLteScheduler(params.SRD);
  const gpsScheduler = isNil(params.SRD)
    ? undefined
    : getGpsScheduler(params.SRD);
  const externalSensorSchedulers = isNil(params.SRD)
    ? undefined
    : getExternalSensorSchedulers(params.SRD);
  // const inputSchedulers = isNil(params.SRD)
  //   ? undefined
  //   : getInputSchedulers(params.SRD);
  // const outputSchedulers = isNil(params.SRD)
  //   ? undefined
  //   : getOutputSchedulers(params.SRD);

  res.AccParams =
    accParams2ViewModel(
      params.AccParams,
      params.AccFilterValues,
      params.AccDVATrigLevel,
      accScheduler
    ) ?? disabledAccParams;

  res.TempParams =
    tempParams2ViewModel(params.TempParams, tempScheduler) ??
    disabledTempParams;

  res.PressureParams =
    pressureParams2ViewModel(params.PressureParams, pressureScheduler) ??
    disabledPressureParams;

  res.RhParams =
    rhParams2ViewModel(params.RhParams, rhScheduler) ?? disabledRhParams;

  res.AngleParams =
    angleParams2ViewModel(params.AngleParams, angleScheduler) ??
    disabledAngleParams;

  res.LteParams = lteParams2ViewModel(params.LTE) ?? defaultLteParams;
  res.GpsParams = { storeSpeed: params.GPS?.storeSpeed === 1 ? true : false };

  res.ExternalInputParams = externalInputParams2ViewModel(
    params.ExternalInputs
  );
  res.ExternalOutputParams = externalOutputParams2ViewModel(
    params.ExternalOutputs
  );

  res.ExternalSensorParams = externalSensors2ViewModel(
    params.ExternalSensors,
    externalSensorSchedulers
  );

  const findNullPattern = /\0/g;
  res.scheduleBlocks = [];
  const unixBase = iDate2Unix(getIDateEpochBase());

  // Create schedule blocks
  if (lteScheduler && gpsScheduler) {
    if (lteScheduler.SRD.length === gpsScheduler.SRD.length) {
      for (let i = 0; i < lteScheduler.SRD.length; i++) {
        let blockDuration = 0;
        if (lteScheduler.SRD[i].end.year < 21) {
          blockDuration =
            (iDate2Unix(lteScheduler.SRD[i].end) - unixBase) / 3600;
        } else {
          blockDuration =
            (iDate2Unix(lteScheduler.SRD[i].end) -
              iDate2Unix(lteScheduler.SRD[i].start)) /
            3600;
        }

        const lteParams = lteSRD2ViewModel(lteScheduler.SRD[i]);
        const gpsParams = gpsSRD2ViewModel(gpsScheduler.SRD[i]);
        const scheduleBlock: ScheduleBlock = {
          blockDuration,
          LteParams: lteParams ?? emptyLteParams,
          GpsParams: gpsParams ?? emptyGpsParams,
          flightMode:
            lteScheduler.SRD[i].FuncCtrl & SCTRL_FLIGHTMODE &&
            gpsScheduler.SRD[i].FuncCtrl & SCTRL_FLIGHTMODE
              ? true
              : false
        };
        res.scheduleBlocks.push(scheduleBlock);
      }
    }
  } else if (lteScheduler && lteScheduler.SRD.length > 0) {
    lteScheduler.SRD.forEach((SRD) => {
      let blockDuration = 0;
      if (SRD.end.year < 21) {
        blockDuration = (iDate2Unix(SRD.end) - unixBase) / 3600;
      } else {
        blockDuration = (iDate2Unix(SRD.end) - iDate2Unix(SRD.start)) / 3600;
      }
      const lteParams = lteSRD2ViewModel(SRD);
      const scheduleBlock: ScheduleBlock = {
        blockDuration,
        LteParams: lteParams ?? emptyLteParams,
        GpsParams: disabledGpsParams,
        flightMode: SRD.FuncCtrl & SCTRL_FLIGHTMODE ? true : false
      };
      res.scheduleBlocks.push(scheduleBlock);
    });
  } else if (gpsScheduler && gpsScheduler.SRD.length > 0) {
    gpsScheduler.SRD.forEach((SRD) => {
      let blockDuration = 0;
      if (SRD.end.year < 21) {
        blockDuration = (iDate2Unix(SRD.end) - unixBase) / 3600;
      } else {
        blockDuration = (iDate2Unix(SRD.end) - iDate2Unix(SRD.start)) / 3600;
      }
      const gpsParams = gpsSRD2ViewModel(SRD);
      const scheduleBlock: ScheduleBlock = {
        blockDuration,
        LteParams: disabledLteParams,
        GpsParams: gpsParams ?? emptyGpsParams,
        flightMode: SRD.FuncCtrl & SCTRL_FLIGHTMODE ? true : false
      };
      res.scheduleBlocks.push(scheduleBlock);
    });
  } else {
    const otherScheduler =
      accScheduler ?? tempScheduler ?? rhScheduler ?? angleScheduler;
    if (otherScheduler) {
      const SRD = otherScheduler.SRD[0];
      let blockDuration = 0;
      // There is only one SRD in the other schedulers
      if (SRD.end.year < 21) {
        blockDuration = (iDate2Unix(SRD.end) - unixBase) / 3600;
      } else {
        blockDuration = (iDate2Unix(SRD.end) - iDate2Unix(SRD.start)) / 3600;
      }
      res.scheduleBlocks.push({
        blockDuration: blockDuration,
        LteParams: disabledLteParams,
        GpsParams: disabledGpsParams,
        flightMode: false
      });
    } else {
      res.scheduleBlocks = [];
    }
  }

  res.UserInfo = params?.UserInfo?.replace(findNullPattern, "") ?? "";
  res.ProjectName = params?.ProjectName?.replace(findNullPattern, "") ?? "";
  res.CompanyId = params?.CompanyId?.replace(findNullPattern, "") ?? "";
  res.ParameterId = params?.ParameterId?.replace(findNullPattern, "") ?? "";

  return res;
};

/** Removes UserInfo, ProjectName, CompanyId and ParameterId from params */
export const cleanParams = (
  params: VMRecordingParameters,
  appVersion: AppVersion,
  companyId?: string
): VMRecordingParameters => {
  const { RecParams: oldParams } = params;
  const ProgInfo = {
    ...params.ProgInfo,
    ...appVersion
  };
  let startTimestamp = oldParams.startTimestamp;
  let stopTimestamp = oldParams.stopTimestamp;
  let startRecordingType = oldParams.startRecordingType;
  let endRecordingType = oldParams.endRecordingType;
  let stopAfterDuration = {
    days: oldParams.stopAfterDuration.days,
    hours: Math.round(oldParams.stopAfterDuration.hours * 100) / 100
  };

  // Returns the recording duration in hours
  const stopOffset = () => {
    if (stopAfterDuration.days + stopAfterDuration.hours > 0) {
      return stopAfterDuration.days * 24 + stopAfterDuration.hours;
    } else {
      return 365 * 24; // Default to 1 year
    }
  };

  const setStartDirectly = () => {
    startRecordingType = "directly";
    startTimestamp = dayjs().unix();
    endRecordingType = "date";
    stopTimestamp = startTimestamp + stopOffset() * 3600;
  };

  // If start or end date is missing,
  // set start to "directly" and end to "date"
  if (
    oldParams.startRecordingType !== "button" &&
    (isUndefined(oldParams.startTimestamp) ||
      isUndefined(oldParams.stopTimestamp))
  ) {
    setStartDirectly();
  }

  // Make sure "afterDuration" has duration
  if (
    oldParams.startRecordingType === "button" &&
    oldParams.endRecordingType === "afterDuration" &&
    stopAfterDuration.days + stopAfterDuration.hours === 0
  ) {
    stopAfterDuration = defaultStopAfterDuration;
  }

  // Make sure stopTimestamp is not in the past
  if (
    oldParams.startRecordingType !== "button" &&
    !isUndefined(oldParams.stopTimestamp) &&
    dayjs().isAfter(dayjs.unix(oldParams.stopTimestamp))
  ) {
    setStartDirectly();
  }

  const RecParams = {
    ...params.RecParams,
    startTimestamp,
    stopTimestamp,
    startRecordingType,
    endRecordingType,
    stopAfterDuration
  };
  return {
    ...params,
    ProgInfo,
    RecParams,
    UserInfo: undefined,
    ProjectName: undefined,
    CompanyId: companyId,
    ParameterId: undefined
  };
};

export const getAccScheduler = (schedulers: IScheduler[]) =>
  schedulers.find((x) => x.sensorId === SSI_Acc);

export const getTempScheduler = (schedulers: IScheduler[]) =>
  schedulers.find((x) => x.sensorId === SSI_Temp);

export const getPressureScheduler = (schedulers: IScheduler[]) =>
  schedulers.find((x) => x.sensorId === SSI_Pressure);

export const getRhScheduler = (schedulers: IScheduler[]) =>
  schedulers.find((x) => x.sensorId === SSI_RH);

export const getAngleScheduler = (schedulers: IScheduler[]) =>
  schedulers.find((x) => x.sensorId === SSI_Angle);

export const getLteScheduler = (schedulers: IScheduler[]) =>
  schedulers.find((x) => x.sensorId === SSI_LTE);

export const getGpsScheduler = (schedulers: IScheduler[]) =>
  schedulers.find((x) => x.sensorId === SSI_GPS);

export const getExternalSensorSchedulers = (
  schedulers: IScheduler[]
): IScheduler[] =>
  schedulers.filter((scheduler) =>
    External_Sensors_SSI.includes(scheduler.sensorId)
  );

// export const getInputSchedulers = (schedulers: IScheduler[]) =>
//   schedulers.filter((x) => x.sensorId === SSI_Ext_Inputs);

// export const getOutputSchedulers = (schedulers: IScheduler[]) => schedulers.filter((x) => x.sensorId === SSI_Ext_Outputs);

//======Date functions======

/** Converts dayjs to IDate. IDate will always be in utc */
export const dayjs2IDate = (date: Dayjs): IDate => {
  //NOTE: month+1 since it counts 0-11

  return {
    year: date.utc().year() - 2000,
    month: date.utc().month() + 1,
    day: date.utc().date(),
    hour: date.utc().hour(),
    minute: date.utc().minute(),
    second: date.utc().second()
  };
};

export const unix2IDate = (timestamp: number): IDate => {
  const m = dayjs.unix(timestamp);
  return dayjs2IDate(m);
};

export const date2DayjsTuple = (dateTuple: [Date, Date]): [Dayjs, Dayjs] => {
  return [dayjs(dateTuple[0]), dayjs(dateTuple[1])];
};

export const unix2Date = (date: number): Date => new Date(date * 1e3);

export const array2IDate = (arr: number[]): IDate => {
  if (arr.length !== 6) console.log("Arr wrong length for IDate");
  const date: IDate = {
    year: arr[0],
    month: arr[1],
    day: arr[2],
    hour: arr[3],
    minute: arr[4],
    second: arr[5]
  };

  return date;
};

/** Get base for i date epochs */
export const getIDateEpochBase = (): IDate => ({
  year: 0,
  month: 1,
  day: 1,
  hour: 0,
  minute: 0,
  second: 0
});

export const duration2IDateEpoch = ({
  years = 0,
  months = 0,
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0
}): IDate => {
  //start from 2000-01-01 ...
  const base: IDate = getIDateEpochBase();

  const m = iDate2dayjs(base);

  m.add(years, "years");
  m.add(months, "months");
  m.add(days, "days");
  m.add(hours, "hours");
  m.add(minutes, "minutes");
  m.add(seconds, "seconds");

  return dayjs2IDate(m);
};

const iDateEpoch2DaysHours = (date: IDate): { days: number; hours: number } => {
  const base = getIDateEpochBase();

  const unixBase = iDate2Unix(base);
  const unixDate = iDate2Unix(date);

  const diff = unixDate - unixBase;

  return unix2DaysHours(diff);
};

const iDateEpoch2Seconds = (date: IDate): number => {
  const base = getIDateEpochBase();
  const unixBase = iDate2Unix(base);
  const unixDate = iDate2Unix(date);
  return unixDate - unixBase;
};

const unix2DaysHours = (timestamp: number): { days: number; hours: number } => {
  const inHours = timestamp / 3600;
  const inDays = inHours / 24;

  const days = Math.floor(inDays);
  const hours = inHours - days * 24;

  return { days, hours };
};

export const vmRecParams2DataModel = (
  params: VMRecordingParameters
): IRecParams => {
  const { scheduleBlocks, RecParams } = params;
  const { startRecordingType: startType, startTimestamp } = RecParams;

  const recMode = generateRecParamsStartStopId(startType);

  const fullDuration = () => {
    let time = 0;
    for (let i = 0; i < scheduleBlocks.length; i++) {
      time += scheduleBlocks[i].blockDuration;
    }
    return time;
  };

  // If startType is "on date", the defined start timestamp is used
  // If startType is "directly" or "by button", the current time is used
  const startTime =
    startType === "date" && startTimestamp
      ? dayjs.unix(startTimestamp)
      : dayjs();

  const baseTime = iDate2dayjs(getIDateEpochBase()); // 2000-01-01 00:00:00

  const start: IDate = dayjs2IDate(startTime);
  let end: IDate;

  // Start "on date" or start "directly" havs absolute date as end
  if (startType === "date" || startType === "directly") {
    end = dayjs2IDate(startTime.clone().add(fullDuration(), "hours"));
  }
  // Start "by button" has block duration as stop
  else {
    end = dayjs2IDate(baseTime.clone().add(fullDuration(), "hours"));
  }

  return { recMode, start, end };
};

/**
 * Generates the schedules for LTE and GPS start and end times
 */
export const vmSchedulers2DataModel = (
  params: VMRecordingParameters
): IRecParams[] => {
  let times: IRecParams[] = [];
  const { scheduleBlocks, RecParams } = params;
  const { startRecordingType: startType, startTimestamp } = RecParams;

  const recMode = generateRecParamsStartStopId(startType);

  // Adds the durations from all schedule blocks until the given index
  const timeAtIndex = (index: number) => {
    let time = 0;
    for (let i = 0; i < index; i++) {
      time += scheduleBlocks[i].blockDuration;
    }
    return time;
  };

  // NOTE: Times are also handled in vmRecParams2DataModel (above) and needs to be implemented similarly here

  // If startType is "on date", the defined start timestamp is used
  // If startType is "directly" or "by button", the current time is used
  const startTime =
    startType === "date" && startTimestamp
      ? dayjs.unix(startTimestamp)
      : dayjs();

  const baseTime = iDate2dayjs(getIDateEpochBase()); // 2000-01-01 00:00:00

  scheduleBlocks.forEach((scheduler, index) => {
    const { blockDuration } = scheduler;

    // Add the duration of the previous blocks to the block's start time
    const blockStart = startTime.clone().add(timeAtIndex(index), "hours");
    const start = dayjs2IDate(blockStart);
    let end: IDate;

    // Start "on date" or start "directly" havs absolute date as end
    if (startType === "date" || startType === "directly") {
      end = dayjs2IDate(blockStart.clone().add(blockDuration, "hours"));
    }
    // Start "by button" has block duration as stop
    else {
      end = dayjs2IDate(baseTime.clone().add(blockDuration, "hours"));
    }

    times.push({ start, end, recMode });
  });

  return times;
};

export const recParams2ViewModel = (params: IRecParams): VMRecParams => {
  const { recMode, start, end } = params;

  const startRecordingType = getStartRecMode(recMode) ?? "directly";
  const endRecordingType = getStopRecMode(recMode) ?? "date";

  const startTimestamp = iDate2Unix(start);
  let stopTimestamp = iDate2Unix(end);
  let stopAfterDuration = { days: 0, hours: 0 };

  if (startTimestamp > stopTimestamp) {
    // "end" is a duration
    stopAfterDuration = iDateEpoch2DaysHours(end);
    stopTimestamp = startTimestamp + iDateEpoch2Seconds(end);
  } else {
    // "end" is a date
    stopAfterDuration = unix2DaysHours(stopTimestamp - startTimestamp);
  }

  return {
    startRecordingType,
    endRecordingType,
    startTimestamp,
    stopTimestamp,
    stopAfterDuration
  };
};

/**
 * Get start recmode from StartStopId
 * @param id StartStopId (2 byte number, only the last byte in use)
 */
export const getStartRecMode = (id: number): RecStartType | undefined => {
  const i16Buffer = Buffer.from([0x00, id]);
  const i8 = i16Buffer.readUInt8(1);
  const startId = i8 & 0xf0;

  if (startId === REC_START_DATE) return "date";
  if (startId === REC_START_DOWNLOAD) return "directly";
  if (startId === REC_START_ON_BUTTON) return "button";

  return undefined;
};

/**
 * Get end recmode from StartStopId
 * @param id StartStopId (2 byte number, only the last byte in use)
 */
export const getStopRecMode = (id: number): RecEndType | undefined => {
  const i16Buffer = Buffer.from([0x00, id]);
  const i8 = i16Buffer.readUInt8(1);
  const stopId = i8 & 0x0f;

  if (stopId === REC_STOP_DATE) return "date";
  if (stopId === REC_STOP_DURATION) return "afterDuration";

  return undefined;
};

/**
 * Transforms acc params data model to view model. If params is undefined,
 * default parameters is returned
 * @param accParams
 * @param accFilter
 * @param srd
 */
export const accParams2ViewModel = (
  accParams: Optional<IAccParams>,
  accFilter: Optional<IAccFilterValues>,
  dvaTriggerLevel: Optional<number>,
  scheduler: Optional<IScheduler>
): Optional<VMAccParams> => {
  if (isNil(accParams) || isNil(scheduler) || isNil(accFilter)) {
    return undefined;
  }

  const largeNumber = 0xffff;

  const useX = accParams.Xalarm !== largeNumber;
  const useY = accParams.Yalarm !== largeNumber;
  const useZ = accParams.Zalarm !== largeNumber;

  const useAcc = useX || useY || useZ;

  const params = {
    Xreg: useX ? accParams.Xreg / 100 : 0.5,
    Xalarm: useX ? accParams.Xalarm / 100 : 2,
    Xms: useX ? accParams.Xms : 25,

    Yreg: useY ? accParams.Yreg / 100 : 0.5,
    Yalarm: useY ? accParams.Yalarm / 100 : 2,
    Yms: useY ? accParams.Yms : 25,

    Zreg: useZ ? accParams.Zreg / 100 : 0.5,
    Zalarm: useZ ? accParams.Zalarm / 100 : 2,
    Zms: useZ ? accParams.Zms : 25
  };

  const IgnoreCountLTE = scheduler.SRD[0].IgnoreCountLTE;
  const IgnoreCountGPS = scheduler.SRD[0].IgnoreCountGPS;

  const channelBits: IChannelBits = parseChannelBits(
    scheduler.SRD[0].AlsoRecord
  );
  const alarmInputBits: IAlarmInputBits = parseAlarmInputBits(
    scheduler.SRD[0].AlarmInputBits
  );
  const alarmOutputBits: IAlarmOutputBits = parseAlarmOutputBits(
    scheduler.SRD[0].AlarmOutputBits
  );

  const useDva = dvaTriggerLevel !== undefined && dvaTriggerLevel !== 0;

  return {
    useAcc,
    useX,
    useY,
    useZ,
    params,
    accFilter: accFilter.value1 ?? 10,
    //todo: can this be undefined
    useDva,
    dvaTriggerLevel: (dvaTriggerLevel ?? 0) / 100,
    IgnoreCountLTE,
    IgnoreCountGPS,
    channelBits,
    alarmInputBits,
    alarmOutputBits
  };
};

export const viewModel2AccParams = (params: VMAccParams): IAccParams => {
  const largeNumber = 0xffff;

  return {
    Xreg: params.useX ? params.params.Xreg * 100 : largeNumber,
    Xalarm: params.useX ? params.params.Xalarm * 100 : largeNumber,
    Xms: params.useX ? params.params.Xms : largeNumber,

    Yreg: params.useY ? params.params.Yreg * 100 : largeNumber,
    Yalarm: params.useY ? params.params.Yalarm * 100 : largeNumber,
    Yms: params.useY ? params.params.Yms : largeNumber,

    Zreg: params.useZ ? params.params.Zreg * 100 : largeNumber,
    Zalarm: params.useZ ? params.params.Zalarm * 100 : largeNumber,
    Zms: params.useZ ? params.params.Zms : largeNumber
  };
};

/**
 * Get acc filter values from view model. If use acc filter is toggled off, 0
 * will be returned for values
 * @param params
 */
const getAccFilterFromVMAccParams = (params: VMAccParams): IAccFilterValues => {
  const { accFilter } = params;

  const value1 = accFilter;
  const value2 = accFilter;

  return { value1, value2 };
};

/**
 * Transforms temp params data model to view model. If params is undefined,
 * undefined is returned
 * @param tempParams
 * @param scheduler
 */
export const tempParams2ViewModel = (
  tempParams: Optional<ITempParams>,
  scheduler: Optional<IScheduler>
): Optional<VMTempParams> => {
  if (isNil(tempParams) || isNil(scheduler)) {
    return undefined;
  }

  const useTemp = true;
  const params = tempParams;

  const interval = convertMillisecondsToDayHourMinutes(
    scheduler.SRD[0].onPeriod
  );

  const intervalDays = interval.days;
  const intervalHours = interval.hours;
  const intervalMinutes = interval.minutes;
  const IgnoreCountLTE = scheduler.SRD[0].IgnoreCountLTE;
  const IgnoreCountGPS = scheduler.SRD[0].IgnoreCountGPS;
  const channelBits: IChannelBits = parseChannelBits(
    scheduler.SRD[0].AlsoRecord
  );
  const alarmInputBits: IAlarmInputBits = parseAlarmInputBits(
    scheduler.SRD[0].AlarmInputBits
  );
  const alarmOutputBits: IAlarmOutputBits = parseAlarmOutputBits(
    scheduler.SRD[0].AlarmOutputBits
  );

  return {
    useTemp,
    params,
    intervalDays,
    intervalHours,
    intervalMinutes,
    IgnoreCountLTE,
    IgnoreCountGPS,
    channelBits,
    alarmInputBits,
    alarmOutputBits
  };
};

/** Temp params view model to ct-type */
export const viewModel2TempParams = (params: VMTempParams): ITempParams => {
  return params.params;
};

/**
 * Transforms pressure params data model to view model. If params is undefined,
 * undefined is returned
 * @param tempParams
 * @param scheduler
 */
export const pressureParams2ViewModel = (
  pressureParams: Optional<IPressureParams>,
  scheduler: Optional<IScheduler>
): Optional<VMPressureParams> => {
  if (isNil(pressureParams) || isNil(scheduler)) {
    return undefined;
  }

  const usePressure = true;
  const params = pressureParams;

  const interval = convertMillisecondsToDayHourMinutes(
    scheduler.SRD[0].onPeriod
  );

  const intervalDays = interval.days;
  const intervalHours = interval.hours;
  const intervalMinutes = interval.minutes;
  const IgnoreCountLTE = scheduler.SRD[0].IgnoreCountLTE;
  const IgnoreCountGPS = scheduler.SRD[0].IgnoreCountGPS;
  const channelBits: IChannelBits = parseChannelBits(
    scheduler.SRD[0].AlsoRecord
  );
  const alarmInputBits: IAlarmInputBits = parseAlarmInputBits(
    scheduler.SRD[0].AlarmInputBits
  );
  const alarmOutputBits: IAlarmOutputBits = parseAlarmOutputBits(
    scheduler.SRD[0].AlarmOutputBits
  );

  const slopeCalc = {
    temp1: 10,
    temp2: 20,
    pressure1: 100,
    pressure2: Math.round(params.slopeValue * 10 + 100)
    // pressure2 = (slope * (temp2 - temp1)) + pressure1
  };

  return {
    usePressure,
    params,
    intervalDays,
    intervalHours,
    intervalMinutes,
    IgnoreCountLTE,
    IgnoreCountGPS,
    channelBits,
    alarmInputBits,
    alarmOutputBits,
    slopeCalc
  };
};

/** Pressure params view model to ct-type */
export const viewModel2PressureParams = (
  params: VMPressureParams
): IPressureParams => {
  return params.params;
};

/**
 * Transforms rh params data model to view model. If params is undefined,
 * default parameters is returned
 * @param rhParams
 * @param scheduler
 */
export const rhParams2ViewModel = (
  rhParams: Optional<IRhParams>,
  scheduler: Optional<IScheduler>
): Optional<VMRhParams> => {
  if (isNil(rhParams) || isNil(scheduler)) {
    return undefined;
  }

  const useRh = true;
  const params = rhParams;

  const interval = convertMillisecondsToDayHourMinutes(
    scheduler.SRD[0].onPeriod
  );

  const intervalDays = interval.days;
  const intervalHours = interval.hours;
  const intervalMinutes = interval.minutes;

  const IgnoreCountLTE = scheduler.SRD[0].IgnoreCountLTE;
  const IgnoreCountGPS = scheduler.SRD[0].IgnoreCountGPS;

  const channelBits: IChannelBits = parseChannelBits(
    scheduler.SRD[0].AlsoRecord
  );
  const alarmInputBits: IAlarmInputBits = parseAlarmInputBits(
    scheduler.SRD[0].AlarmInputBits
  );
  const alarmOutputBits: IAlarmOutputBits = parseAlarmOutputBits(
    scheduler.SRD[0].AlarmOutputBits
  );

  return {
    useRh,
    params,
    intervalDays,
    intervalHours,
    intervalMinutes,
    IgnoreCountLTE,
    IgnoreCountGPS,
    channelBits,
    alarmInputBits,
    alarmOutputBits
  };
};

export const rhParamsState2rhParams = (params: VMRhParams): IRhParams => {
  return params.params;
};

/**
 * Transforms angle params view model to datamodel
 * @param params
 */
export const viewModel2AngleParams = (p: VMAngleParams): IAngleParams => {
  const { useAutoHorizon, params } = p;

  const autoHorizonDef = angleAutoHorizonKey;

  const xOffset = useAutoHorizon ? autoHorizonDef : 0;

  const yOffset = useAutoHorizon ? autoHorizonDef : 0;

  const zOffset = useAutoHorizon ? autoHorizonDef : 0;

  return {
    ...params,
    xOffset,
    yOffset,
    zOffset
  };
};

/**
 * Transforms LTE params view model to datamodel
 * @param params
 */
export const viewModel2LteParams = (p: VMLteParams): ILTEParams => {
  const GPSRequired = 0;
  const Enable2G = p.LteBands.Enable2G ? 1 : 0;
  const Enable3G = p.LteBands.Enable3G ? 1 : 0;
  const Enable4G = p.LteBands.Enable4G ? 1 : 0;
  const Enable5G = p.LteBands.Enable5G ? 1 : 0;
  const Enable6G = p.LteBands.Enable6G ? 1 : 0;

  return {
    GPSRequired,
    Enable2G,
    Enable3G,
    Enable4G,
    Enable5G,
    Enable6G
  };
};

/**
 * Transforms external input params view model to datamodel and filters out channels that are not supported by the device
 * @param params
 */
export const viewModel2ExternalInputParams = (
  inputs: VMExternalInputParams[],
  deviceFeatures?: VMDeviceFeatures
): IExternalInputsParams[] => {
  const inputParams: IExternalInputsParams[] = inputs
    .filter(
      (input) =>
        input.used &&
        (!deviceFeatures ||
          getNumberOfIO(deviceFeatures.DF_EXT_IO_CFG) > input.inputNumber)
    )
    .map((input) => {
      let onChange = 0;
      if (input.triggOnImpact && input.triggOnReset)
        onChange += onChangeBits.triggOnAll;
      else if (input.triggOnImpact) onChange += onChangeBits.triggOnImpact;
      // In other cases the third bit from the end is 0, which means triggOnImpact
      if (input.alarmOnTrigg) onChange += onChangeBits.alarmOnTrigg;
      if (input.saveTrigg) onChange += onChangeBits.saveTrigg;

      return {
        inputBit: Math.pow(2, input.inputNumber),
        onChange,
        alsoRecord: packChannelBits(
          filterChannelBits(input.channelBits, deviceFeatures)
        ),
        alarmOutputBits: packAlarmOutputBits(
          filterAlarmOutputBits(input.alarmOutputBits, deviceFeatures)
        ),
        alarmInputBits: packAlarmInputBits(
          filterAlarmInputBits(input.alarmInputBits, deviceFeatures)
        ),
        description: input.description
      };
    });

  return inputParams;
};

/**
 * Transforms external output params view model to datamodel and filters out channels that are not supported by the device
 * @param params
 */
export const viewModel2ExternalOutputParams = (
  outputs: VMExternalOutputParams[],
  deviceFeatures?: VMDeviceFeatures
): IExternalOutputsParams[] => {
  const outputParams: IExternalOutputsParams[] = outputs
    .filter(
      (output) =>
        output.used &&
        (!deviceFeatures ||
          getNumberOfIO(deviceFeatures.DF_EXT_IO_CFG) > output.outputNumber)
    )
    .map((output) => {
      const onChange =
        onChangeBits.triggOnAll +
        onChangeBits.alarmOnTrigg +
        (output.saveTrigg ? onChangeBits.saveTrigg : 0);
      return {
        outputBit: Math.pow(2, output.outputNumber),
        onChange,
        initialState: output.initialStateOn ? 1 : 0,
        alsoRecord: 0,
        alarmOutputBits: 0,
        alarmInputBits: 0,
        description: output.description
      };
    });
  return outputParams;
};

/** Transformms external sensors view model to data models */
export const viewModel2ExternalSensors = (
  sensors: VMExternalSensorParams[]
): IExternalSensorParams[] => {
  return sensors.map((sensor) => sensor.params);
};

/** Returns very high angle values. Used for when angle params needs to be
 * included, but shoudn't be used*/
export const maxDtAngleParams = (): IAngleParams => ({
  xAlarmLevel: 0xffff,
  yAlarmLevel: 0xffff,
  zAlarmLevel: 0xffff,
  xOffset: 0,
  yOffset: 0,
  zOffset: 0
});

/**
 * Transforms angle params data model to view model. If params is undefined,
 * default parameters is returned
 * @param angleParams
 */
export const angleParams2ViewModel = (
  angleParams: Optional<IAngleParams>,
  scheduler: Optional<IScheduler>
): Optional<VMAngleParams> => {
  if (isNil(angleParams) || isNil(scheduler)) {
    return undefined;
  }

  const useAngle = true;
  const useAutoHorizon =
    angleParams.xOffset === angleAutoHorizonKey &&
    angleParams.yOffset === angleAutoHorizonKey &&
    angleParams.zOffset === angleAutoHorizonKey;

  const {
    days: intervalDays,
    hours: intervalHours,
    minutes: intervalMinutes
  } = convertMillisecondsToDayHourMinutes(scheduler.SRD[0].onPeriod);

  const params = angleParams;

  const IgnoreCountLTE = scheduler.SRD[0].IgnoreCountLTE;
  const IgnoreCountGPS = scheduler.SRD[0].IgnoreCountGPS;
  const channelBits: IChannelBits = parseChannelBits(
    scheduler.SRD[0].AlsoRecord
  );
  const alarmInputBits: IAlarmInputBits = parseAlarmInputBits(
    scheduler.SRD[0].AlarmInputBits
  );
  const alarmOutputBits: IAlarmOutputBits = parseAlarmOutputBits(
    scheduler.SRD[0].AlarmOutputBits
  );

  return {
    useAngle,
    useAutoHorizon,
    intervalDays,
    intervalHours,
    intervalMinutes,
    params,
    IgnoreCountLTE,
    IgnoreCountGPS,
    channelBits,
    alarmInputBits,
    alarmOutputBits
  };
};

/**
 * Transforms LTE params data model to view model. If params is undefined,
 * default parameters is returned
 * @param lteParams
 */
export const lteParams2ViewModel = (
  lteParams: Optional<ILTEParams>
): Optional<VMLteParams> => {
  if (isNil(lteParams)) {
    return undefined;
  }
  const params: VMLteParams = {
    LteBands: {
      Enable2G: lteParams.Enable2G === 1,
      Enable3G: lteParams.Enable3G === 1,
      Enable4G: lteParams.Enable4G === 1,
      Enable5G: lteParams.Enable5G === 1,
      Enable6G: lteParams.Enable6G === 1
    }
  };

  return params;
};

/**
 * Transforms External Input params data model to view model.
 * @param inputParams
 */
export const externalInputParams2ViewModel = (
  inputParams: Optional<IExternalInputsParams[]>
): VMExternalInputParams[] => {
  let params = cloneDeep(defaultExternalInputParams);
  if (isNil(inputParams)) {
    return params;
  }
  inputParams.forEach((input) => {
    const inputIndex = Math.log2(input.inputBit);
    // If triggOnImpact bit === 1 or triggOnAll bit === 1
    const triggOnImpact =
      (input.onChange & onChangeBits.triggOnImpact) > 0 ||
      (input.onChange & onChangeBits.triggOnAll) > 0;
    // If triggOnReset bit === 0 or triggOnAll bit === 1
    const triggOnReset =
      (input.onChange & onChangeBits.triggOnReset) === 0 ||
      (input.onChange & onChangeBits.triggOnAll) > 0;
    params[inputIndex] = {
      inputNumber: inputIndex,
      used: true,
      triggOnImpact,
      triggOnReset,
      alarmOnTrigg: (input.onChange & onChangeBits.alarmOnTrigg) > 0,
      saveTrigg: (input.onChange & onChangeBits.saveTrigg) > 0,
      channelBits: parseChannelBits(input.alsoRecord),
      alarmInputBits: parseAlarmInputBits(input.alarmInputBits),
      alarmOutputBits: parseAlarmOutputBits(input.alarmOutputBits),
      description: input.description
    };
  });

  return params;
};
/**
 * Transforms External Output params data model to view model.
 * @param outputParams
 */
export const externalOutputParams2ViewModel = (
  outputParams: Optional<IExternalOutputsParams[]>
): VMExternalOutputParams[] => {
  let params = cloneDeep(defaultExternalOutputParams);
  if (isNil(outputParams)) {
    return params;
  }

  outputParams.forEach((output) => {
    const outputIndex = Math.log2(output.outputBit);
    params[outputIndex] = {
      outputNumber: outputIndex,
      used: true,
      saveTrigg: (output.onChange & onChangeBits.saveTrigg) > 0,
      initialStateOn: output.initialState === 1,
      description: output.description
    };
  });
  return params;
};

/** Transforms external sensors data model to view model */
export const externalSensors2ViewModel = (
  sensors: Optional<IExternalSensorParams[]>,
  schedulers: Optional<IScheduler[]>
): VMExternalSensorParams[] => {
  if (isNil(sensors) || isNil(schedulers)) {
    return [];
  }

  let params: VMExternalSensorParams[] = [];
  sensors.forEach((sensorParams) => {
    // Find the scheduler for the sensor
    const scheduler = schedulers.find(
      (scheduler) =>
        scheduler.SRD[0].ExtSensTypeID === sensorParams.sensorTypeId
    );
    if (isNil(scheduler)) return;

    const interval = convertMillisecondsToDayHourMinutes(
      scheduler.SRD[0].onPeriod
    );

    const intervalDays = interval.days;
    const intervalHours = interval.hours;
    const intervalMinutes = interval.minutes;
    params.push({
      intervalDays,
      intervalHours,
      intervalMinutes,
      params: sensorParams,
      channelBits: parseChannelBits(scheduler.SRD[0].AlsoRecord),
      alarmInputBits: disabledAlarmInputBits,
      alarmOutputBits: disabledAlarmOutputBits
    });
  });

  return params;
};

export const lteSRD2ViewModel = (
  SRD: Optional<ISRD>
): Optional<VMLteSchdeuleParams> => {
  if (isNil(SRD)) {
    return undefined;
  }

  const flightModeLte = SRD.FuncCtrl & SCTRL_FLIGHTMODE ? true : false;
  const useLteInterval = SRD.FuncCtrl & SCTRL_PERODIC ? true : false;
  const GpsRequired = SRD.FuncCtrl & SCTRL_GPSREQUIRED ? true : false;
  const alwaysOn = SRD.FuncCtrl & SCTRL_ALWAYSON ? true : false;

  const {
    days: intervalDays,
    hours: intervalHours,
    minutes: intervalMinutes
  } = convertMillisecondsToDayHourMinutes(SRD.onPeriod);

  const params: VMLteSchdeuleParams = {
    useLteInterval,
    alwaysOn,
    GpsRequired,
    intervalDays,
    intervalHours,
    intervalMinutes,
    flightModeLte
  };

  return params;
};
export const gpsSRD2ViewModel = (
  SRD: Optional<ISRD>
): Optional<VMGpsScheduleParams> => {
  if (isNil(SRD)) {
    return undefined;
  }
  const flightModeGps = SRD.FuncCtrl & SCTRL_FLIGHTMODE ? true : false;
  const useGpsInterval = SRD.FuncCtrl & SCTRL_PERODIC ? true : false;
  const alwaysOn = SRD.FuncCtrl & SCTRL_ALWAYSON ? true : false;

  const {
    days: intervalDays,
    hours: intervalHours,
    minutes: intervalMinutes
  } = convertMillisecondsToDayHourMinutes(SRD.onPeriod);

  const params: VMGpsScheduleParams = {
    useGpsInterval,
    alwaysOn,
    intervalDays,
    intervalHours,
    intervalMinutes,
    flightModeGps
  };

  return params;
};

/** convert seconds into days, hours, minutes */
const convertSecondsToDayHourMinutes = (seconds: number) => {
  /** number of seconds left of the pot of time */
  let n = seconds;

  const days = Math.floor(n / (24 * 3600));

  //subtract "entire days" from pot
  n = n % (24 * 3600);
  const hours = Math.floor(n / 3600);

  //subtract "entire hours" from pot
  n %= 3600;
  const minutes = Math.floor(n / 60);

  return { days, hours, minutes };
};

/** convert milliseconds into days, hours, minutes */
const convertMillisecondsToDayHourMinutes = (milliseconds: number) => {
  /** number of seconds left of the pot of time */
  let n = milliseconds;

  const days = Math.floor(n / (24 * 3600000));

  //subtract "entire days" from pot
  n = n % (24 * 3600000);
  const hours = Math.floor(n / 3600000);

  //subtract "entire hours" from pot
  n %= 3600000;
  const minutes = Math.floor(n / 60000);

  return { days, hours, minutes };
};

//todo: Not sure if this is good enough (utc stuff)
export const iDate2date = (date: IDate): Date => {
  //special cases
  const year = date.year + 2000;
  const month = date.month - 1;

  return new Date(year, month, date.day, date.hour, date.minute, date.second);
};

/** Converts IDate to dayjs-utc */
export const iDate2dayjs = (date: IDate): Dayjs => {
  //special cases
  const year = date.year + 2000;
  const month = date.month - 1;
  return dayjs.utc({
    year,
    month,
    day: date.day,
    hour: date.hour,
    minute: date.minute,
    second: date.second
  });
};

//note: this might be too slow.
export const iDate2Unix = (date: IDate) => {
  const mDate = iDate2dayjs(date);
  return mDate.unix();
};

/** convert days, hours, minutes to milliseconds */
const convertDaysHourMinutesToMilliseconds = (
  days: number,
  hours: number,
  minutes: number
) => (days * 86400 + hours * 3600 + minutes * 60) * 1000;

/**
 * Returns correct value (signed or unsigned) for extra value id.
 * @param extraValueId
 * @param value Buffer with length 4
 * @throws Error if extraValueId is not recognized.
 */
export const getCorrectValueForExtraValue = (
  extraValueId: number,
  value: Buffer
) => {
  switch (extraValueId) {
    case EVI_ANGLE_X:
      return value.readInt32LE(0);
    case EVI_ANGLE_Y:
      return value.readInt32LE(0);
    case EVI_ANGLE_Z:
      return value.readInt32LE(0);
    case EVI_TEMP:
      return value.readInt32LE(0);
    case EVI_RH:
      return value.readUInt32LE(0);
    case EVI_LIGHT:
      return value.readUInt32LE(0);
    case EVI_PRESSURE:
      return value.readInt32LE(0);
    case EVI_BATTERY_DATA:
      return value.readUInt32LE(0);
    default:
      throw new Error("Unknown extraValueId: " + extraValueId);
  }
};

export const buf2IDate = (buf: Buffer): IDate => {
  if (buf.length !== 6) {
    throw new Error(
      "Wrong length for timestamp. Expected 6 but got: " + buf.length
    );
  }

  const year = buf.readUInt8(0);
  const month = buf.readUInt8(1);
  const day = buf.readUInt8(2);
  const hour = buf.readUInt8(3);
  const minute = buf.readUInt8(4);
  const second = buf.readUInt8(5);

  return { year, month, day, hour, minute, second };
};

export const num2DateTuple = (numTupple: [number, number]): [Date, Date] => [
  new Date(numTupple[0] * 1e3),
  new Date(numTupple[1] * 1e3)
];

export const date2NumTuple = (dateTupple: [Date, Date]): [number, number] => [
  dateTupple[0].getTime() / 1e3,
  dateTupple[1].getTime() / 1e3
];

export type VMDvaData = { start: number; data: IDtDva[]; end: number };

export const dtDva2ViewModel = (data: IDvaData): VMDvaData[] => {
  if (isEmpty(data)) {
    return [];
  }

  function isStart(obj: any): obj is { start: IDate } {
    return "start" in obj;
  }
  function isEnd(obj: any): obj is { end: IDate } {
    return "end" in obj;
  }
  function isDvaData(obj: any): obj is IDtDva {
    return "xAlarm" in obj && "yAlarm" in obj && "zAlarm" in obj;
  }
  function isCompleteBuildObj(obj: Partial<VMDvaData>): obj is VMDvaData {
    return "start" in obj && "data" in obj && "end" in obj;
  }

  let arr: VMDvaData[] = [];

  data.reduce(
    (buildObj: Partial<VMDvaData>, curr) => {
      if (isStart(curr)) {
        buildObj.start = iDate2Unix(curr.start);
        buildObj.data = []; // Empty data if a new start is found
        return buildObj;
      }
      if (isDvaData(curr)) {
        buildObj.data!.push(curr);
        return buildObj;
      }
      if (isEnd(curr)) {
        buildObj.end = iDate2Unix(curr.end);

        if (isCompleteBuildObj(buildObj)) {
          arr.push(buildObj);
        }

        return { data: [] };
      }

      return buildObj;
    },
    { data: [] }
  );

  return arr;
};

/** Transforms a number to a string with the postfix "g". If number is
 * undefined, "N/A" will be returned */
export const toGStr = (maybeNumber: Optional<number>) =>
  maybeNumber ? maybeNumber.toFixed(2) + "g" : "N/A";

/** Transforms a number to a string with the postfix "ms". If number is
 * undefined, "N/A" will be returned */
export const toMsStr = (num: Optional<number>) => (num ? num + "ms" : "N/A");

export const createVmGeneralRecordingInformationCard = (
  recParams: VMRecordingParameters
): VMGeneralRecordingInformationCard => {
  const {
    UserInfo,
    ProjectName,
    AccParams,
    TempParams,
    RhParams,
    AngleParams,
    scheduleBlocks
  } = recParams;

  const userInfo = UserInfo;
  const projectName = ProjectName;

  const acc = AccParams?.useAcc ? AccParams.params : undefined;
  const temp = TempParams?.useTemp ? TempParams.params : undefined;
  const rh = RhParams?.useRh ? RhParams.params : undefined;
  const angle = AngleParams?.useAngle ? AngleParams.params : undefined;

  return { userInfo, projectName, acc, temp, rh, angle, scheduleBlocks };
};

/**
 * Generate a number based on the start/end type according to specification
 * @param startType
 * @param endType
 * @returns
 */
export const generateRecParamsStartStopId = (startType: RecStartType) => {
  const startTypeToNum: Record<RecStartType, ValidRecStartMode> = {
    date: REC_START_DATE,
    button: REC_START_ON_BUTTON,
    directly: REC_START_DOWNLOAD
  };

  let startAndStop = 0;
  switch (startType) {
    case "date":
    case "directly":
      startAndStop = startTypeToNum[startType] | REC_STOP_DATE;
      break;
    case "button":
      startAndStop = startTypeToNum[startType] | REC_STOP_DURATION;
      break;
  }
  return startAndStop;
};

/**
 * Transform device features to a comma seperated string that can be shown in
 * the GUI. E.g. "acceleration, temp, humidity"
 * @param features
 * @param t Translation function that can translate every key in {features}
 */
export const createFeaturesAsCommaSeperatedString = (
  features: VMDeviceFeatures,
  t: TFunction
) => {
  const availableFeatures = Object.entries(features).filter(
    ([key, val]) => val === true
  );

  //to showable features e.g. "feature1, feature2, feature3"
  return availableFeatures.reduce((resp, [key, value], index, arr) => {
    const isLastElement = index === arr.length - 1;
    const entry = t(key);

    return isLastElement ? resp.concat(entry) : resp.concat(`${entry}, `);
  }, "");
};

export const findGpsStatus = (rawStatus: number): number[] => {
  let status: number[] = [];
  const {
    gpsFailedToInitGPSModule,
    gpsFailedToGetPosition,
    gpsAntennaError,
    gpsFailedToInitCoMCU,
    gpsCommunicationProblemsCoMCU,
    gpsStatusFailedToGetGPSPosition,
    gpsPowerOn,
    gpsPowerOff,
    gpsTriggeredBySensor,
    gpsTriggeredBySchedule
  } = gpsStatusBitMasks;

  if (rawStatus & gpsFailedToInitGPSModule) {
    status.push(gpsFailedToInitGPSModule);
  }
  if (rawStatus & gpsFailedToGetPosition) {
    status.push(gpsFailedToGetPosition);
  }
  if (rawStatus & gpsAntennaError) {
    status.push(gpsAntennaError);
  }
  if (rawStatus & gpsFailedToInitCoMCU) {
    status.push(gpsFailedToInitCoMCU);
  }
  if (rawStatus & gpsCommunicationProblemsCoMCU) {
    status.push(gpsCommunicationProblemsCoMCU);
  }
  if (rawStatus & gpsStatusFailedToGetGPSPosition) {
    status.push(gpsStatusFailedToGetGPSPosition);
  }
  if (rawStatus & gpsPowerOn) {
    status.push(gpsPowerOn);
  }
  if (rawStatus & gpsPowerOff) {
    status.push(gpsPowerOff);
  }
  if (rawStatus & gpsTriggeredBySensor) {
    status.push(gpsTriggeredBySensor);
  }
  if (rawStatus & gpsTriggeredBySchedule) {
    status.push(gpsTriggeredBySchedule);
  }

  return status;
};

export const formatLatLong = (latorlong: number) => latorlong / 10000000;

export const renderPositionStatus = (
  t: (s: DictionaryTransKeys) => string,
  status?: number
) => {
  if (status === 1024) {
    return t("TriggeredBySensor");
  }
  if (status === 2048) {
    return t("TriggeredBySchedule");
  } else {
    return "";
  }
};

export const accuracyColorSelector = (accuracy?: number) => {
  if (isUndefined(accuracy)) {
    return gpsAccuracyColors.default;
  } else if (accuracy <= 5000) {
    return gpsAccuracyColors.high;
  } else if (accuracy > 5000 && accuracy <= 20000) {
    return gpsAccuracyColors.medium;
  } else if (accuracy > 20000) {
    return gpsAccuracyColors.low;
  } else {
    return gpsAccuracyColors.default;
  }
};

export const accuracyTextSelector = (
  t: (s: DictionaryTransKeys) => string,
  accuracy?: number
) => {
  if (isUndefined(accuracy)) {
    return "";
  } else if (accuracy <= 5000) {
    return t("AccuracyLevelHigh");
  } else if (accuracy > 5000 && accuracy <= 20000) {
    return t("AccuracyLevelMedium");
  } else if (accuracy > 20000) {
    return t("AccuracyLevelLow");
  } else {
    return "";
  }
};

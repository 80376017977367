import React from "react";
import { Typography } from "antd";
import dayjs from "dayjs";
import { TransportHeaderData } from "../../helpers/pdf/pdfInterfaces";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

export interface Header {
  header: TransportHeaderData;
}

const PreviewExportHeader = (props: Header): React.JSX.Element => {
  const { header } = props;
  const {
    // reportType,
    projectName,
    // hasFilters,
    startMethod,
    // parametersLoaded,
    scheduledToStart,
    scheduledToEnd,
    recStart,
    recEnd,
    // recEndReason,
    reportStart,
    reportEnd,
    exportedBy,
    deviceId
  } = header;

  const { t } = useTranslation();

  const localUtcOffset = dayjs().utcOffset() / 60;
  const localUtcOffsetStr =
    localUtcOffset >= 0 ? `(UTC+${localUtcOffset})` : `(UTC${localUtcOffset})`;

  const renderTableRow = (label: string, value: string): React.JSX.Element => {
    return (
      <tr>
        <td style={{ paddingRight: "10px" }}>
          <Text strong>{t(label)}: </Text>
        </td>
        <td style={{ paddingRight: "10px" }}>
          <Text>{t(value)}</Text>
        </td>
      </tr>
    );
  };

  const renderDoubleTableRow = (
    label1: string,
    value1: string,
    label2: string,
    value2: string
  ): React.JSX.Element => {
    return (
      <tr>
        <td style={{ paddingRight: "10px" }}>
          <Text strong>{t(label1)}: </Text>
        </td>
        <td style={{ paddingRight: "20px" }}>
          <Text>{value1}</Text>
        </td>
        <td style={{ paddingRight: "10px" }}>
          <Text strong>{t(label2)}: </Text>
        </td>
        <td style={{ paddingRight: "10px" }}>
          <Text>{value2}</Text>
        </td>
      </tr>
    );
  };

  return (
    <>
      <table>
        <tbody>
          {/** Project name + Device Id */}
          {projectName &&
            deviceId &&
            renderDoubleTableRow("Project", projectName, "DeviceId", deviceId)}

          {/** Start method */}
          {startMethod && renderTableRow("StartMethod", startMethod)}

          {/** Scheduled To Start + Scheduled To End  */}
          {scheduledToStart &&
            scheduledToEnd &&
            renderDoubleTableRow(
              "ScheduledToStart",
              scheduledToStart,
              "ScheduledToEnd",
              scheduledToEnd.time
                ? scheduledToEnd.time
                : scheduledToEnd.duration!
            )}

          {/** Record Start + Record End */}
          {recStart &&
            recEnd &&
            renderDoubleTableRow("RecordStart", recStart, "RecordEnd", recEnd)}

          {/** Report Start + Report End */}
          {reportStart &&
            reportEnd &&
            renderDoubleTableRow(
              "ReportStart",
              reportStart,
              "ReportEnd",
              reportEnd
            )}

          {/** Exported + Exported By */}
          {exportedBy?.firstName &&
            exportedBy.lastName &&
            renderDoubleTableRow(
              "Exported",
              dayjs().format("YYYY-MM-DD, HH:mm:ss ") + localUtcOffsetStr,
              "ExportedBy",
              `${exportedBy.firstName} ${exportedBy.lastName}`
            )}

          {/** Exported - if exporting user is not provided. */}
          {!exportedBy &&
            renderTableRow(
              "Exported",
              dayjs().format("YYYY-MM-DD, HH:mm:ss ") + localUtcOffsetStr
            )}
        </tbody>
      </table>
    </>
  );
};

export default PreviewExportHeader;

import { TFunction } from "i18next";
import { Content } from "pdfmake/interfaces";
import { getLocalTimeAndOffset } from "../dateHelper";
import {
  ProjectHeaderData,
  TableType,
  TransportHeaderData
} from "./pdfInterfaces";
import { defaultExportMarginBottom } from "./pdfConstants";

export const packTable = (
  table: TableType,
  contentType:
    | "default"
    | "QuickReport"
    | "ProjectStatus"
    | "Notes"
    | "Recordings"
    | "Alarms"
): Content => {
  const widthSelector = () => {
    switch (contentType) {
      case "default":
      case "Notes":
        return "*";
      case "Alarms":
        return ["*", "*", "auto", "*"];
      default:
        return "auto";
    }
  };

  return {
    layout: contentType === "Notes" ? "notesLayout" : "antdLayout",
    marginBottom: defaultExportMarginBottom,
    table: {
      headerRows: table.headers.length,
      widths: widthSelector(),
      body: [...table.headers, ...table.rows]
    }
  };
};

export const packTitle = (itemTitle: string): Content => {
  return {
    text: itemTitle,
    bold: true,
    margin: [0, 0, 0, 5],
    headlineLevel: 1
  };
};

export const packTransportHeader = (
  t: TFunction,
  transportHeader?: TransportHeaderData
): Content => {
  const headerContent: Content = {
    layout: "transportHeaderLayout",
    marginBottom: defaultExportMarginBottom,
    table: {
      headerRows: 0,
      widths: "auto",
      body: []
    }
  };
  if (!transportHeader) return headerContent;
  const tableBody = headerContent.table.body;

  tableBody.push([
    t("Project"),
    transportHeader.projectName ?? "-",
    t("DeviceId"),
    transportHeader.deviceId ?? "-"
  ]);

  if (transportHeader.startMethod) {
    tableBody.push([t("StartMethod"), transportHeader.startMethod, "", ""]);
  }

  if (transportHeader.scheduledToStart && transportHeader.scheduledToEnd) {
    tableBody.push([
      t("ScheduledToStart"),
      transportHeader.scheduledToStart,
      t("ScheduledToEnd"),
      transportHeader.scheduledToEnd.time
        ? transportHeader.scheduledToEnd.time
        : transportHeader.scheduledToEnd.duration!
    ]);
  }

  if (transportHeader.recStart && transportHeader.recEnd) {
    tableBody.push([
      t("RecordStart"),
      transportHeader.recStart,
      t("RecordEnd"),
      transportHeader.recEnd
    ]);
  }

  if (transportHeader.reportStart && transportHeader.reportEnd) {
    tableBody.push([
      t("ReportStart"),
      transportHeader.reportStart,
      t("ReportEnd"),
      transportHeader.reportEnd
    ]);
  }

  if (
    transportHeader.exportedBy?.firstName &&
    transportHeader.exportedBy.lastName
  ) {
    tableBody.push([
      t("Exported"),
      getLocalTimeAndOffset(),
      t("ExportedBy"),
      transportHeader.exportedBy.firstName +
        " " +
        transportHeader.exportedBy.lastName
    ]);
  } else {
    tableBody.push([t("Exported"), getLocalTimeAndOffset(), "", ""]);
  }

  return headerContent;
};

export const packProjectHeader = (
  t: TFunction,
  projectHeader?: ProjectHeaderData
): Content => {
  const headerContent: Content = {
    layout: "transportHeaderLayout",
    marginBottom: defaultExportMarginBottom,
    table: {
      headerRows: 0,
      widths: [60, 120, 60, "auto"],
      body: []
    }
  };
  if (!projectHeader) return headerContent;
  const tableBody = headerContent.table.body;

  tableBody.push([
    t("Project"),
    projectHeader.title ?? "-",
    t("Description"),
    projectHeader.description ?? "-"
  ]);

  if (projectHeader.startTime && projectHeader.endTime) {
    tableBody.push([
      t("StartTime"),
      projectHeader.startTime,
      t("EndTime"),
      projectHeader.endTime
    ]);
  }

  return headerContent;
};

export const packOrderLinesTable = (table: TableType): Content => {
  return {
    layout: "antdLayout",
    table: {
      headerRows: table.headers.length,
      widths: ["auto", "*", "auto", "auto"],
      body: [...table.headers, ...table.rows]
    }
  };
};

export const packOrderTotalTable = (table: TableType): Content => {
  return {
    columns: [
      {
        width: "*",
        text: ""
      },
      {
        width: "auto",
        layout: "noBorderLayout",
        table: {
          headerRows: table.headers.length,
          widths: "auto",
          body: [...table.headers, ...table.rows]
        }
      }
    ]
  };
};

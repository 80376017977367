import React, { useState } from "react";
import { Form, Row, Col, Input, notification } from "antd";
import {
  modalsState,
  closeEditUserNameModal,
  setUser
} from "../../state/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import { PrimaryButton } from "../Common/CommonButtons";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import {
  UserForUpdate,
  User,
  useUpdateUserPropertiesMutation
} from "../../state/cargologRestApi";
import StandardModal from "../Common/StandardModal";

// Main component
const EditUserNameModal = () => {
  const modals = useSelector(modalsState);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedUser = modals.userObject;

  // Load User data from API
  let user: User = {
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyId: "",
    temperatureScale: "",
    timeZone: ""
  };

  if (!isUndefined(selectedUser)) {
    user = selectedUser;
  }

  /* Request for update User Properties */
  const [updateUserProperties, requestStatus] =
    useUpdateUserPropertiesMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();

  const handleUpdateUserProperties = async (request: UserForUpdate) => {
    const result: any = await updateUserProperties(request);
    if (result.data) {
      notification.success({ message: t("SuccessfullyUpdatedUserName") });
      setFirstName(undefined);
      setLastName(undefined);
      dispatch(closeEditUserNameModal());
    } else {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      }
      notification.error({
        message: t("FailedtoUpdateUserName"),
        description: errorDescription
      });
    }
  };

  // When user finishes form sucessfully
  const changeFinished = () => {
    const newUser: User = {
      userId: user.userId,
      firstName: !isUndefined(firstName) ? firstName : user.firstName,
      lastName: !isUndefined(lastName) ? lastName : user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      companyId: user.companyId,
      temperatureScale: user.temperatureScale,
      timeZone: user.timeZone
    };
    // Use existing firstName or lastName if user hasn't edited field
    if (
      !isUndefined(newUser.firstName) &&
      !isUndefined(newUser.lastName) &&
      !isUndefined(newUser.email)
    ) {
      const request: UserForUpdate = {
        userId: newUser.userId,
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        email: newUser.email,
        phoneNumber: newUser.phoneNumber,
        temperatureScale: newUser.temperatureScale,
        timeZone: newUser.timeZone
      };
      handleUpdateUserProperties(request);
      dispatch(setUser(newUser));
    }
  };

  // When user tries to finish form, but it is incomplete
  const changeFailed = () => {};

  return (
    <>
      {/* Update user name modal */}
      <StandardModal
        title={t("UpdateName")}
        zIndex={1048}
        open={modals.isEditUserNameModalOpen}
        onCancel={() => dispatch(closeEditUserNameModal())}
        closable={true}
        footer={null}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          onFinish={changeFinished}
          onFinishFailed={changeFailed}
          layout="vertical"
          style={{ width: "100%" }}
        >
          <Row gutter={size.m1}>
            <Col span={12}>
              <Form.Item
                label={t("FirstName")}
                name="firstName"
                rules={[{ required: true, message: t("FirstNameIsRequired") }]}
                initialValue={
                  !isUndefined(user.firstName) ? user.firstName : ""
                }
              >
                <Input
                  placeholder=""
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("LastName")}
                name="lastName"
                rules={[{ required: true, message: t("LastNameIsRequired") }]}
                initialValue={!isUndefined(user.lastName) ? user.lastName : ""}
              >
                <Input
                  placeholder=""
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ marginBottom: 0 }}>
            <PrimaryButton
              style={{ width: "100%", height: "40px" }}
              htmlType="submit"
              loading={requestIsLoading}
            >
              {t("genUpdate")}
            </PrimaryButton>
          </Form.Item>
        </Form>
      </StandardModal>
    </>
  );
};

export default EditUserNameModal;

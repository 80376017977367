import React, { useState } from "react";
import {
  InfoCircleOutlined,
  ExportOutlined,
  CheckSquareOutlined,
  AimOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { GeneralSystemInfo } from "../../models/ISystemInfo";
import {
  setParamsUserProgress,
  setRecordingParametersTargetDevice
} from "../../state/paramsPageSlice";
import SystemInfoPreview from "./SystemInfoPreview";
import {
  defaultTableColumnPropsNumber,
  defaultTableColumnPropsString
} from "../../constants/defaultComponentsProps";
import { Row, Typography, Col, Table, Space } from "antd";
import { LiftedCard } from "../Common/CommonCards";
import {
  DangerButtonSmall,
  NormalButtonSmall,
  PrimaryButton
} from "../Common/CommonButtons";
import { iDate2dayjs } from "../../helpers/dataModelHelper";
import IDate from "../../models/IDate";
import { User } from "../../state/cargologRestApi";
import { size, impersonateBannerHeight } from "../../helpers/pageHelper";
import { setCompanyId } from "../../state/openParxSlice";
import { getUser } from "../../state/sessionSlice";
import { LatLngTuple } from "leaflet";
import {
  openMoveDeviceModal,
  setAlarmsData,
  setParameterId,
  setSelectedProjectId
} from "../../state/projectSlice";
import { isUndefined } from "lodash-es";
import {
  openFinishTransportModal,
  setTransportToFinish,
  openPositionsModal,
  setDeviceToRemove,
  openRemoveDeviceModal
} from "../../state/modalsSlice";
import { CheckUserRightsAccess } from "../MicroComponents/LicenseAccess";
import DeviceMap, { DeviceData, LastPos } from "./DeviceMap";
import { MapProviderLink } from "../MicroComponents/MapProviderLink";
import FinishTransportModal from "../Modals/FinishTransportModal";
import { selectImpersonate } from "../../state/persistantStateSlice";
import StandardModal from "../Common/StandardModal";
const { Text, Paragraph } = Typography;

export const fullNameString = (firstName: string, lastName: string) => {
  return `${firstName} ${lastName}`;
};

/**
 * Return next calibration (last calibration + 2 years) as a formated string
 * @param lastCalibration last calibration property from SystemInfo
 */
export const calcNextCalibration = (lastCalibration: IDate) => {
  const date = iDate2dayjs(lastCalibration).add(2, "year");
  return date.format("YYYY-MM-DD");
};

interface IProps {
  center: LatLngTuple;
  outerBounds: LatLngTuple[];
  deviceData: DeviceData[];
  showMap: boolean;
  showStartPos: boolean;
  showDevices: boolean;
  showAlarms: boolean;
  showPolylines: boolean;
  isLoading: boolean;
}
const MyDevicesList = (props: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isImpersonating } = useSelector(selectImpersonate);

  const { companyId } = useSelector(getUser);
  const {
    center,
    outerBounds,
    deviceData,
    showMap,
    showStartPos,
    showDevices,
    showAlarms,
    showPolylines,
    isLoading
  } = props;

  /** The modal that System-Info lives in */
  const [showModal, setShowModal] = useState(false);
  const [systemInfoDetails, setSystemInfoDetails] =
    useState<GeneralSystemInfo>();

  /** This variable is used to detect changes to connected devices. If this
   * variable change, the device-list will be updated */
  // const connectedDevice = useSelector(selectConnectedDeviceSystemInfo);

  const mayMoveDevice = CheckUserRightsAccess("ADK");
  const mayFinishTransport = CheckUserRightsAccess("ADM");

  const [expandedRowKeys, setExpandedRowKeys] = useState<readonly React.Key[]>(
    []
  );

  const deviceColumns: ColumnsType<DeviceData> = [
    {
      title: "",
      dataIndex: "lineColor",
      key: "lineColor",
      width: "4%",
      ellipsis: true,
      render: (lineColor: string) => (
        <div style={{ borderLeft: "5px solid " + lineColor }}>&nbsp;</div>
      )
    },
    Table.EXPAND_COLUMN,
    {
      title: t("myDevicesTableSerialNumberTitle"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      // width: "20%",
      ellipsis: true,
      render: (serialNumber: string) => (
        <Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: 0 }}>
          {serialNumber}
        </Paragraph>
      ),
      sorter: (a, b) =>
        a.serialNumber
          .toLowerCase()
          .localeCompare(b.serialNumber.toLowerCase(), "sv")
    },
    {
      title: t("Project"),
      dataIndex: ["project"],
      key: "project",
      // width: "30%",
      ellipsis: true,
      render: (project: { name: string }, data: DeviceData) => {
        if (project && data.isActive) {
          return <Text>{project.name}</Text>;
        }
        if (project && !data.isActive) {
          return <Text type="secondary">{t("Stocked")}</Text>;
        }
        return <></>;
      },
      sorter: (a, b) =>
        (a.project?.name.toLowerCase() ?? "").localeCompare(
          b.project?.name.toLowerCase() ?? "",
          "sv"
        )
    },
    {
      ...defaultTableColumnPropsString,
      title: t("myDevicesTableNextCalibrationTitle"),
      dataIndex: "nextCalibration",
      key: "nextCalibration",
      // width: 50,
      ellipsis: true,
      render: (nextCalibration: string) => <Text>{nextCalibration}</Text>,
      sorter: (a, b) =>
        (a.nextCalibration ?? "").localeCompare(b.nextCalibration ?? "")
    },
    {
      ...defaultTableColumnPropsNumber,
      title: t("myDevicesTableLastUserTitle"),
      dataIndex: "lastUser",
      key: "lastUser",
      // width: 50,
      ellipsis: true,
      render: (lastUser: User) =>
        lastUser ? `${lastUser.firstName} ${lastUser.lastName}` : "",
      sorter: (a, b) => {
        const aFullName = a.lastUser
          ? `${a.lastUser.firstName} ${a.lastUser.lastName}`
          : "";
        const bFullName = b.lastUser
          ? `${b.lastUser.firstName} ${b.lastUser.lastName}`
          : "";

        return aFullName.localeCompare(bFullName, "sv");
      }
    },
    {
      ...defaultTableColumnPropsNumber,
      title: t("myDevicesTableLastConnectedTitle"),
      dataIndex: "lastSeen",
      key: "lastSeen",
      // width: 50,
      ellipsis: true,
      sorter: (a, b) => {
        if ((a.lastSeen ?? "") < (b.lastSeen ?? "")) {
          return -1;
        }
        if ((a.lastSeen ?? "") > (b.lastSeen ?? "")) {
          return 1;
        }
        return 0;
      }
    },
    {
      title: t("LastPosition"),
      dataIndex: "lastPos",
      key: "firstLat",
      // width: "25%",
      ellipsis: true,
      render: (lastPos: LastPos) => (
        <>
          {lastPos ? (
            <>
              <Row>
                <Text type="secondary">
                  {lastPos.lastDateTime && lastPos.lastDateTime}
                </Text>
              </Row>
              <Row>
                <Text style={{ paddingRight: size.m1 }}>
                  {lastPos.lastLat + ", " + lastPos.lastLon}
                </Text>
              </Row>
            </>
          ) : (
            <></>
          )}
        </>
      )
    }
  ];

  /** Use system-info as a base for creating/downloading parameters */
  const sysInfoToNewParams = (systemInfo: GeneralSystemInfo) => {
    dispatch(setRecordingParametersTargetDevice(systemInfo));
    dispatch(setCompanyId(companyId ?? ""));
    dispatch(setParamsUserProgress(1));
    navigate("/params");
  };

  const deleteDevice = (data: DeviceData) => {
    dispatch(setDeviceToRemove(data.serialNumber));
    dispatch(openRemoveDeviceModal());
  };

  /**
   ** Top bar: 48px
   ** PagePadding: 24px
   ** Title bar: 68px
   ** Card padding: 48px
   ** Map content: 245px (only with map)
   ** Table header: 55px (increases if screen size is small)
   ** Pagination: 64px
   ** Card marginBottom: 24px
   ** Other: 8px (making some room for dynamic changes)
   ** Is impersonating (if active): 48px
   */
  const heightWithMap = `calc(100vh - 48px - 24px - 68px - 48px - 245px - 55px - 64px - 24px - 8px ${isImpersonating ? `- ${impersonateBannerHeight}px` : ""})`;
  /** Same as heightWithMap but without 'Map content' */
  const heightWithoutMap = `calc(100vh - 48px - 24px - 68px - 48px - 55px - 64px - 24px - 8px ${isImpersonating ? `- ${impersonateBannerHeight}px` : ""})`;

  return (
    <div>
      <LiftedCard>
        {outerBounds.length > 0 && (
          <>
            <div
              style={{
                visibility: showMap ? "visible" : "hidden",
                height: showMap ? "auto" : 0
              }}
            >
              <DeviceMap
                center={center}
                outerBounds={outerBounds}
                deviceData={deviceData}
                showStartPos={showStartPos}
                showAlarms={showAlarms}
                showDevices={showDevices}
                showPolylines={showPolylines}
              />
            </div>
          </>
        )}

        <Table
          columns={deviceColumns}
          dataSource={deviceData}
          loading={isLoading}
          scroll={{
            x: "100%",
            y:
              outerBounds.length > 0 && showMap
                ? heightWithMap
                : heightWithoutMap
          }}
          pagination={{
            hideOnSinglePage: false,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "15", "25", "50", "100"],
            defaultPageSize: 25
          }}
          rowKey={(data) => data.key}
          tableLayout="auto"
          expandable={{
            onExpandedRowsChange: (key) => setExpandedRowKeys(key),
            expandedRowKeys: expandedRowKeys,
            expandedRowRender: (data) => {
              const showIcon = data.gpsPosData.find((e) => e);

              return (
                <>
                  <Row wrap={false} justify="space-between" align="middle">
                    <Col span={8} style={{ marginLeft: size.xl2 }}>
                      <Paragraph
                        style={{ marginBottom: 0 }}
                        ellipsis={{
                          rows: 2,
                          expandable: true,
                          symbol: t("more")
                        }}
                      >
                        {data.deviceDescription && (
                          <>
                            <Row>
                              <Text strong>{t("RecordingDescription")}</Text>
                            </Row>
                            <Row>
                              <Paragraph style={{ marginBottom: 0 }}>
                                {data.deviceDescription}
                              </Paragraph>
                            </Row>
                          </>
                        )}
                      </Paragraph>
                    </Col>

                    <Col>
                      <Space wrap={true}>
                        {data.recentDevice && (
                          <NormalButtonSmall
                            icon={<InfoCircleOutlined />}
                            onClick={() => {
                              setShowModal(true);
                              setSystemInfoDetails(
                                data.recentDevice?.systemInfo
                              );
                            }}
                          >
                            {t("DeviceInfo")}
                          </NormalButtonSmall>
                        )}
                        {mayFinishTransport &&
                          data.parameterId.length > 0 &&
                          data.isActive &&
                          !isUndefined(data.project) && (
                            <NormalButtonSmall
                              icon={<CheckSquareOutlined />}
                              onClick={() => {
                                dispatch(
                                  setTransportToFinish({
                                    id: data.parameterId,
                                    projectId: data.project!.id,
                                    deviceName: data.serialNumber,
                                    deviceDescription: data.deviceDescription,
                                    isActive: false
                                  })
                                );
                                dispatch(openFinishTransportModal());
                              }}
                            >
                              {t("FinishTransport")}
                            </NormalButtonSmall>
                          )}
                        {mayMoveDevice &&
                          data.parameterId.length > 0 &&
                          data.isActive && (
                            <NormalButtonSmall
                              icon={<ExportOutlined />}
                              onClick={() => {
                                dispatch(
                                  setParameterId({
                                    parameterId: data.parameterId,
                                    deviceName: data.serialNumber,
                                    deviceDescription: data.deviceDescription,
                                    isActive: data.isActive
                                  })
                                );
                                dispatch(
                                  setSelectedProjectId(data.project?.id)
                                );
                                dispatch(openMoveDeviceModal());
                              }}
                            >
                              {t("ChangeProject")}
                            </NormalButtonSmall>
                          )}
                        {!isUndefined(showIcon) && (
                          <NormalButtonSmall
                            icon={<AimOutlined />}
                            onClick={() => {
                              dispatch(setAlarmsData(data));
                              dispatch(openPositionsModal());
                            }}
                          >
                            {t("Positions")}
                          </NormalButtonSmall>
                        )}
                        {data && data.lastPos && (
                          <MapProviderLink
                            lat={data.lastPos.lastLat}
                            lon={data.lastPos.lastLon}
                            text={true}
                            formFactor="button"
                          />
                        )}
                        <DangerButtonSmall
                          icon={<DeleteOutlined />}
                          onClick={() => deleteDevice(data)}
                        >
                          {t("genDelete")}
                        </DangerButtonSmall>
                      </Space>
                    </Col>
                  </Row>
                </>
              );
            }
          }}
        />
      </LiftedCard>
      <StandardModal
        title={t("DeviceInfo")}
        open={showModal}
        zIndex={1045}
        closable
        footer={[
          <PrimaryButton
            key={"useForNewParameters"}
            onClick={() => sysInfoToNewParams(systemInfoDetails!)}
          >
            {t("UseForNewParameters")}
          </PrimaryButton>
        ]}
        onCancel={() => setShowModal(false)}
      >
        <SystemInfoPreview systemInfo={systemInfoDetails!} />
      </StandardModal>
      <FinishTransportModal />
    </div>
  );
};

export default MyDevicesList;

import React from "react";
import { useSelector } from "react-redux";
import {
  selectParamsPageState,
  selectTargetDevice,
  UserProgress
} from "../../state/paramsPageSlice";
import { selectRecordingParameters } from "../../state/openParxSlice";
import ParamsBuilderDateAndTime from "./ParamsBuilderDateAndTime";
import ParamsBuilderChooseParams from "./ParamsBuilderChooseParams";
import ParamsBuilderStart from "./ParamsBuilderStart";
import ParamsPreview from "./ParamsPreview";
import { Row, Space } from "antd";
import { createUtcOffsetStr, guessLocalTz } from "../../helpers/dateHelper";
import { TransportHeaderData } from "../../helpers/pdf/pdfInterfaces";
import {
  getScheduledToEnd,
  getStartMethod,
  getStartDate
} from "../../helpers/exportHelper";
import { useTranslation } from "react-i18next";
import PreviewExportHeader from "../PrintExport/PreviewExportHeader";
import { Typography } from "antd";

interface IProps {
  contentWidth: number;
}
const ParamsBuilderContent: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { userProgress } = useSelector(selectParamsPageState);
  const openRecordingParameters = useSelector(selectRecordingParameters);
  const targetDevice = useSelector(selectTargetDevice);
  const timezoneStr = useSelector(guessLocalTz);

  // Convert timestamps to milliseconds or stay undefined
  const startTimestamp = openRecordingParameters.RecParams.startTimestamp
    ? openRecordingParameters.RecParams.startTimestamp * 1000
    : undefined;
  const stopTimestamp = openRecordingParameters.RecParams.stopTimestamp
    ? openRecordingParameters.RecParams.stopTimestamp * 1000
    : undefined;

  // UTC offset for the start timestamp
  const startTzOffset = createUtcOffsetStr(timezoneStr, startTimestamp);
  const startTimezone = {
    timezoneStr,
    utcOffsetStr: startTzOffset
  };

  // UTC offset for the stop timestamp
  const stopTzOffset = createUtcOffsetStr(timezoneStr, stopTimestamp);
  const endTimezone = {
    timezoneStr,
    utcOffsetStr: stopTzOffset
  };

  const transportHeader: TransportHeaderData = {
    reportType: "ParametersPreview",
    projectName: openRecordingParameters.ProjectName,
    startMethod:
      openRecordingParameters.RecParams.startRecordingType === "date" // Checking if the start method is 'date' in order to determine if the start method should be displayed in the report.
        ? getStartMethod(openRecordingParameters, t)
        : undefined,
    scheduledToStart: getStartDate(openRecordingParameters, startTimezone, t),
    scheduledToEnd: getScheduledToEnd(
      openRecordingParameters,
      endTimezone,
      t,
      startTimestamp,
      true
    ),
    deviceId: targetDevice.serial.toString()
  };

  /** Parameters pages based on user progress */
  const pages: Record<UserProgress, JSX.Element> = {
    0: <ParamsBuilderStart />,
    1: <ParamsBuilderChooseParams systemInfo={targetDevice} />,
    2: <ParamsBuilderDateAndTime systemInfo={targetDevice} />,
    3: (
      <Space direction="vertical" style={{ width: "100%" }}>
        <Typography.Title level={3}>{t("ParametersPreview")}</Typography.Title>
        <PreviewExportHeader header={transportHeader} />
        <ParamsPreview
          recordingParameters={openRecordingParameters}
          systemInfo={targetDevice}
          withValidation={true}
        />
      </Space>
    )
  };

  return (
    <Row
      style={{ width: "100%", maxWidth: props.contentWidth }}
      justify="start"
      align="top"
    >
      {pages[userProgress]}
    </Row>
  );
};

export default ParamsBuilderContent;

import React from "react";
import { Row } from "antd";
import { useResizeDetector } from "react-resize-detector";
import ParamsBuilder from "../components/ParamsPage/ParamsBuilder";
import ParamsSideMenu from "../components/ParamsPage/ParamsSideMenu";
import { isSmallScreen, sideMenuWidth } from "../helpers/pageHelper";
import RemoveParXTemplateModal from "../components/Modals/RemoveParXTemplateModal";
import EditParXTemplateModal from "../components/Modals/EditParXTemplateModal";
import SaveParxTemplateModal from "../components/Modals/SaveParxTemplateModal";

const sidebarWidth = 90;
const scrollbarWidth = 16;

const ParamsPage = () => {
  const { width, ref } = useResizeDetector();
  const windowWidthMinusSidebarWidth = window.innerWidth - sidebarWidth;
  const componentWidth = windowWidthMinusSidebarWidth ?? width;
  const windowWidth = window.innerWidth ?? width;
  const contentWidth = isSmallScreen(windowWidth)
    ? componentWidth
    : componentWidth - sideMenuWidth - scrollbarWidth;

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          overflowY: "scroll",
          overflowX: "hidden"
        }}
        ref={ref}
      >
        <Row wrap={false}>
          <ParamsSideMenu
            isExpanded={!isSmallScreen(windowWidth)}
            isFloating={isSmallScreen(windowWidth)}
          />
          <div style={{ width: contentWidth }}>
            <ParamsBuilder contentWidth={contentWidth} />
          </div>
        </Row>
      </div>
      <SaveParxTemplateModal />
      <EditParXTemplateModal />
      <RemoveParXTemplateModal />
    </>
  );
};

export default ParamsPage;

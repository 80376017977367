import React, { useState } from "react";
import StandardModal from "../Common/StandardModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  closeEditParXTemplateModal,
  modalsState
} from "../../state/modalsSlice";
import { Form, Input, notification } from "antd";
import { PrimaryButton } from "../Common/CommonButtons";
import {
  selectParamsPageState,
  setSelectedEditParXTemplate
} from "../../state/paramsPageSlice";
import {
  ParXTemplateForAlteration,
  useEditParXTemplateMutation
} from "../../state/cargologRestApi";
import { isUndefined } from "lodash-es";

const EditParXTemplateModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isEditParXTemplateModalOpen } = useSelector(modalsState);
  const { selectedEditParXTemplate } = useSelector(selectParamsPageState);

  const [templateName, setTemplateName] = useState<string>();
  const [templateDescription, setTemplateDescription] = useState<string>();

  const [editParXTemplate, request] = useEditParXTemplateMutation();
  const { isLoading } = request;

  const handleUpdateTemplate = async (request: ParXTemplateForAlteration) => {
    const result: any = await editParXTemplate(request);
    if (result.data) {
      notification.success({ message: t("SuccessfullyUpdatedTemplate") });
      setTemplateName(undefined);
      setTemplateDescription(undefined);
      dispatch(setSelectedEditParXTemplate(undefined));
      dispatch(closeEditParXTemplateModal());
    } else {
      notification.error({ message: t("UnableToUpdateTemplate") });
    }
  };

  const onFinish = () => {
    if (
      !isUndefined(selectedEditParXTemplate) &&
      !isUndefined(selectedEditParXTemplate.id)
    ) {
      const templateRequest: ParXTemplateForAlteration = {
        id: selectedEditParXTemplate.id,
        name: isUndefined(templateName)
          ? selectedEditParXTemplate.name
          : templateName,
        description: isUndefined(templateDescription)
          ? selectedEditParXTemplate.description
          : templateDescription
      };

      handleUpdateTemplate(templateRequest);
    }
  };

  return (
    <StandardModal
      title={t("EditParxTemplate")}
      open={isEditParXTemplateModalOpen}
      zIndex={1045}
      width={400}
      closable={true}
      onCancel={() => {
        dispatch(closeEditParXTemplateModal());
      }}
      destroyOnClose={true}
      footer={null}
    >
      <Form
        name="basic"
        onFinish={onFinish}
        layout="vertical"
        style={{ width: "100%" }}
      >
        <Form.Item
          label={t("TemplateName")}
          name="templateName"
          initialValue={
            !isUndefined(selectedEditParXTemplate)
              ? selectedEditParXTemplate.name
              : ""
          }
          rules={[{ required: true, message: t("TemplateNameIsRequired") }]}
        >
          <Input
            placeholder=""
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label={t("TemplateDescription")}
          name="templateDescription"
          initialValue={
            !isUndefined(selectedEditParXTemplate)
              ? selectedEditParXTemplate.description
              : ""
          }
        >
          <Input.TextArea
            rows={4}
            showCount
            maxLength={256}
            value={templateDescription}
            onChange={(e) => setTemplateDescription(e.target.value)}
          />
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <PrimaryButton
            style={{ width: "100%", height: "40px" }}
            htmlType="submit"
            loading={isLoading}
            disabled={isUndefined(selectedEditParXTemplate)}
          >
            {t("UpdateTemplate")}
          </PrimaryButton>
        </Form.Item>
      </Form>
    </StandardModal>
  );
};

export default EditParXTemplateModal;

import React, { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Row, Typography, notification } from "antd";
import { Buffer } from "buffer";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanParams,
  recordingParameters2ViewModel
} from "../../helpers/dataModelHelper";
import { impersonateBannerHeight, size } from "../../helpers/pageHelper";
import { validatePackedParx } from "../../helpers/paramsHelper";
import { parseParx } from "../../helpers/parsers/parseParxHelper";
import {
  isValidationObjAllValid,
  validateRecordingParameters
} from "../../helpers/validationHelper";
import { getAppVersion } from "../../helpers/versionHelper";
import { newRecordingParameters } from "../../state/openParxSlice";
import {
  defaultParametersString,
  selectParamsBasedOn,
  selectParamsPageState,
  selectTargetDevice,
  setActiveParametersTab,
  setParamsBasedOn,
  setParamsUserProgress,
  setSelectedParXTemplate
} from "../../state/paramsPageSlice";
import {
  appendRecentLocalParxFile,
  selectImpersonate
} from "../../state/persistantStateSlice";
import { getUser } from "../../state/sessionSlice";
import { invalidRecordingParametersErrorMessage } from "../../utils/invalidRecordingParametersMessage";
import { NormalSideMenuCard } from "../Common/CommonCards";
import { displayErrorMessage } from "../MicroComponents/GeneralUserFeedback";
import OpenFileButton from "../MicroComponents/OpenFileButton";
import { NormalButtonSmall } from "../Common/CommonButtons";
import { GeneralSideMenuArrow } from "../MicroComponents/GeneralSideMenuArrow";
import ParamsTemplates from "./ParamsTemplates";
import {
  useGetAllParXTemplatesQuery,
  useGetParXFileQuery
} from "../../state/cargologRestApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { isUndefined } from "lodash-es";

const { Text } = Typography;

interface IProps {
  isExpanded?: boolean;
  isFloating?: boolean;
  width?: number;
}
const ParamsSideMenu: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: parXTemplateList, isLoading: loadingTemplates } =
    useGetAllParXTemplatesQuery();

  const { companyId } = useSelector(getUser);
  const { isImpersonating } = useSelector(selectImpersonate);
  const targetDevice = useSelector(selectTargetDevice);
  const paramsBasedOn = useSelector(selectParamsBasedOn);
  const isBaseParams = paramsBasedOn === defaultParametersString;
  const { selectedParXTemplate } = useSelector(selectParamsPageState);

  const { data: selectedTemplate } = useGetParXFileQuery(
    isUndefined(selectedParXTemplate?.id)
      ? skipToken
      : { templateId: selectedParXTemplate.id }
  );

  /** general function */
  const validateAndOpenParx = (parxBuf: Buffer, fileName: string) => {
    const validationRes = validatePackedParx(parxBuf);
    const isValid = validationRes.isOk;

    //tell the user that there is something wrong with parx
    if (!isValid) {
      //saveNotOkRecParamsToLog(parxBuf, validationRes);
      invalidRecordingParametersErrorMessage();
      return;
    }

    const parx = parseParx(parxBuf);
    const recordingParameters = parx.recordingParameters;
    const vmRecordingParameters =
      recordingParameters2ViewModel(recordingParameters);

    // Validate recording parameters and display error message if invalid
    const validatedRecordingParameters = validateRecordingParameters(
      vmRecordingParameters,
      targetDevice
    );

    // Sorting out invalid parameters - returning the key of the invalid parameter as a string
    const invalidParams = Object.entries(validatedRecordingParameters)
      .filter(([key, value]) => value === false)
      .map(([key, value]) => key);

    // Function to get the text for the invalid parameter
    const invalidParamsText = (invalidParamName: string): string => {
      switch (invalidParamName) {
        case "startDateHasNotPassed":
          return t("startDate");
        case "endDateHasNotPassed":
          return t("endDate");
        case "hasAccOrNotUsed":
          return t("generalAccTitle");
        case "hasDvaOrNotUsed":
          return t("DF_DVA");
        case "hasTempOrNotUsed":
          return t("generalTempTitle");
        case "hasRhOrNotUsed":
          return t("generalRhTitle");
        case "hasAngleOrNotUsed":
          return t("generalAngleTitle");
        case "hasLteOrNotUsed":
          return t("DF_LTE");
        case "hasGpsOrNotUsed":
          return t("DF_GPS");
        case "hasExtIOOrNotUsed":
          return t("generalExtIOTitle");
        case "hasAtlestOneUsedParameter":
          return t("noParametersUsed");
        case "hasProjectName":
          return t("projectName");
        default:
          return "";
      }
    };

    const parametersValid = isValidationObjAllValid(
      validatedRecordingParameters
    );

    const neutralParams = cleanParams(
      vmRecordingParameters,
      getAppVersion(),
      companyId
    );

    dispatch(newRecordingParameters(neutralParams));
    dispatch(setParamsUserProgress(1));
    dispatch(setActiveParametersTab("freeText"));
    dispatch(setParamsBasedOn(fileName));

    if (parametersValid) {
      notification.success({ message: t("YourSelectedFileHasBeenOpened") });
    } else {
      notification.warning({
        message: `${t("SomeParametersHaveBeenChanged")}`,
        description: (
          <div>
            <div style={{ marginBottom: size.m1 }}>
              <Text>{t("seeBelowWhichParametersThatAreAffected")}</Text>
            </div>

            {invalidParams.map((param, index) => (
              <div key={index}>
                <Text key={param}>- {invalidParamsText(param)}</Text>
              </div>
            ))}
          </div>
        )
      });
    }
  };

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          const data: Buffer = Buffer.from(reader.result as ArrayBuffer);
          validateAndOpenParx(data, file.name);
          dispatch(
            appendRecentLocalParxFile({
              filePath: file.path,
              fileName: file.name
            })
          );
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const openTemplateFile = async (parxBuf: Buffer) => {
    try {
      if (!isUndefined(selectedParXTemplate)) {
        validateAndOpenParx(parxBuf, selectedParXTemplate.name);
      }
    } catch (e) {
      displayErrorMessage(`${t("CouldntOpenFile")}.`);
    }
  };

  useEffect(() => {
    if (!isUndefined(selectedTemplate)) {
      openTemplateFile(selectedTemplate);
    }
  }, [selectedTemplate]);

  const [mouseInArea, setMouseInArea] = useState(false);

  return (
    <NormalSideMenuCard
      title={mouseInArea || props.isExpanded ? t("Setupdevice") : undefined}
      isExpanded={mouseInArea || props.isExpanded}
      isFloating={props.isFloating}
      isImpersonating={isImpersonating}
      onMouseEnter={() => setMouseInArea(true)}
      onMouseLeave={() => setMouseInArea(false)}
      style={{ zIndex: 1001 }}
    >
      <div
        style={{
          overflow: "auto",
          outline: "none",
          maxHeight: `calc(100vh - (48px + 65px ${isImpersonating ? `+ ${impersonateBannerHeight}px` : ""}))`
        }}
      >
        {!mouseInArea && !props.isExpanded ? (
          <GeneralSideMenuArrow
            page="params"
            isImpersonating={isImpersonating}
          />
        ) : (
          <>
            <OpenFileButton
              accept=".parx"
              onSelectFile={onSelectFile}
              buttonLabel={t("ImportFromFile")}
            />

            <Row style={{ paddingTop: size.m2, paddingInline: size.m2 }}>
              <Text strong>{t("ParametersBasedOn")}:</Text>
            </Row>
            <Row
              justify="space-between"
              align="middle"
              style={{ width: "100%", paddingInline: size.m2 }}
              wrap={false}
            >
              <Text>{paramsBasedOn}</Text>
              {!isBaseParams && (
                <NormalButtonSmall
                  icon={<CloseOutlined />}
                  onClick={() => {
                    dispatch(setSelectedParXTemplate(undefined));
                    dispatch(setParamsBasedOn(defaultParametersString));
                  }}
                />
              )}
            </Row>
            <ParamsTemplates
              data={parXTemplateList}
              loading={loadingTemplates}
            />
          </>
        )}
      </div>
    </NormalSideMenuCard>
  );
};

export default ParamsSideMenu;

import React from "react";
import Dashboard from "../components/GraphPage/Dashboard";
import NoData from "../components/GraphPage/noData";
import { Row, Space } from "antd";
import {
  Loading3QuartersOutlined,
  FileUnknownOutlined,
  LockOutlined
} from "@ant-design/icons";
import commonColors from "../styles/commonColors";
import { useDispatch, useSelector } from "react-redux";
import { selectGraphPageVm } from "../state/openDatxSlice";
import { NormalButton } from "../components/Common/CommonButtons";
import { openFilePasswordModal } from "../state/modalsSlice";
import FilePasswordModal from "../components/GraphPage/FilePasswordModal";
import GraphSideMenu from "../components/GraphPage/GraphSideMenu";
import { useTranslation } from "react-i18next";
import { selectImpersonate } from "../state/persistantStateSlice";
import {
  impersonateBannerHeight,
  isSmallScreen,
  sideMenuWidth
} from "../helpers/pageHelper";
import { useResizeDetector } from "react-resize-detector";

/** Graph page content types */
type PageContent =
  | "dashboard"
  | "noFileId"
  | "datxWithoutData"
  | "passwordProtected"
  | "openingNewFile"
  | "noData";

const GraphPage = () => {
  const {
    activeFileId,
    noActiveFileId,
    hasOpenFiles,
    hasData,
    openNewFileStatus,
    isUnlocked
  } = useSelector(selectGraphPageVm);
  const { t } = useTranslation();
  const { isImpersonating } = useSelector(selectImpersonate);
  const { width, ref } = useResizeDetector();
  const componentWidth = width ?? window.innerWidth;
  const windowWidth = window.innerWidth ?? width;

  const dispatch = useDispatch();
  /** All available page contents for this page */
  const graphPageContent: Record<PageContent, () => JSX.Element> = {
    dashboard: () => <Dashboard fileId={activeFileId!} />,
    noFileId: () => (
      <Row
        align="middle"
        justify="center"
        style={{ height: "100%", textAlign: "center" }}
      >
        <Space direction="vertical">
          <FileUnknownOutlined
            style={{ fontSize: "48px", color: commonColors.disabledText }}
          />
          <p>{t("NoFileSelected")}</p>
        </Space>
      </Row>
    ),
    datxWithoutData: () => (
      <>
        <Row
          align="middle"
          justify="center"
          style={{ height: "100%", textAlign: "center" }}
        >
          <Space direction="vertical">
            <FileUnknownOutlined
              style={{ fontSize: "48px", color: commonColors.disabledText }}
            />
            <p>{t("TheSelectedFileIsEmpty")}</p>
          </Space>
        </Row>
      </>
    ),
    passwordProtected: () => (
      <>
        <Row
          align="middle"
          justify="center"
          style={{ height: "100%", textAlign: "center" }}
        >
          <Space direction="vertical">
            <LockOutlined
              style={{ fontSize: "48px", color: commonColors.disabledText }}
            />
            <p>{t("TheSelectedFileIsPasswordProtected")}</p>
            <NormalButton onClick={() => dispatch(openFilePasswordModal())}>
              {t("UnlockFile")}
            </NormalButton>
            <FilePasswordModal fileId={activeFileId} page="reports" />
          </Space>
        </Row>
      </>
    ),
    openingNewFile: () => (
      <>
        <Row
          align="middle"
          justify="center"
          style={{ height: "100%", textAlign: "center" }}
        >
          {" "}
          <Space direction="vertical">
            <Loading3QuartersOutlined
              spin
              style={{ fontSize: "48px", color: commonColors.disabledText }}
            />
            <p>{t(openNewFileStatus)}</p>
          </Space>
        </Row>
      </>
    ),
    noData: () => <NoData />
  };

  /** Function determine which content to render */
  const getActivePage = (): PageContent => {
    if (openNewFileStatus !== "openFileStatusInactive") return "openingNewFile";
    if (noActiveFileId) return "noFileId";
    if (!hasOpenFiles) return "noData";
    if (!hasData) return "datxWithoutData";
    if (isUnlocked === false) return "passwordProtected";
    if (hasData) return "dashboard";
    return "noData";
  };

  const renderActivePage = () => graphPageContent[getActivePage()]();
  const contentWidth = isSmallScreen(windowWidth)
    ? "100%"
    : componentWidth - sideMenuWidth;

  return (
    <div
      style={{ width: "100%", height: "100%", overflowY: "scroll" }}
      ref={ref}
    >
      <Row wrap={false} style={{ height: "inherit" }}>
        <GraphSideMenu
          style={{
            height: `calc(100% ${isImpersonating ? `- ${impersonateBannerHeight}px` : ""})`
          }}
          isExpanded={!isSmallScreen(windowWidth)}
          isFloating={isSmallScreen(windowWidth)}
        />
        <div style={{ width: contentWidth }}>{renderActivePage()}</div>
      </Row>
    </div>
  );
};

export default GraphPage;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultSystemInfo } from "../constants/params-defaults";
import { GeneralSystemInfo } from "../models/ISystemInfo";
import { StoreApi } from "./store";
import { isNil } from "lodash-es";
import {
  isCriticalRecordingParametersValid,
  validateRecordingParameters,
  validateRecordingParamsBeforeDownload
} from "../helpers/validationHelper";
import { ParXTemplate } from "./cargologRestApi";

export interface ParamsPageState {
  userProgress: UserProgress;
  targetDevice: GeneralSystemInfo;
  activeParameterTab: ParameterTab;
  //todo: add desc
  isSideMenuOpenIfSmallScreen: boolean;
  paramsBasedOn: string;
  selectedParXTemplate?: ParXTemplate;
  selectedEditParXTemplate?: ParXTemplate;
  selectedDeleteParXTemplate?: ParXTemplate;
}

export type UserProgress = 0 | 1 | 2 | 3;

export type ParameterTab =
  | "acc"
  | "temp"
  | "pressure"
  | "rh"
  | "angle"
  | "freeText"
  | "triggers"
  | "extIO"
  | "extSensors"
  | String;

export type ProgressStepStatus = "waiting" | "inProgress" | "validated";
export const defaultParametersString = "Default parameters";

const initialState: ParamsPageState = {
  userProgress: 0,
  targetDevice: defaultSystemInfo,
  activeParameterTab: "freeText",
  isSideMenuOpenIfSmallScreen: false,
  paramsBasedOn: defaultParametersString,
  selectedParXTemplate: undefined,
  selectedEditParXTemplate: undefined,
  selectedDeleteParXTemplate: undefined
};

export const slice = createSlice({
  name: "paramsPage",
  initialState,
  reducers: {
    setParamsUserProgress: (state, action) => {
      state.userProgress = action.payload;
    },
    nextParamsPage: (state) => {
      state.userProgress += 1;
    },
    prevParamsPage: (state) => {
      state.userProgress -= 1;
    },

    setUseDefaultTargetDevice: (state) => {
      state.targetDevice = defaultSystemInfo;
    },
    setRecordingParametersTargetDevice: (
      state,
      action: PayloadAction<GeneralSystemInfo>
    ) => {
      state.targetDevice = action.payload;
    },

    setActiveParametersTab: (state, action: PayloadAction<ParameterTab>) => {
      state.activeParameterTab = action.payload;
    },

    setHoverParamsSideMenu: (state, action: PayloadAction<boolean>) => {
      state.isSideMenuOpenIfSmallScreen = action.payload;
    },

    setParamsBasedOn: (state, action: PayloadAction<string>) => {
      state.paramsBasedOn = action.payload;
    },

    setSelectedParXTemplate: (
      state,
      action: PayloadAction<ParXTemplate | undefined>
    ) => {
      state.selectedParXTemplate = action.payload;
    },

    setSelectedEditParXTemplate: (
      state,
      action: PayloadAction<ParXTemplate | undefined>
    ) => {
      state.selectedEditParXTemplate = action.payload;
    },

    setSelectedDeleteParXTemplate: (
      state,
      action: PayloadAction<ParXTemplate | undefined>
    ) => {
      state.selectedDeleteParXTemplate = action.payload;
    }
  }
});

export const {
  setParamsUserProgress,
  nextParamsPage,
  prevParamsPage,
  setUseDefaultTargetDevice,
  setRecordingParametersTargetDevice,
  setActiveParametersTab,
  setHoverParamsSideMenu,
  setParamsBasedOn,
  setSelectedParXTemplate,
  setSelectedEditParXTemplate,
  setSelectedDeleteParXTemplate
} = slice.actions;

export default slice.reducer;

export const selectParamsPageState = (state: StoreApi) => state.paramsPage;

export const selectParamsPageUserProgress = (state: StoreApi) => {
  const { userProgress, targetDevice } = state.paramsPage;
  const { recordingParameters } = state.openParx;

  const validationResult = validateRecordingParameters(
    recordingParameters,
    targetDevice
  );

  const getStatus = (
    key: UserProgress,
    isValid: boolean
  ): ProgressStepStatus =>
    userProgress === key ? "inProgress" : isValid ? "validated" : "waiting";

  const step1Valid = validateRecordingParamsBeforeDownload(
    recordingParameters,
    targetDevice
  );
  const step2Valid = isCriticalRecordingParametersValid(validationResult);

  const progressStatus: Record<UserProgress, ProgressStepStatus> = {
    0: getStatus(0, !isNil(targetDevice) && targetDevice.serial !== "default"),
    1: getStatus(1, step1Valid),
    2: getStatus(2, step2Valid),
    3: getStatus(3, false)
  };

  return { userProgress, progressStatus };
};

export const selectTargetDevice = (state: StoreApi) =>
  state.paramsPage.targetDevice;

export const selectActiveParameterTab = (state: StoreApi) =>
  state.paramsPage.activeParameterTab;

export const selectSideMenuOpenIfSmallScreen = (state: StoreApi) =>
  state.paramsPage.isSideMenuOpenIfSmallScreen;

export const selectParamsBasedOn = (state: StoreApi) =>
  state.paramsPage.paramsBasedOn;

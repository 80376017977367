import { DatePicker, DatePickerProps, GetProps } from "antd";
import { range, isNil } from "lodash-es";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withoutTime } from "../../helpers/dateHelper";
import {
  selectForDashboardRangePicker,
  setActiveDataDomain
} from "../../state/openDatxSlice";
import {
  selectGlobalTimezone,
  selectGlobalTimezoneToggle
} from "../../state/sessionSlice";
import { timezoneSelector } from "../../helpers/timezoneSelector";
import dayjs, { Dayjs } from "dayjs";

const { RangePicker } = DatePicker;
type RangePickerProps = GetProps<typeof RangePicker>;

type DisabledDomain = {
  disabledHours?: () => number[];
  disabledMinutes?: () => number[];
};

const lastPickableHour = 24;
const lastPickableMinute = 60;

/**
 * Get disabled hours/minutes based on start {pickTime}
 * @param pickTime
 * @param activeDomain
 * @param entireDomain
 */
const getDisabledDomainStartValues = (
  pickTime: Dayjs,
  entireDomain: [Dayjs, Dayjs]
): DisabledDomain => {
  const [domainStart, domainEnd] = entireDomain;

  const isSameDateAsDomainStart = pickTime.date() === domainStart.date();
  const isSameDateAsDomainEnd = pickTime.date() === domainEnd.date();

  // +1 since the end hour should be selectable
  const lastPickableEndHourIfSameDate = domainEnd.hour() + 1;

  // The entire domain is within one day
  if (isSameDateAsDomainStart && isSameDateAsDomainEnd) {
    const isSameHour = pickTime.hour() === domainStart.hour();

    const priorDisabledhours = range(0, domainStart.hour());
    const laterDisabledHours = range(
      lastPickableEndHourIfSameDate,
      lastPickableHour
    );

    const disabledHours = () => priorDisabledhours.concat(laterDisabledHours);

    if (!isSameHour) {
      // no disabled minutes
      return { disabledHours };
    }

    const priorDisabledMinutes = range(0, domainStart.minute());
    const disabledMinutes = () => priorDisabledMinutes;

    return { disabledHours, disabledMinutes };
  }

  if (isSameDateAsDomainStart) {
    const isSameHour = pickTime.hour() === domainStart.hour();

    const disabledHours = () => range(0, domainStart.hour());
    const disabledMinutes = isSameHour
      ? () => range(0, domainStart.minute())
      : undefined;

    return { disabledHours, disabledMinutes };
  }

  //nothing disabled
  return {};
};

/**
 * Get disabled hours/minutes based on end {pickTime}
 * @param pickTime
 * @param activeDomain
 * @param entireDomain
 */
const getDisabledDomainEndValues = (
  pickTime: Dayjs,
  entireDomain: [Dayjs, Dayjs]
): DisabledDomain => {
  const [domainStart, domainEnd] = entireDomain;

  const isSameDateAsStart = pickTime.date() === domainStart.date();
  const isSameDateAsEnd = pickTime.date() === domainEnd.date();

  // +1 since the end hour should be selectable
  //todo: maybe not what im looking for
  const lastPickableEndHourIfSameDate = domainEnd.hour() + 1;

  const isSameHour = pickTime.hour() === domainStart.hour();

  // The entire domain is within one day

  //todo: maybe very wrong
  if (isSameDateAsStart && isSameDateAsEnd) {
    const priorDisabledhours = range(0, domainStart.hour());
    const laterDisabledHours = range(
      lastPickableEndHourIfSameDate,
      lastPickableHour
    );

    const disabledHours = () => priorDisabledhours.concat(laterDisabledHours);

    if (!isSameHour) {
      // no disabled minutes
      return { disabledHours };
    }

    const priorDisabledMinutes = range(0, domainStart.minute() + 1);
    const laterDisabledMinutes = range(
      domainEnd.minute() + 1,
      lastPickableMinute
    );

    const disabledMinutes = () =>
      priorDisabledMinutes.concat(laterDisabledMinutes);

    return { disabledHours, disabledMinutes };
  }

  if (isSameDateAsEnd) {
    // +1 since the last hour should be selectable
    const endHour = domainEnd.hour() + 1;
    // +1 since the last minute should be selectable
    const endMinute = domainEnd.minute() + 1;

    const disabledHours = () => range(endHour, lastPickableHour);
    const disabledMinutes = isSameHour
      ? () => range(endMinute, lastPickableMinute)
      : undefined;

    return { disabledHours, disabledMinutes };
  }

  //nothing disabled
  return {};
};

interface IProps {
  fileId: string;
}
const DashboardHeaderRangePicker: React.FC<RangePickerProps & IProps> = (
  props
) => {
  const dispatch = useDispatch();
  const { dataDomain, timezone } = useSelector(
    selectForDashboardRangePicker(props.fileId)
  );
  const timezoneState = useSelector(selectGlobalTimezone);
  const timezoneToggle = useSelector(selectGlobalTimezoneToggle);
  const currentTimezone = timezoneSelector(
    timezone,
    timezoneState,
    timezoneToggle
  );

  // Get the timezone offset from the currently selected timezone
  const timezoneOffset = dayjs().tz(currentTimezone).utcOffset();
  // Get the local timezone offset
  const localTimezoneOffset = dayjs().utcOffset();

  // Convert UTC to local time
  const utcToLocal = (date: Dayjs) => {
    return date.add(localTimezoneOffset, "minutes");
  };
  // Convert UTC to selected timezone
  const utcToTimezone = (date: Dayjs) => {
    return date.add(timezoneOffset, "minutes");
  };

  // Convert local time to UTC
  const localToUtc = (date: Dayjs) => {
    return date.subtract(localTimezoneOffset, "minutes");
  };
  // Convert selected timezone to UTC
  const timezoneToUtc = (date: Dayjs) => {
    return date.subtract(timezoneOffset, "minutes");
  };

  // Creates the domain in the local timezone
  const entireDomain: [Dayjs, Dayjs] = dataDomain
    ? [dayjs.unix(dataDomain[0]), dayjs.unix(dataDomain[1])]
    : [dayjs(), dayjs()];

  const shiftAndDispatch = (start: Dayjs, end: Dayjs) => {
    const startUnshifted = utcToLocal(timezoneToUtc(start));
    const endUnshifted = utcToLocal(timezoneToUtc(end));
    const unixStart = startUnshifted.unix();
    let unixEnd = endUnshifted.unix();
    if (unixStart > unixEnd) return;
    const id = props.fileId;
    const newDomain: [number, number] = [unixStart, unixEnd];

    dispatch(setActiveDataDomain({ id, newDomain }));
  };

  const handleDateChange = (
    value: DatePickerProps["value"] | RangePickerProps["value"]
  ) => {
    if (!Array.isArray(value)) return;
    const [start, end] = value;
    if (isNil(start) || isNil(end)) return;
    shiftAndDispatch(start, end);
  };

  const defaultStart: Dayjs = utcToTimezone(localToUtc(entireDomain[0]));
  const defaultEnd: Dayjs = utcToTimezone(localToUtc(entireDomain[1]));
  const defaultRange: [Dayjs, Dayjs] = [defaultStart, defaultEnd];

  const isDateDisabled = (pickDate: Dayjs) => {
    //We dont care about time in this function, only the dates
    const pickDateUnix = withoutTime(pickDate).unix();
    const startUnix = withoutTime(defaultStart).unix();
    const endUnix = withoutTime(defaultEnd).unix();

    //pick date needs to be between start and end date
    return !(startUnix <= pickDateUnix && pickDateUnix <= endUnix);
  };

  const isTimeDisabled = (date: Dayjs, range?: "start" | "end") => {
    if (isNil(date)) {
      return {};
    }
    return range === "start"
      ? getDisabledDomainStartValues(date, defaultRange)
      : getDisabledDomainEndValues(date, defaultRange);
  };

  return (
    <RangePicker
      minDate={defaultStart}
      maxDate={defaultEnd}
      defaultValue={[defaultStart, defaultEnd]}
      disabledDate={isDateDisabled}
      disabledTime={isTimeDisabled}
      allowClear={false}
      suffixIcon={null}
      separator="-"
      showTime={{ format: "HH:mm:ss", hideDisabledOptions: true }}
      format="YYYY-MM-DD HH:mm:ss"
      onOk={handleDateChange}
      style={{ width: 300 }}
    />
  );
};

export default React.memo(DashboardHeaderRangePicker);

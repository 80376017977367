import React, { useState } from "react";
import {
  FilterOutlined,
  PlusCircleOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { skipToken } from "@reduxjs/toolkit/query";
import { Checkbox, Col, Divider, Input, Popover, Row, Space } from "antd";
import { LatLngExpression, LatLngTuple } from "leaflet";
import { isUndefined } from "lodash-es";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { deviceColorsArray } from "../../constants/colors";
import { size } from "../../helpers/pageHelper";
import {
  ProjectWithUserRights,
  useGetParametersQuery,
  useGetProjectGpsQuery
} from "../../state/cargologRestApi";
import { setProjectName } from "../../state/openParxSlice";
import { setParamsUserProgress } from "../../state/paramsPageSlice";
import { closeProjectModal, projectsState } from "../../state/projectSlice";
import { NormalButton, PrimaryButton } from "../Common/CommonButtons";
import DeviceMap, { DeviceData, GpsPosData } from "../DevicesPage/DeviceMap";
import FinishTransportModal from "../Modals/FinishTransportModal";
import RecordingsTabTable from "./RecordingsTabTable";

export const getLineColor = (colorsArray: string[]) => {
  return { color: colorsArray.pop() ?? "#409c46" };
};

interface IProps {
  project?: ProjectWithUserRights;
}

const RecordingsTab = (props: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedProjectId } = useSelector(projectsState);
  const { project } = props;

  const { data: parameters } = useGetParametersQuery(
    selectedProjectId ? { projectId: selectedProjectId } : skipToken
  );

  const { data: recordings } = useGetProjectGpsQuery(
    selectedProjectId ? { id: selectedProjectId } : skipToken
  );

  const loading = !project || !parameters || !recordings;

  /** Used for centering the map when there is only one device in the project */
  let center: LatLngTuple = [57.8563, 14.30782];
  /** Used for creating a map bounds that shows all the devices in the project*/
  let outerBounds: LatLngTuple[] = [];
  const gpsData = recordings
    ? recordings.map((gps) => {
        if (gps.gpsData?.length === 0) {
          return;
        }

        const dateTime = gps.gpsData?.[gps.gpsData.length - 1].dateTime;
        const timezoneOffset = new Date().getTimezoneOffset();
        const lastDateTime = dayjs
          .utc(dateTime)
          .subtract(timezoneOffset, "minutes")
          .format("YYYY-MM-DD, HH:mm:ss");

        const lastVelocity = gps.gpsData?.[gps.gpsData.length - 1].velocity;
        const lastStatus = gps.gpsData?.[gps.gpsData.length - 1].status ?? 100;

        const firstDate = gps.gpsData?.[0].dateTime;
        const firstDateTime = dayjs
          .utc(firstDate)
          .subtract(timezoneOffset, "minutes")
          .format("YYYY-MM-DD, HH:mm:ss");
        const firstLat = gps.gpsData?.[0].lat;
        const firstLon = gps.gpsData?.[0].lon;

        const lastLat = gps.gpsData?.[gps.gpsData.length - 1].lat ?? 57.8563;
        const lastLon = gps.gpsData?.[gps.gpsData.length - 1].lon ?? 14.30782;

        const mapOrigo: LatLngExpression = [lastLat, lastLon];

        outerBounds.push(mapOrigo);
        center = mapOrigo;

        const lastPos = {
          lastDateTime: lastDateTime,
          lastLat: lastLat,
          lastLon: lastLon,
          lastVelocity: lastVelocity ?? 0,
          lastStatus: lastStatus
        };

        /** Used for Polyline */
        const gpsPosData: GpsPosData[] = gps.gpsData
          ? gps.gpsData.map((data) => {
              // todo: should this include all gps positions or only the devices?
              outerBounds.push([data.lat, data.lon]);

              return {
                path: [data.lat, data.lon],
                velocity: data.velocity,
                dateTime: data.dateTime,
                status: data.status
              };
            })
          : [];

        const sortedGpsPos = gpsPosData.sort((a, b) =>
          a.dateTime.localeCompare(b.dateTime)
        );
        return {
          parameterId: gps.parameterId ?? "",
          firstPos: {
            firstDateTime,
            firstLat,
            firstLon
          },
          lastPos: lastPos,
          gpsPosData: sortedGpsPos
        };
      })
    : [];

  const colorsArray = [...deviceColorsArray];

  const selectableDevices = parameters
    ? [...new Set(parameters.map((data) => data.deviceName))]
    : [];
  const [filterDeviceNames, setFilterDeviceNames] = useState<string[]>([]);
  const [filterStatuses, setFilterStatuses] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const deviceData: DeviceData[] = parameters
    ? parameters
        .map((param, index) => {
          const gps = gpsData?.find((gps) => gps?.parameterId === param.id);
          const deviceAlarms = project?.alarms?.filter(
            (alarm) => alarm.parameterId === param.id
          );
          return {
            parameterId: param.id ?? "",
            fileName: param.fileName ?? "",
            firstPos: gps?.firstPos,
            lastPos: gps?.lastPos,
            recordingStart: param.firstStart,
            latestData: param.hasData ? param.lastData : "",
            lastUploader: param.lastUploader ?? "",
            lastUploadType: param.lastUploadType ?? "",
            created: param.created ?? "",
            creator: param.creator ?? "",
            serialNumber: param.deviceName ?? "",
            deviceDescription: param.deviceDescription ?? "",
            gpsPosData: gps?.gpsPosData ?? [],
            lineColor: !isUndefined(gps)
              ? getLineColor(colorsArray).color
              : "transparent",
            alarms: deviceAlarms?.length ?? 0,
            project: {
              id: param.projectId,
              name: ""
            },
            hasData: param.hasData,
            isActive: param.isActive,
            parXFileName: param.parXFileName,
            key: index.toString()
          };
        })
        .filter((data) => {
          return (
            (filterDeviceNames.length === 0 ||
              filterDeviceNames.find(
                (deviceName) => deviceName === data.serialNumber
              )) &&
            (filterStatuses.length === 0 ||
              (filterStatuses.includes("active") && data.isActive) ||
              (filterStatuses.includes("finished") && !data.isActive)) &&
            (!searchQuery ||
              searchQuery === "" ||
              data
                .serialNumber!.toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              data
                .deviceDescription!.toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              data.fileName.toLowerCase().includes(searchQuery.toLowerCase()))
          );
        })
        .sort((a: any, b: any) =>
          b.latestData?.toLowerCase().localeCompare(a.latestData?.toLowerCase())
        )
    : [];

  const mapData: DeviceData[] = deviceData.filter(
    (data) => data.gpsPosData.length > 0
  );

  /** Using custom component instead of Leaflets built in LayersControl in order to show green checkboxes.
   * Put it outside of the map to avoid issues when clicking the Popover.
   */
  const customLayersControl = () => (
    <Popover
      placement="bottom"
      trigger="click"
      overlayStyle={{ minWidth: 160, paddingRight: size.s1 }}
      getPopupContainer={(triggerNode: HTMLElement) =>
        triggerNode.parentNode as HTMLElement
      }
      content={
        <>
          <Row>
            <Checkbox
              checked={showMap}
              style={{ width: "100%" }}
              onClick={() => setShowMap(!showMap)}
            >
              {" "}
              {t("ShowMap")}
            </Checkbox>
          </Row>
          <Divider style={{ marginBlock: size.s1 }} />
          <Row>
            <Checkbox
              checked={showStartPos}
              style={{ width: "100%" }}
              onClick={() => setShowStartPos(!showStartPos)}
            >
              {t("StartPosition")}
            </Checkbox>
          </Row>
          <Row>
            <Checkbox
              checked={showAlarms}
              style={{ width: "100%" }}
              onClick={() => setShowAlarms(!showAlarms)}
            >
              {t("Alarms")}
            </Checkbox>
          </Row>
          <Row>
            <Checkbox
              checked={showDevices}
              style={{ width: "100%" }}
              onClick={() => setShowDevices(!showDevices)}
            >
              {t("Devices")}
            </Checkbox>
          </Row>
          <Row>
            <Checkbox
              checked={showPolylines}
              style={{ width: "100%" }}
              onClick={() => setShowPolylines(!showPolylines)}
            >
              {t("DeviceLines")}
            </Checkbox>
          </Row>
        </>
      }
    >
      <NormalButton
        icon={<FilterOutlined />}
        style={{
          marginBottom: size.m2
        }}
      >
        {t("Map")}
      </NormalButton>
    </Popover>
  );

  const [showStartPos, setShowStartPos] = useState<boolean>(true);
  const [showDevices, setShowDevices] = useState<boolean>(true);
  const [showAlarms, setShowAlarms] = useState<boolean>(true);
  const [showPolylines, setShowPolylines] = useState<boolean>(true);
  const [showMap, setShowMap] = useState<boolean>(false);

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <PrimaryButton
            style={{ marginBottom: size.m2 }}
            onClick={() => {
              dispatch(setParamsUserProgress(1));
              dispatch(setProjectName(project?.title ?? ""));
              dispatch(closeProjectModal());
              navigate("/params");
            }}
          >
            <PlusCircleOutlined /> {t("NewRecording")}
          </PrimaryButton>
        </Col>
        <Col>
          <Space>
            {customLayersControl()}
            <Popover
              placement="bottom"
              trigger="click"
              getPopupContainer={(triggerNode: HTMLElement) =>
                triggerNode.parentNode as HTMLElement
              }
              content={
                <Checkbox.Group
                  onChange={(v) => setFilterDeviceNames(v as string[])}
                  style={{ flexDirection: "column" }}
                >
                  {selectableDevices.length > 0 &&
                    selectableDevices.map((deviceName) => (
                      <Checkbox
                        defaultChecked={true}
                        value={deviceName}
                        checked={
                          !isUndefined(deviceName) &&
                          !isUndefined(filterDeviceNames) &&
                          filterDeviceNames.includes(deviceName)
                        }
                        key={deviceName}
                      >
                        {deviceName}
                      </Checkbox>
                    ))}
                </Checkbox.Group>
              }
            >
              <NormalButton
                icon={<FilterOutlined />}
                style={{
                  marginBottom: size.m2
                }}
                active={filterDeviceNames.length > 0}
              >
                {t("Device")}
              </NormalButton>
            </Popover>
            <Popover
              placement="bottom"
              trigger="click"
              getPopupContainer={(triggerNode: HTMLElement) =>
                triggerNode.parentNode as HTMLElement
              }
              content={
                <Checkbox.Group
                  onChange={(v) => setFilterStatuses(v as string[])}
                  style={{ flexDirection: "column" }}
                >
                  <Checkbox
                    defaultChecked={false}
                    value="active"
                    checked={
                      filterStatuses && filterStatuses.includes("active")
                        ? true
                        : false
                    }
                  >
                    {t("StatusActive")}
                  </Checkbox>
                  <Checkbox
                    defaultChecked={false}
                    value="finished"
                    checked={
                      filterStatuses && filterStatuses.includes("finished")
                        ? true
                        : false
                    }
                  >
                    {t("StatusFinished")}
                  </Checkbox>
                </Checkbox.Group>
              }
            >
              <NormalButton
                icon={<FilterOutlined />}
                style={{
                  marginBottom: size.m2
                }}
                active={filterStatuses.length > 0}
              >
                {t("Status")}
              </NormalButton>
            </Popover>
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              onChange={(e) => setSearchQuery(e.target.value as string)}
              allowClear
              style={{ marginBottom: size.m2 }}
            />
          </Space>
        </Col>
      </Row>
      {showMap && (
        <DeviceMap
          center={center}
          outerBounds={outerBounds}
          deviceData={mapData}
          showStartPos={showStartPos}
          showAlarms={showAlarms}
          showDevices={showDevices}
          showPolylines={showPolylines}
        />
      )}

      <RecordingsTabTable
        deviceData={deviceData}
        loading={loading}
        project={project}
      />

      <FinishTransportModal />
    </>
  );
};

export default RecordingsTab;

import {
  ExportOutlined,
  DeliveredProcedureOutlined,
  FileSearchOutlined
} from "@ant-design/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { VMRecordingParameters } from "../../models/ViewModelRecordingParameters/VMRecordingParameters";
import { MenuButton } from "../Common/CommonButtons";
import { Help } from "../MicroComponents/Help";
import PrintMultiblePreviewer from "./PrintMultiblePreviewer";
import { Dropdown, MenuProps } from "antd";
import { selectDatxToCsvFunc } from "../../state/openDatxSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectCsvFormat } from "../../state/sessionSlice";
import { isNil } from "lodash-es";
import { displayErrorMessage } from "../MicroComponents/GeneralUserFeedback";
import { saveAsCsv } from "../../helpers/fileHelperUniversal";
import { setIsFirstScreenshot } from "../../state/exportSlice";
import { CheckUserRightsAccess } from "../MicroComponents/LicenseAccess";

interface IProps {
  fileId: string;
  recParams?: VMRecordingParameters;
}
const ExportReportButton: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [exportPreviewVisible, setExportPreviewVisible] = useState(false);

  const windowWidth = window.innerWidth;

  const csvFormat = useSelector(selectCsvFormat);
  const getDatxAsCsv = useSelector(
    selectDatxToCsvFunc(props.fileId, csvFormat, CheckUserRightsAccess("MOB"))
  );

  const exportDatxAsCsv = () => {
    const datxAsCsv = getDatxAsCsv();

    if (isNil(datxAsCsv)) {
      displayErrorMessage(t("FailedToExportCsvDataNoDataAvailable"));
      return;
    }

    saveAsCsv(datxAsCsv);
  };

  const items: MenuProps["items"] = [
    {
      label: t("PdfExportPreview"),
      key: "pdfExportPreview",
      icon: <FileSearchOutlined />,
      onClick: () => setExportPreviewVisible(true)
    },
    {
      label: "CSV",
      key: "csv",
      icon: <DeliveredProcedureOutlined />,
      onClick: () => exportDatxAsCsv()
    }
  ];

  const closeModal = () => {
    setExportPreviewVisible(false);
    // Reset the this value so that when user closes the modal and opens it again, it will not show
    // the "Image saved" message on open.
    dispatch(setIsFirstScreenshot(true));
  };

  return (
    <>
      <PrintMultiblePreviewer
        fileId={props.fileId}
        show={exportPreviewVisible}
        close={() => closeModal()}
      />
      {Help(
        <Dropdown trigger={["click"]} destroyPopupOnHide menu={{ items }}>
          <MenuButton
            icon={<ExportOutlined />}
            styles={{ icon: { marginRight: windowWidth > 990 ? 8 : 0 } }}
          >
            {windowWidth > 990 ? t("ExportReport") : undefined}
          </MenuButton>
        </Dropdown>,
        t("ClickHereToExportTheReport"),
        "left"
      )}
    </>
  );
};

export default ExportReportButton;
